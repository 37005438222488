console.log(`Cart.JS loaded`);
const api = require('../api');
import Vue from 'vue'
import numeral from 'numeral';
import parseItems from "../utils/parseItems";
import { uuid } from 'vue-uuid';

export default {
    namespaced: true,
    state: {
        recompute: 0,
        items: [], // "" { id: price... }
        id: null
    },
    getters: {
        total: state => state.items.reduce(function(total, item) { return total += (parseFloat(numeral(item.listPrice).value())) }, 0),
        inCart: (state) => (item) => state.items.findIndex((elm) => elm.id == item.id) > -1,
        hasItems: (state) => state.items.length > 0,
        count: (state) => state.items.length,
        totalItems: (state) => state.items.reduce(function(total, item) {
            return total + item.qty
        }, 0),
        qtyOf: (state) => (item) => state.items.filter(x=>x.market_hash_name == item.market_hash_name).length,
        costOf: (state) => (item) => state.items.filter(x => x.market_hash_name == item.market_hash_name).reduce((total, item) => total += (parseFloat(numeral(item.listPrice).value())), 0),
        items: (state) => state.items,
        condensedItems: (state) => {
            let items = [];
            let seenItems = [];

            for (let item of state.items) {
                if (seenItems.indexOf(item.market_hash_name) > -1) continue;
                // console.log("Testing " + item.type + " - " + item.market_hash_name)
                if (item.type == "COMMODITY") { 
                    seenItems.push(item.market_hash_name);
                }

                items.push(item);
            }

            return items;
        },
        cartByGame: (state) => {
            let games = {};
            for (let item of state.items) {
                let appId = item.appId || item.appId || item.appid;
                games[appId] = games[appId] || [];
                games[appId].push(item);
            }
            return games;
        },
        cartByGameCondensed: (state) => {
            let seenItems = [];

            let games = {};
            for (let item of state.items) {
                if (seenItems.indexOf(item.market_hash_name) > -1) continue;

                if (item.type == "COMMODITY") { 
                    seenItems.push(item.market_hash_name);
                }
                
                let appId = item.appId || item.appId || item.appid;
                games[appId] = games[appId] || [];
                games[appId].push(item);
            }
            return games;
        },
        cartId: (state) => state.id
    },
    mutations: {
        addItemToCart(state, item) {
            state.items.push(item);
        },
        removeItemById(state, itemId) {
            let indexOfItem = state.items.findIndex((item) => item.id == itemId);
            if (indexOfItem == -1) return;
            
            let item = state.items[indexOfItem];
            if (item.qty > 1) {
                item.qty--;
                return;
            }

            state.items.splice(indexOfItem, 1);
        },
        clearCart(state) {
            console.log("Trying to clear cart")
            Vue.set(state, 'items', []);
            console.log("Cleared cart")
        },
        updateCartId(state, id){
            state.id = id;
        },
    },
    actions: {
        async updateCart(context){
            // If logged in, get cart from lists container
            let loggedIn = context.rootState.auth.loggedIn;
            if (!loggedIn) return context.commit('clearCart');
            
            let user = context.rootState.auth.user;
            let list = (await api.getLists({userId: user.id, type: "cart"})).data[0]
            if (!list) {
                throw new Error("No cart list found!");
            }

            context.commit('updateCartId', list.id)
            if (list.items.length <= 0) return;

            console.log(`Trying to get ${list.items.length} items for cart`)

            let itemIds = [];
            for (let item of list.items) {
                if (item.id) itemIds.push(item.id);
                else itemIds.push(item); // Legacy where it can be just a string
            }

            console.log(`Getting itemIds: ${itemIds.join(',')}`)
            if (itemIds.length == 0) return console.log("Empty cart");

            let items = (await api.getItems({ ids: itemIds.join(',') })).data
            context.commit('clearCart');

            items = await parseItems(items);
            for (let item of items) {
                const listItem = list.items.find(x => x.id == item.id);

                if (listItem && item.listPrice != listItem.listPrice) item.priceChanged = true;

                context.commit('addItemToCart', item)
            }
        },
        async addItem(context, item) {
            try {
                // Syncing up cart before adding item
                // This seems to be causing issues if users remove items too fast
                // or add items too fast.
                // await context.dispatch('updateCart')

                if (!item.id)
                    throw new Error("Item must have a trackable ID!");

                let user = context.rootState.auth.user;
                if (!user || !user.id) {
                    throw new Error("User is not signed in");
                }
                
                item.qty = item.qty || 1;
                item.price = item.price || 1;

                let alreadyAdded = context.state.items.find(x => x.id == item.id);
                if (alreadyAdded) {
                    return console.log(`Item already in cart`);
                }

                context.commit('addItemToCart', item);
                await api.post(`/Lists/cart/add`, item);
            } catch (e) {
                console.error(e.stack);
            }   
        },
        async removeItem(context, item) {
            // Syncing up cart before removing item
            // This seems to be causing issues if users remove items too fast
            // or add items too fast.
            // await context.dispatch('updateCart')

            if (!item.id)
                throw new Error("Item must have a trackable ID!");

            const itemIds = []
            const cartId = context.state.id;

            if (item.type == "COMMODITY") {
                for (let item of context.state.items.filter(x => x.market_hash_name == item.market_hash_name)) {
                    context.commit( 'removeItemById', item.id);
                    itemIds.push(item.id);
                }
            } else {
                context.commit( 'removeItemById', item.id);
                itemIds.push(item.id);
            }
            // Update user's cart in the db (list)
            // Taking out item from current cart object
            let user = context.rootState.auth.user;
            if (user && user.id){
                for (const itemId of itemIds) {
                    const { data: newCart } = await api.post(`/Lists/${cartId}/remove`, { id: itemId });
                }
            }

            // Maybe remove?
            this.updateCart()
        },
        reset: () => {},
        purchaseItems: async ({ commit, state, getters, dispatch }) => {
            let items = state.items;
            let itemsToPurchase = [];

            for (let item of items) {
                // commodity purchase orders are currently depricated
                if (false && item.type == "COMMODITY") {
                    // Purchase any 
                    itemsToPurchase.push({
                        market_hash_name: item.market_hash_name,
                        maxPrice: parseFloat(item.listPrice)
                    }); 
                } else {
                    // Purchase a specific item
                    itemsToPurchase.push({
                        id: item.id,
                        depositerId: item.depositerId,
                        listPrice: item.listPrice, // This is to enure a buyer only pays what they was expecting.
                    });
                }
            }
            
            let response = await api.purchaseItems(itemsToPurchase);
            let correlationId = uuid.v4();
            commit('clearCart');

            return response;
        }
    }
}