import { uuid } from 'vue-uuid';
import moment from 'moment';

import sampleImage from '@/assets/item-sample.png';

import sticker1 from "@/assets/sticker1.svg";
import sticker2 from "@/assets/sticker2.svg";
import sticker3 from "@/assets/sticker3.svg";


function item(appId) {
    appId = appId || "730";

    let baseRecord = {
        id: uuid.v4(),
        name: "AK-47 | Fire Serpent",
        market_hash_name: "AK-47 Fire Serpent (Factory New)",
        float: Math.random(),
        patternIndex: 123,
        appId: 730,
        // image: "",
        icon_url: "-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot7HxfDhjxszJemkV08u_mpSOhcjnI7TDglRc7cF4n-T--Y3nj1H6rhFpYWihcIGUdwc8aFmGqVm6kunogsTvvMyaz3Bn7yghsXiInxPl0AYMMLKjE_A1sw",
        image: "-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot7HxfDhjxszJemkV08u_mpSOhcjnI7TDglRc7cF4n-T--Y3nj1H6rhFpYWihcIGUdwc8aFmGqVm6kunogsTvvMyaz3Bn7yghsXiInxPl0AYMMLKjE_A1sw",
        stickers: [
            { image: sticker1 },
            { image: sticker2 },
            { image: sticker3 },
        ],
        price: (Math.random() * 100),
        currency: "$",
        discount: 14,
        rarity: {
            name: "Covert",
            color: "FF0000"
        },
        quantity: 1,
        commodity: true,
        tradable: false,
        stattrak: true,
        wear: 'Factory New',
        location: "site",
        listPrice: (Math.random() * 100).toFixed(2),
        qty: 1,
    };

    const allItems = {
        "730": [
            { name: "", image: sampleImage, }
        ]
    };

    let item = {}
    if (allItems[appId])
        item = allItems[appId][Math.floor(Math.random() * allItems[appId].length) - 1];

    item = Object.assign(baseRecord, item);
    return item;
}

function store() {
    return {
        id: uuid.v4(),
        name: 'Best Store',
        featured: [
            item(730),
            item(730),
            item(730),
            item(730),
        ],

        items: [item(730), item(730), item(730), item(730), item(730), item(730), item(730), item(730), item(730), item(730)],
    }
}

function chart() {
    // Come up with a random list of prices and dates

    let points = [];

    let date = moment().subtract('1', 'months');

    while (date.isBefore(moment())) {
        points.push({
            x: date.format('YYYY-MM-DD'),
            y: (Math.random() * 100).toFixed(2)
        });
        date.add('1', 'day')
    }

    return points
}

export { item, store, chart }