<template>
    <div>
        <nav
            class="nav navbar navbar-expand-xl navbar-dark gdark px-5" 
            style="height:56px;"
            >
            <div class="mr-4">
                <router-link to="/" v-slot="{ href, navigate }">
                    <a class="navbar-brand " :href="href" @click="navigate">
                        <img src="@/assets/key-icon-teal.svg" style="max-height: 35px; max-width: 30px;" />
                        <span class="text-white" style="margin-left:5px;font-family: Rubik; font-size: 22px;  font-weight: bold">GUARD</span>
                        <span class="text-teal" style="font-family: Rubik; font-size: 22px; font-weight: bold">GG</span>
                    </a>
                </router-link>
            </div>

            <ul class="navbar-nav">
                <router-link to="/tf2" v-slot="{ href, navigate, isActive }">
                    <li class="nav-item">
                        <a
                        class="nav-link text-white"
                        :class="{ active: isActive }"
                        :href="href"
                        @click="$route == '/tf2' ? window.reload() : navigate()"
                        style=""
                        >BROWSE</a>
                    </li>
                </router-link>

                <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    >
                    <span class="navbar-toggler-icon"></span>
                </button>
            </ul>

            <!-- This will collapse if too small -->
            <div class="collapse navbar-collapse gdark" id="navbarSupportedContent">
                <!-- This stuff collapses when  -->
                <ul class="navbar-nav mr-auto">
                    <router-link to="/faq" v-slot="{ href, navigate, isActive }">
                        <li class="nav-item">
                            <a
                            class="nav-link text-white"
                            :class="{ active: isActive }"
                            :href="href"
                            @click="navigate"
                            style=""
                            >SUPPORT</a>
                        </li>
                    </router-link>

                    <!-- <router-link to="/db" v-slot="{ href, navigate, isActive }">
                        <li class="nav-item">
                            <a
                            class="nav-link text-white"
                            :class="{ active: isActive }"
                            :href="href"
                            @click="navigate"
                            >Item DB</a>
                        </li>
                    </router-link> -->
                </ul>

                <div class="mx-auto">
                    <Search style="width:20vw"/>
                </div>

                <ul class="ml-auto navbar-nav nav">
                    <!-- TODO: If StepByStep trade in progress -->
                    <div v-if="this.$store.getters['stepByStep/isActive']" class="mx-3" style="margin-top: 15px;">
                        <font-awesome-layers style="font-size: 2rem;">
                        <font-awesome-icon icon="circle" color="white" style="font-size: 20px;left: 3px;bottom: -6px;" />
                            <font-awesome-icon
                            class=""
                            icon="exclamation-triangle"
                            color="#ED6B33"
                            data-toggle="modal"
                            data-target="#stepByStep" 
                            style="cursor: pointer;"
                            />
                        </font-awesome-layers>
                    </div>

                    <router-link to="/sell" v-slot="{ href, navigate, isActive }">
                        <li class="nav-item">
                            <a
                            class="nav-link text-white btn btn-orange"
                            :class="{ active: isActive }"
                            :href="href"
                            @click="navigate"
                            style="font-size:16px;font-weight:700;font-family:Roboto;"
                            :style="{'margin-top': loggedIn ? '12px' : '0px'}"
                            >SELL</a>
                        </li>
                    </router-link>
                    
                    <Profile ref="profile" v-if="loggedIn" v-on:profileToggle="toggleDropdown('profile', $event)"/>
                    <li v-if="!loggedIn" class="nav-item text-white">
                        <router-link to="/login" v-slot="{ href, navigate }">
                            <a class="btn btn-white nav-link" :href="href" @click="navigate" style="text-transform: none">LOGIN</a>
                        </router-link>
                    </li>
                    <li v-if="!loggedIn" class="nav-item text-white"> 
                        <!-- <router-link
                            to="/register"
                            v-slot="{ href, navigate }"
                        >
                            <a class="btn btn-primary nav-link" :href="href" @click="navigate">Sign up</a>
                        </router-link> -->
                    </li>
                </ul>
            </div>
            <Notifications class="mx-2" ref="notifications" v-on:noticationsToggle="toggleDropdown('notifications', $event)" />
            <Cart class="mx-2" ref="cart" v-on:cartToggle="toggleDropdown('cart', $event)"/>
        </nav>

        <!-- TODO: Collapsable -->
        <!-- games bar -->   
        <nav class="navbar navbar-expand-lg glight game-selector " style="z-index:1;" v-if="showGameBar">
            <transition name="fadeLeft" tag="div" class="w-100" mode="out-in">
                <ul key="1" class="navbar-nav nav-fill w-100"  v-if="selectedSection == null">
                    <li v-for="section in sections.filter(x => !x.hidden)" v-bind:key="section.path" class="nav-item">
                        <router-link :to="section.path" v-slot="{ href, navigate, isActive }" @click.native="selectedSection = section">
                            <li class="nav-item" :class="{ active: isActive }">
                                <span
                                    class="nav-link navbar-rubik"
                                    style="cursor: pointer;"
                                    :class="{ 'game-selected': isActive }"
                                    :href="href"
                                    @click="navigate"
                                >{{section.name}}</span>
                            </li>
                        </router-link>
                    </li>
                </ul>
                
                <div v-else class="d-flex w-100" key="2" >
                    <div
                        class="nav-link navbar-rubik game-selected my-auto"
                        style="cursor: pointer; margin-left: 70px; min-width:100px"
                        @click="selectedSection = null"
                    >{{selectedSection.name}}</div>
                    <div class="w-100 my-auto text-center d-flex flex-sm-column flex-md-row justify-content-center">
                        <component :is="selectedSection.filterBar" v-if="selectedSection.filterBar" />
                        <div v-else>Filters coming soon</div>

                        <button v-if="selectedSection.advancedBar" class="btn btn-primary btn-primary-fancy ml-4 lg-button-advanced" @click="toggleAdvancedBar($event)">
                            <span class="mr-2"><img src="@/assets/slide.svg" /></span>    
                            Advanced
                        </button>
                    </div>
                </div>
            </transition>
        </nav>

        <!-- Advanced Search -->
        <component 
            v-if="selectedSection != null && selectedSection.advancedBar && advancedOpen" 
            :is="selectedSection.advancedBar"
            @
            class="justify-content-center"
            />
    </div>
</template>

<script>
import Search from "../components/navbar-widgets/Search";
import Notifications from "../components/navbar-widgets/Notifications";
import Cart from "../components/navbar-widgets/Cart";
import Profile from "../components/navbar-widgets/Profile";

import SearchFilterBar from "./navbar-widgets/filters/search.vue";

export default {
    data() {
        return {
            selectedSection: null,
            advancedOpen: false,
            sections: [
                // { name: "CS:GO", path: "/csgo", filterBar: () => import('./navbar-widgets/filters/csgo.vue'), advancedBar: () => import('./navbar-widgets/advanced/csgo.vue') },
                // { name: "Dota 2", path: "/dota2", filterBar: () => import('./navbar-widgets/filters/dota2.vue'), advancedBar: () => import('./navbar-widgets/advanced/dota2.vue') },
                { name: "TF2", path: "/tf2", filterBar: () => import('./navbar-widgets/filters/tf2.vue'), advancedBar: () => import('./navbar-widgets/advanced/tf2.vue') },
                // { name: "Z1:BR", path: "/z1br", filterBar: () => import('./navbar-widgets/filters/z1br.vue') },
                // { name: "Rust", path: "/rust", filterBar: () => import('./navbar-widgets/filters/rust.vue') },
                { name: "New", path: "/new" , filterBar: () => import('./navbar-widgets/filters/new.vue') },
                { name: "Recommended", path: "/recommended", filterBar: () => import('./navbar-widgets/filters/recommended.vue') },
                { name: "Watchlist", path: "/watchlist", filterBar: () => import('./navbar-widgets/filters/watchlist.vue') },
                { hidden: true, name: "Search", path: "/search", filterBar: SearchFilterBar },
            ]
        }
    },
    mounted() {
        console.log("Loaded header.vue");
        let foundSection = this.sections.find(x=> x.path == this.$route.path);
        if (foundSection) {
            this.selectedSection = foundSection;
            console.log("Found matched section! ")
            
            if (this.$route.query.market_hash_name) {
                console.log("adding")
                this.$store.dispatch("filters/updateFilter", { filter: "market_hash_name", value: this.$route.query.market_hash_name })
            }

            if (this.$route.query.query) {
                this.$store.dispatch('filters/updateFilter', { filter: "query", value: this.$route.query.query });
            }

        } else {
            console.log("Missed!");
            this.selectedSection = null;
        }
    },
    components: {
        Search,
        Notifications,
        Cart,
        Profile,
    },
    computed: {
        user() {
            return this.$store.getters["auth/user"];
        },
        cartTotal() {
            return this.$store.getters["cart/total"];
        },
        hasCartItems() {
            return this.$store.getters["cart/hasItems"];
        },
        cartItems() {
            return this.$store.getters["cart/items"];
        },
        cartCount() {
            return this.$store.getters["cart/count"];
        },
        loggedIn() {
            return this.$store.getters["auth/loggedIn"];
        },
        showGameBar() {
            if (this.$route.name == 'Sell') return false;
            return true;
        }
    },
    methods: {
        toggleAdvancedBar(e) {
            console.log("Toggling advanced");
            e.target.blur();
            this.advancedOpen = !this.advancedOpen

        },
        async clearFilters(){
            await this.$store.dispatch('filters/clearFilters');
        },
        toggleDropdown(dropdown, state) {
            if (state != true) return;

            const dropdowns = [ 'notifications', 'cart', 'profile' ];

            let dropdownsToClose = dropdowns.filter(x => x != dropdown);
            for (let dropdownToClose of dropdownsToClose) {
                if (this.$refs[dropdownToClose].isDropdownOpen) {
                    this.$refs[dropdownToClose].toggle(false);
                }
            }
        }
    },
    watch: {
        async $route() {
            console.log("DETECTED ROUTE CHANGE!")
            this.isCartOpen = false;
            this.isNotificationsOpen = false;
            this.isUserMenuOpen = false;
            this.isSearchOpen = false;

            let foundSection = this.sections.find(x=> x.path == this.$route.path);
            if (foundSection) {
                this.selectedSection = foundSection;
                console.log("Found matched section! ")
                await this.clearFilters();
                if (this.$route.query.market_hash_name) {
                    console.log("adding")
                    await this.$store.dispatch("filters/updateFilter", { filter: "market_hash_name", value: this.$route.query.market_hash_name })
                }
                if (this.$route.query.query) {
                    console.log("adding")
                    await this.$store.dispatch("filters/updateFilter", { filter: "query", value: this.$route.query.query })
                }
            } else {
                console.log("Missed!");
                this.selectedSection = null;
            }
        },
    }
}
</script>

<style lang="scss">

.nav-link {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;

    color: black !important;
    // padding: 0 4px !important;
}

.navbar-collapse {
    background-color: #1E2021;
}

.game-selector {-webkit-box-shadow: 0px 10px 10px -10px rgba(0,0,0,0.4);
-moz-box-shadow: 0px 10px 10px -10px rgba(0,0,0,0.4);
box-shadow: 0px 10px 10px -10px rgba(0,0,0,0.4);
}

@media (min-width: 992px) and (max-width: 1199.98px) { 
    .lg-button-advanced {
        height: 20px !important; // TODO: Adjust text position
    }
}
</style>