<template>
    <div
        v-on-clickaway="hideDropdown"
        @keyup.esc="onEscape"
        @keydown.up.prevent="typeAheadUp"
        @keydown.down.prevent="typeAheadDown"
        @keydown.enter.prevent="typeAheadSelect"
        class="v-select"
        :class="{'disabled': disabled, 'big': !searchable, 'v-select-smol': !showDropdownIcon }"
    >
        <div class="d-flex" v-if="!searchable && !onlyRange" @click="toggle">
            <button  type="button" class="v-select-toggle">
                <div class="v-title">{{ title }}</div>
                <div class="arrow-up-down"></div>
            </button>
            <div v-if="showDropdownIcon" class="ml-2 v-select-toggle-icon">
                <img src="@/assets/updown.svg" alt="" class="d-block m-auto">
            </div>

            <div style="position: absolute;">
                <img 
                    v-if="resettable"
                    src="@/assets/cancelStickerSelection.svg"
                    class="cancel-icon" 
                    :class="{ 'sbig': !searchable }" 
                    @click="reset"
                />
            </div>

        </div>

        <div class="d-flex" v-if="!searchable && onlyRange">
            <div class="d-flex px-2 v-bs-ranger">
                <input 
                    type="text"
                    class="v-title v-ranger "
                    v-bind:value="rangeMin"
                    v-on:keydown="customRange('min')"
                    style="text-align: center;"
                    @click="fieldClicked('min')"
                    />
                <div class="v-hr-spacer mx-1"></div>
                <input
                    type="text"
                    class="v-title v-ranger"
                    v-bind:value="rangeMax"
                    v-on:keydown="customRange('max')"
                    style="text-align: center;"
                    @click="fieldClicked('max')"
                    />    
            </div>
        </div>

        <div v-if="searchable" v-show="searchable" class="v-bs-searchbox">
            <div>
                <input
                    :placeholder="labelSearchPlaceholder"
                    class="v-select-toggle"
                    type="text"
                    v-model="searchValue"
                    @click="toggle"
                    :style="{ 'padding-left': value && value.image ? '45px !important' : '0px', 'color': value && value.color ? value.color : 'black' }"
                    >
                <span class="v-search-icon" style="z-index:0;">
                    <img class="v-dropdown-item-image" v-if="value && value.image" :src="value.image" style="margin-top:0px; height: 24px; max-height: 24px;"/>
                    <font-awesome-icon icon="search" v-else style="color: #091629" />
                </span>
            </div>
            <div style="position: absolute;">
                <img  v-if="value && value.value" src="@/assets/cancelStickerSelection.svg" class="cancel-icon-search" :class="{ 'sbig': adv }" @click="reset">
            </div>
        </div>


        <div v-show="show" class="v-dropdown-container" v-if="!onlyRange" :style="{ left: left }">
            <div v-if="rangeable" v-show="rangeable" class="v-bs-ranger px-2 pb-2" style="border-bottom: 1px solid #E8E8E8">
                <div class="d-flex">
                    <div class="v-ranger-label px-2 pb-1 mt-1">
                        {{labelRangePlaceholder}}
                    </div>
                    <div class="d-flex px-2">
                        <div class="d-flex">
                            <div class="input-group-prepend" v-if="rangePrefix" style="">
                                <span class="input-group-text text-center" style="border-top-right-radius: 0;border-bottom-right-radius: 0; font-size: 12px; padding-left: 5px; padding-right: 5px; padding-top:0; padding-bottom: 0;">{{rangePrefix}}</span>
                            </div>
                            <!-- <input 
                                type="text" 
                                class="v-ranger" 
                                v-model="rangeMin" 
                                @change="customRange('min')" 
                                v-on:keydown.enter="customRange('min')" 
                                @keydown="rangeFilter('min', $event)" 
                                style="text-align: center;"
                                :style="rangeStyleWithPrefix"
                                /> -->
                            <RangeInput
                                :value="rangeMin"
                                placeholder="MIN"
                                :options="{
                                    currency: 'USD',
                                    currencyDisplay: 'hidden',
                                    hideCurrencySymbolOnFocus: false,
                                    hideGroupingSeparatorOnFocus: false,
                                    hideNegligibleDecimalDigitsOnFocus: false,
                                    autoDecimalDigits: autoDecimalLast,
                                    useGrouping: true,
                                    valueRange: { min: defaultRangeMin, max: defaultRangeMax },
                                    precision: rangePrecsion
                                }"

                                @change="rangeMin = $event; customRange('min', $event)"
                                v-on:keydown.enter="rangeMin = $event; customRange('min', $event)" 


                                class="v-ranger" 
                                :style="rangeStyleWithPrefix" />
                        </div>
                        <div class="v-hr-spacer mx-2"></div>

                        <div class="d-flex">
                            <div class="input-group-prepend" v-if="rangePrefix" style="">
                                <span class="input-group-text text-center" style="border-top-right-radius: 0;border-bottom-right-radius: 0; font-size: 12px; padding-left: 5px; padding-right: 5px; padding-top:0; padding-bottom: 0;">{{rangePrefix}}</span>
                            </div>
                            <RangeInput
                                :value="rangeMax"
                                placeholder="MAX"
                                :options="{
                                    currency: 'USD',
                                    currencyDisplay: 'hidden',
                                    autoDecimalDigits: autoDecimalLast,
                                    hideCurrencySymbolOnFocus: false,
                                    hideGroupingSeparatorOnFocus: false,
                                    hideNegligibleDecimalDigitsOnFocus: false,
                                    useGrouping: true,
                                    valueRange: { max: defaultRangeMax, max: defaultRangeMax },
                                    precision: rangePrecsion
                                }"

                                @change="rangeMax = $event; customRange('max', $event)"
                                v-on:keydown.enter="rangeMax = $event; customRange('max', $event)" 


                                class="v-ranger" 
                                :style="rangeStyleWithPrefix" />
                            <!-- <input :style="rangeStyleWithPrefix" type="text" class="v-ranger" v-model="rangeMax" @change="customRange('max')" v-on:keydown.enter="customRange('max')" @keydown="('max', $event)" style="text-align: center;"/> -->
                        </div>
                    </div>
                </div>
            </div>
            <vue-custom-scrollbar tagname="div">
                <ul style="min-width: 140px">
                    <li
                        v-show="searchable && filteredOptions.length === 0"
                        class="v-dropdown-item"
                    >{{ labelNotFound }} "{{ searchValue }}"</li>
                    <li
                        v-if="showDefaultOption"
                        class="v-dropdown-item disabled default-option"
                    >{{ labelTitle }}</li>
                    <li
                        v-for="(option, index) in filteredOptions"
                        :key="`v-select-${index}`"
                        class="v-dropdown-item"
                        :class="{'selected' : isSelectedOption(option, index), 'disabled': option[disabledProp]}"
                        :style="{ 'color': option.color, 'text-shadow': option.textshadow }"
                        @click="onSelect(option, index)"
                        ><img class="v-dropdown-item-image" v-if="option.image" :src="option.image" />{{ getOptionLabel(option) }}</li>
                </ul>
            </vue-custom-scrollbar>
        </div>
    </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import { mixin as clickaway } from "vue-clickaway";
import PriceRanger from './PriceRanger.vue';
import RangeInput from './RangeInput.vue';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';
import { EventBus } from "@/utils/eventBus.js";

export default {
    name: "FilterBox",
    mixins: [ clickaway ],
    props: {
        left: {
            type: String,
            default: "0px",
        },
        adv: {
            type: Boolean,
            default: false,
        },
        nocustom: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        disabledProp: {
            type: String,
            default: "disabled"
        },
        labelTitle: {
            type: String,
            default: "Nothing selected"
        },
        labelNotFound: {
            type: String,
            default: "No results matched"
        },
        labelSearchPlaceholder: {
            type: String,
            default: "Search"
        },
        labelRangePlaceholder: {
            type: String,
            default: "Search"
        },
        rangePrefix: {
            type: String,
            default: ""
        },
        defaultRangeMin: {
            type: Number,
            default: 0,
        },
        rangePrecsion: {
            type: Number,
            default: 0,
        },
        autoDecimalLast: {
            type: Boolean,
            default: false,
        },
        defaultRangeMax: {
            type: Number,
            default: 1,
        },
        options: {
            type: Array,
            default: () => []
        },
        searchable: {
            type: Boolean,
            default: false
        },
        rangeable: {
            type: Boolean,
            default: false
        },
        onlyRange: {
            type: Boolean,
            default: false
        },
        showDropdownIcon: {
            type: Boolean,
            default: false
        },
        showDefaultOption: {
            type: Boolean,
            default: false
        },
        textProp: {
            type: String,
            default: "text"
        },
        value: {
            type: [Object, String, Number],
            default: null
        },
        valueProp: {
            type: String,
            default: "value"
        },
        clickToReset: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        vueCustomScrollbar,
        PriceRanger,
        RangeInput
    },
    data() {
        return {
            show: false,
            selectedValue: null,
            searchValue: "",
            typeAheadPointer: -1,
            rangeMin: this.defaultRangeMin || 0,
            rangeMax: this.defaultRangeMax || 1,
            initialValue: null,
        };
    },
    mounted() {
        this.initialValue = this.value;
        EventBus.$on('filter-toggle', () => {
            this.hideDropdown();
        })  
    },
    computed: {
        resettable() {
            return this.value && 
                    (typeof (this.value.value) != "undefined") && (this.value.value != null) 
                    && ((this.rangeable && (this.value.value.min != null || this.value.value.max != null) || !this.rangeable))
        },
        rangeStyleWithPrefix() {
            if (this.rangePrefix) {
                return {
                    "border-top-left-radius": 0,
                    "border-bottom-left-radius": 0,
                }
            } else {
                return {};
            }
        },
        title() {
            return this.selectedValue
                ? this.getOptionLabel(this.selectedValue)
                : this.labelTitle;
        },
        filteredOptions() {
            if (this.searchable && this.searchValue && this.searchValue.length > 0) {
                return this.options.filter(item => {
                    if (typeof item === "object") {
                        return (
                            item[this.textProp]
                                .toLowerCase()
                                .indexOf(this.searchValue.toLowerCase()) !== -1
                        );
                    } else {
                        return (
                            item.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1
                        );
                    }
                });
            }
            return this.options;
        },
        reversedOptions() {
            return [...this.filteredOptions].reverse();
        },
        lastOptionIndex() {
            return this.filteredOptions.length - 1;
        }
    },
    watch: {
        value: {
            immediate: false, // This calls it as soon as we load
            deep: true,
            handler(newVal) {
                const index = this.options.findIndex(op =>
                    this.isEqualOption(op, newVal)
                );
                console.log("I've seen value change ", newVal)
                this.onSelect(newVal, index);
            }
        },
        selectedValue: {
            immediate: false,
            deep: true,
            handler(newVal) {
                const index = this.options.findIndex(op =>
                    this.isEqualOption(op, newVal)
                );
                console.log("I've seen selectedvalue change", newVal)
                this.onSelect(newVal, index);
            }
        }
    },
    methods: {
        // rangeFilter(bound, event){

        //     if (this.labelRangePlaceholder == "FLOAT") {
        //         event.preventDefault();
        //         try { 
        //             if ((event.keyCode >= 48 && event.keyCode <= 57)){ // Number or backspace input
        //                 if(bound === 'min') {
        //                     let ourNumber = this.rangeMin.split('');
        //                     ourNumber.shift(); // get rid of numbah 1 
        //                     ourNumber.shift(); // get rid of .
        //                     ourNumber.splice(1, 0, '.'); // add a .
        //                     ourNumber.push(event.key);

        //                     if (parseInt(ourNumber[0]) > 0) ourNumber[0] = '0' // cap at 0
        //                     this.rangeMin = ourNumber.join("");

        //                     // Add a . at the 1st index
                            
        //                     // ourNumber = ourNumber.slice(0, 1)                                                        
        //                     // ourNumber += event.key; // 03
        //                     // this.rangeMin = "0." + ourNumber; // 0.03
        //                 } else if (bound === 'max') {
        //                     let ourNumber = this.rangeMax.split('');
        //                     ourNumber.shift(); // get rid of numbah 1 
        //                     ourNumber.shift(); // get rid of .
        //                     ourNumber.splice(1, 0, '.'); // add a .
        //                     ourNumber.push(event.key);

        //                     if (parseInt(ourNumber[0]) > 0) ourNumber[0] = '0' // cap at 0
        //                     this.rangeMax = ourNumber.join("");

        //                 }
        //             }
                        
        //         } catch (e) {
        //             console.error(e.stack);
        //         }
        //     }
        // },
        addCurrencySymbol(val){
            // if (val[0] == this.rangePrefix){
            //     return val.substring(1)
            // }
            // return val
            return val.replace(this.rangePrefix, "")
        },
        onSelect(option, index) {
            if (option && !option[this.disabledProp]) {
                this.selectedValue = option;
                this.typeAheadPointer = index;
                this.hideDropdown();
                this.$emit("input", option, option[this.valueProp], index);
                this.searchValue = this.selectedValue.text || "";

                if (this.rangeable && this.selectedValue && this.selectedValue.value) {
                    this.rangeMin = this.selectedValue.value.min;
                    this.rangeMax = this.selectedValue.value.max;
                }
            } else if (option === null) {
                this.selectedValue = null;
            }
        },
        onEscape() {
            this.hideDropdown();
        },
        typeAheadUp() {
            if (!this.show) {
                this.show = true;
            }
            if (this.typeAheadPointer > 0) {
                const nextPointer = this.typeAheadPointer - 1;
                const option = this.filteredOptions[nextPointer];
                const isDisabled = option ? option[this.disabledProp] || false : false;
                if (!isDisabled) {
                    this.typeAheadPointer--;
                } else {
                    this.typeAheadPointer--;
                    this.typeAheadUp();
                }
            } else {
                const nextEnabledOption = this.reversedOptions.findIndex(
                    o => o[this.disabledProp] !== true
                );
                this.typeAheadPointer = this.lastOptionIndex - nextEnabledOption;
            }
        },
        typeAheadDown() {
            if (!this.show) {
                this.show = true;
            }
            if (this.typeAheadPointer < this.lastOptionIndex) {
                const nextPointer = this.typeAheadPointer + 1;
                const option = this.filteredOptions[nextPointer];
                const isDisabled = option ? option[this.disabledProp] || false : false;
                if (!isDisabled) {
                    this.typeAheadPointer++;
                } else {
                    this.typeAheadPointer++;
                    this.typeAheadDown();
                }
            } else {
                const nextEnabledOption = this.filteredOptions.findIndex(
                    o => o[this.disabledProp] !== true
                );
                this.typeAheadPointer = nextEnabledOption;
            }
        },
        typeAheadSelect() {
            if (this.filteredOptions[this.typeAheadPointer]) {
                this.onSelect(
                this.filteredOptions[this.typeAheadPointer],
                this.typeAheadPointer
                );
            }
        },
        hideDropdown() {
            this.show = false;
            if (this.selectedValue)
                this.searchValue = this.selectedValue.text;
            else
                this.searchValue = null;
        },
        getOptionLabel(option) {
            if (typeof option === "object") {
                return option[this.textProp];
            }
            return option;
        },
        isSelectedOption(option, index) {
            if (this.typeAheadPointer === -1 && this.selectedValue) {
                return this.isEqualOption(option, this.selectedValue);
            }
            return this.typeAheadPointer === index;
        },
        isEqualOption(a, b) {
            if (a && b && typeof a === "object" && typeof b === "object") {
                return (
                a[this.textProp] === b[this.textProp] &&
                a[this.valueProp] === b[this.valueProp]
                );
            }
            return a === b;
        },
        toggle() {
            EventBus.$emit('filter-toggle');
            if (!this.disabled || this.doNotToggle) {
                this.show = !this.show;
                this.searchValue = "";
            }
            this.doNotToggle = false;
        },
        reset() {
            // this.selectedValue = this.initialValue;
            if (this.searchable) this.searchValue = "";

            this.value = this.initialValue;
            // if (this.rangeable) this.value = { text: "Test", value: { min: null, max: null }, };
            this.doNotToggle = true;
            this.show = false;
        },
        fieldClicked(bound) {
            if (!this.clickToReset) return;


            if (bound == "min") {
                this.selectedValue.value.min = "";
            }
            if (bound == "max") {
                this.selectedValue.value.max = "";
            }
        },
        customRange(bound, val) {
            console.log(event)
            // this.selectedValue = null;
            console.log("Custom range called");
            this.selectedValue.value = this.selectedValue.value || { min: null, max: null };
            let min = parseFloat(this.selectedValue.value.min);
            let max = parseFloat(this.selectedValue.value.max);
            
            if (bound == "min") min = parseFloat(val);
            if (bound == "max") max = parseFloat(val);

            console.log(`%c selectedValue, range %s %s`, 'background: #222; color: #bada55', this.selectedValue.value.min, this.rangeMin);

            // console.log("Setting new value: ", min, max);

            let newValue = {
                value: {
                    min, max
                },
                text: `${this.rangePrefix}${min} - ${this.rangePrefix}${max}`
            }

            this.selectedValue = newValue;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/scss/brand.scss';
* {
    box-sizing: border-box;
}

input {
    width: 100%;
}

ul {
    font-size: 14px;
    color: #424242;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    padding: 0px;
    margin: 0px 0px 0px 0px;
    max-height: 200px;
}

.v-select {
    position: relative;
    width: 100%;
    min-width: 200px;
    height: 30px;
    cursor: pointer;
    // width: 130px;

    &.disabled {
        cursor: not-allowed;

        .v-select-toggle {
            background-color: #f8f9fa;
            border-color: #f8f9fa;
            opacity: 0.65;
            cursor: not-allowed;

            &:focus {
                outline: 0 !important;
            }
        }
    }

    min-width: 130px;
    max-width: 130px;
    width: 130px;
    text-overflow: ellipsis;

    &.big {
        min-width: 167px;
        max-width: 167px;
        width: 167px;
    }
}

.v-select-smol {
    min-width: 130px !important;
    max-width: 130px !important;
    width: 130px !important;
}

.v-select-toggle {
    display: flex;
    justify-content: space-between;
    user-select: none;
    padding: 0.375rem 0.75rem;  
    width: 100%;
    text-align: left;
    white-space: nowrap;
    // padding: 0.375rem 0.75rem;
    transition: background-color, border-color, box-shadow, 0.15s ease-in-out;
    cursor: pointer;

    &:hover {
        background-color: #e2e6ea;
        border-color: #dae0e5;
    }

    min-width: 130px;
    // height: 26px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: $color-teal;

    outline: none;
    border-radius: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
    border: 1px solid #465b7a;
    background-color: #fff;

    &:focus {
        outline: none;
        border: 1px solid $primary;
        // outline: 1px solid $primary;
        box-shadow: 0 0 1pt 1pt $primary;
    }

    min-width: 130px;
    max-width: 130px;
    width: 130px;
    text-overflow: ellipsis;
}

.v-ranger {
    width: 65px;
    height: 27px;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(30, 32, 33, 0.148492);
    border-radius: 13px;
    padding: 0 10px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #1E2021;
}

.v-ranger-label {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    /* identical to box height */

    letter-spacing: 1px;
    text-transform: uppercase;

    color: #1E2021;

    mix-blend-mode: normal;
    opacity: 0.6;
}

.v-hr-spacer {
    width: 10px;
    height: 2px;
    background-color: $primary;
    margin: auto auto;
}

.v-dropdown-container {
    position: absolute;
    width: max-content;
    background: #fff;
    padding: 0.5rem 0;
    margin: 10px 0 0 0px;
    color: #212529;
    text-align: left;
    list-style: none;
    background-clip: padding-box;
    z-index: 1000;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #1E2021;

    border: 1px solid #E8E8E8;
    box-shadow: 0px 0px 10px rgba(30, 32, 33, 0.15);
    border-radius: 4px;

}

.v-dropdown-item {
    text-decoration: none;
    line-height: 32px;
    padding: 0px 10px 0px 10px;
    user-select: none;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: $color-primary;

    &:hover:not(.default-option) {
        // background-color: #f8f9fa;
        color: $primary;
    }

    &.disabled {
        color: #9a9b9b;
    }

    &.selected {
        // background-color: #007bff;
        color: $primary;


        &:hover {
        // background-color: #007bff;
        color: $primary;
        }
    }

    &.disabled {
        cursor: not-allowed;

        &:hover {
        background-color: #fff;
        }
    }
}

.v-bs-searchbox {
    .v-select-toggle {
        padding-left: 22px !important;
    }
}
.v-search-icon {
    position: absolute;
    z-index: 5;
    pointer-events: none;
    left: 5px;
    top: 0px;
    
    svg {  
        height: 12px;
        color: $primary;
        & {
            color: $primary;
        }
    }
}

.v-select-toggle-icon {
    background-color: rgba(237, 107, 51, 0.1); 
    min-width: 28px !important; 
    width: 28px !important; 
    min-height: 28px !important; 
    height: 28px !important; 
    border-radius:50%;

    padding: 8px;

    img {
        display: block;
        margin: auto auto;
    }
}

.v-title {
    text-overflow: ellipsis;
    overflow: hidden;
    color: $color-primary-darker;
}
.v-dropdown-item-image {
    display: inline-block;
    max-height: 28px;
    max-width: 28px;
    // height:28px;
    height: auto;
    width: auto;

    // padding-right:5px;
    border-radius: 50%;
    margin-right:10px;

}

.cancel-icon {
    position: relative;
    height: 12px;

    top: -10px;
    left: 120px;
    width: 100%;
}
.cancel-icon:hover{
    filter: invert(100%) sepia(85%) saturate(800%) hue-rotate(312deg) brightness(98%) contrast(640%);
    width: 130%;
}

.cancel-icon-search {
    position: relative;
    height: 12px;

    top: -40px;
    left: 120px;
    width: 100%;

    &.sbig {
        left: 120px;
    }
}

.cancel-icon-search:hover{
    filter: invert(100%) sepia(85%) saturate(800%) hue-rotate(312deg) brightness(98%) contrast(640%);
    width: 130%;
}
</style>