import Vue from 'vue'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// import "../scss/brand.scss";
// import "../scss/guard.scss";


Vue.use(BootstrapVue)
