<template>
  <div class="container h-75" style="margin-top: 40px">
    <div class="row align-items-center h-75">
      <div class="col-6 mx-auto">
        <div class="card-head gdark">
          <div class="d-flex justify-content-center align-items-center h-100">
            <div class="mr-4">
              <router-link to="/" v-slot="{ href, navigate }">
                <a class="navbar-brand" :href="href" @click="navigate">
                  <img
                    src="@/assets/key-icon.svg"
                    style="max-height: 35px; max-width: 30px"
                  />
                  <span
                    class="text-white"
                    style="
                      margin-left: 5px;
                      font-family: Rubik;
                      font-size: 22px;
                      font-weight: bold;
                    "
                    >GUARD</span
                  >
                  <span
                    class="text-secondary"
                    style="
                      font-family: Rubik;
                      font-size: 22px;
                      font-weight: bold;
                    "
                    >.GG</span
                  >
                </a>
              </router-link>
            </div>
          </div>
        </div>
        <div class="card-body w-100">
          <div class="mt-2 text-center">
            <h3>Create New Account</h3>
          </div>
          <div class="mt-4 mx-auto w-75">
            <input
              class="mt-4 form-control"
              :style="isEmpty(username)"
              type="text"
              name="Username"
              id="Username"
              placeholder="Username"
              v-model="username"
            />
            <div v-for="item in invalidUsernameReqs" class="mt-2">
              <span>{{ item }}</span>
            </div>
            <div :class="['', highlightEmail()]">
              <input
                class="mt-4 form-control"
                :style="isEmpty(email)"
                type="email"
                name="Email"
                id="Email"
                placeholder="Email"
                v-model="email"
              />
            </div>
            <div v-for="item in invalidEmailReqs" class="mt-2">
              <span>{{ item }}</span>
            </div>
            <input
              class="mt-4 form-control"
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              v-model="password"
            />
            <input
              class="mt-4 form-control"
              type="password"
              name="password-confirm"
              id="password-confirm"
              placeholder="Repeat Password"
              v-model="passwordConfirm"
              v-on:keyup.enter="register()"
            />

            <button
              class="btn btn-primary mt-5 w-100 createAccButton"
              v-on:click="register"
            >
              CREATE ACCOUNT
            </button>
            <div class="w-75 mt-4 container">
              <div class="row">
                <div class="col"></div>
                <div class="col-12">
                  <span class="text-muted tosSpan">
                    By creating an account you agree to our
                    <a href="#0" style="text-decoration: underline"
                      >Terms of Service</a
                    >
                    <!-- and
                    <a href="#0" style="text-decoration: underline"
                      >Privacy Policy</a
                    > -->
                  </span>
                </div>
                <div class="col"></div>
              </div>
            </div>
            <div class="mt-2">
              <span
                class="d-flex justify-content-center mt-5 f3"
                style="font-size: 1.2em"
              >
                Already have an account?
                <router-link
                  to="/login"
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
                >
                  <a
                    class="pa ml-2"
                    :href="href"
                    @click="navigate"
                    style="font-weight: 500"
                    >Sign In</a
                  >
                </router-link>
              </span>
            </div>

            <div class="mt-3">
              <div class="separator" style="font-size: 14px">
                Or Connect Using Steam
              </div>
            </div>

            <div class="my-3 d-flex justify-content-center">
              <button @click="loginWithSteam()" class="btn steamLoginButton">
                <img src="@/assets/steam-logo.svg" />
                <span style="margin-left: 5px; color: white">Steam Login</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="container"
      style="width: 30%; position: relative; left: 34%; bottom: 60%"
    >
      <div
        class="mt-5 d-flex flex-column"
        style="margin-left: 100px; background-color: white"
      >
        <div class="card-body">
          <h3>Password Requirements</h3>
          <div class="d-flex flex-column">
            <span :class="reqsMinChars ? 'text-success' : 'text-danger'"
              >Minimum 8 characters</span
            >
            <span :class="reqsUpperCase ? 'text-success' : 'text-danger'"
              >Uppercase letter</span
            >
            <span :class="reqsLowerCase ? 'text-success' : 'text-danger'"
              >Lowercase letter</span
            >
            <span :class="reqsDigits ? 'text-success' : 'text-danger'"
              >At least 1 digit</span
            >
            <span :class="reqsSymbols ? 'text-success' : 'text-danger'"
              >At least 1 symbol</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import momenttimezone from "moment-timezone";
import Swal from "sweetalert2";
let passwordValidator = require("password-validator");
let schema = new passwordValidator()
  .is()
  .min(8)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .symbols();

export default {
  data() {
    return {
      username: "",
      email: "",
      password: "",
      passwordConfirm: "",
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      invalidUsernameReqs: [],
      invalidEmailReqs: [],
      reqsMinChars: false, // False = not validated
      reqsLowerCase: false,
      reqsUpperCase: false,
      reqsDigits: false,
      reqsSymbols: false,
    };
  },
  components: {},
  computed: {
    loggedIn() {
      return this.$store.getters["auth/loggedIn"];
    },
  },
  methods: {
    updatePasswordRequirements() {
      let passwordReqs = schema.validate(this.password, { list: true });

      this.reqsMinChars = passwordReqs.includes("min") ? false : true;
      this.reqsLowerCase = passwordReqs.includes("lowercase") ? false : true;
      this.reqsUpperCase = passwordReqs.includes("uppercase") ? false : true;
      this.reqsDigits = passwordReqs.includes("digits") ? false : true;
      this.reqsSymbols = passwordReqs.includes("symbols") ? false : true;
    },
    loginWithSteam() {
      console.log("Yo");
      this.$store.dispatch("auth/loginWithSteam", { rememberMe: true }); // On the registration page, redirect them maybe to capture the remember option? Or remove steam login here?
    },
    async register() {
      // Clear fields before checking again
      this.invalidUsernameReqs = [];
      this.invalidEmailReqs = [];
      this.invalidPassReqs = [];
      if (this.username === "") {
        this.invalidUsernameReqs.push("Username field cannot be empty.");
        return;
      }
      if (this.email === "") {
        this.invalidEmailReqs.push("Email field cannot be empty.");
        return;
      } else if (!this.reg.test(this.email)) {
        this.invalidEmailReqs.push("Invalid email address entered.");
        return;
      }
      // Password validation
      if (this.password.length == 0) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "You must specify a password!",
        });
        return;
      }

      if (!schema.validate(this.password)) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Password requirements not met!",
        });
        return; // Ghetto?
      }

      if (this.password !== this.passwordConfirm) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Passwords must match!",
        });
        return;
      }

      let info = {
        username: this.username,
        email: this.email,
        password: this.password,
        timezone: moment.tz.guess(),
      };

      Swal.showLoading();
      this.$store
        .dispatch("auth/register", info)
        .then((res) => {
          Swal.close();
        })
        .catch((error) => {
          Swal.close();
          
          if (error.response && error.response.status === 409) {
            // Bad conflict (duplicate username/email)
            switch (error.response.data.reason) {
              case "Duplicate email":
                this.invalidEmailReqs.push(
                  "User already exists with that email."
                );
                break;
              case "Duplicate username":
                this.invalidUsernameReqs.push(
                  "User already exists with that username."
                );
                break;
            } 
          } else {
            console.error(error);
            this.invalidUsernameReqs.push(
              "An unknown error occured"
            );
          }
        });
    },
    highlightEmail() {
      return this.email == ""
        ? ""
        : this.reg.test(this.email)
        ? "has-success"
        : "has-error";
    },
    isEmpty(value) {
      return (value && value.length) > 0
        ? "background: #FFFFFF;"
        : "background: #E6E6E6;";
    },
    parseInvalidPassReqs(requirements) {
      let reasons = [];
      for (let i = 0; i < requirements.length; i++) {
        if (requirements[i] === "min") {
          reasons.push("Password must have a minimum of 8 characters.");
        } else if (requirements[i] === "uppercase") {
          reasons.push("Password must contain at least 1 uppercase character.");
        } else if (requirements[i] === "digits") {
          reasons.push("Password must contain at least 1 digit.");
        } else if (requirements[i] === "symbols") {
          reasons.push("Password must contain at least 1 symbol.");
        }
      }
      return reasons;
    },
  },
  watch: {
    loggedIn(newVal, oldVal) {
      if (newVal) {
        this.$router.push("/");
      }
    },
    password() {
      this.updatePasswordRequirements();
    },
  },
};
</script>

<style lang="scss" scoped>
.card-head {
  height: 70px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card-body {
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-error .form-control:focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.has-success .form-control {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-success .form-control:focus {
  border-color: #2b542c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}

input {
  border-radius: 10px;
  background: #e6e6e6;
}

h3 {
  color: #545454;
}

input::placeholder {
  color: #ababab;
}

.createAccButton {
  height: 45px;
  letter-spacing: 2.5px;
}

.tosSpan {
  font-size: 12px;
  font-family: Noto Sans;
}

.steamLoginButton {
  color: white;
  background: #545454;
  border: 1px solid #1d2021;
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
}
</style>