import Register from '@/views/Register';
import Login    from '@/views/Login';

const authRoutes = [
    {
        path: '/register',
        name: 'register',
        component: Register
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
]

export default authRoutes;
