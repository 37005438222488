<template>
  <div class="home" style="overflow-y: auto">
    <div class="home-background">
      <div class="container">
        <div class="">
          &nbsp;
          <!-- <div style="padding-top: 70px">
            <div
              style="
                font-family: Rubik;
                font-style: normal;
                font-weight: bold;
                font-size: 36px;
                letter-spacing: 2px;
                color: #ffffff;
              "
            >
              5% Fees. Forever.
            </div>
            <div
            class="c-primary"
              style="
                font-family: Rubik;
                font-style: normal;
                font-weight: bold;
                font-size: 36px;
                letter-spacing: 2px;
              "
            >
              2% fees on liquid items.
            </div>
          </div> -->

          <!-- TODO: Check logged in  -->
          <!-- <div v-if="!loggedIn" style="padding-top: 240px; padding-left: 10px;"> -->
            <!-- <router-link
              to="/register"
              v-slot="{ href, navigate }"
            >
              <a :href="href" @click="navigate">
                <div class="btn btn-orange mt-4 ml-5">SIGN UP</div>
              </a>
            </router-link>
          </div>
        </div> -->
        <!-- <img
          class="home-background-img"
          src="@/assets/home-background.png"
          alt=""
        /> -->
        </div>
      </div>
    </div>

    <div class="container mt-5">
      <div
        v-for="(game, index) of recommendedItems"
        v-bind:key="index"
        scroll-to="last-child"
        style="margin-bottom: 29px"
      >
        <ScrollGameView :game="game" type="recommended" />
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import ScrollGameView from "@/components/ScrollGameView.vue";
import * as mock from "@/mock.js";

export default {
  directives: {
    // VueOverflowScroll
  },
  components: {
    ScrollGameView,
  },
  computed: {
    loggedIn() {
      return this.$store.getters["auth/loggedIn"];
    },
    mounted() {
      console.log("Hello")
    }, 
    recommendedItems() {
      let games = [
        // {
        //   id: 730,
        //   name: "CS:GO",
        //   page: "csgo",
        // },
        {
          id: 440,
          name: "TF2",
          page: "tf2",
        },
        // {
        //   id: 570,
        //   name: "DOTA 2",
        //   page: "dota2",
        // },
        // {
        //   id: 252490,
        //   name: "RUST",
        //   page: "rust",
        // },
        // {
        //   id: 433850,
        //   name: 'Z1BR',
        //   page: 'z1br'
        // },
      ];

      return games;
    },
  },
};
</script>
