var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('nav',{staticClass:"nav navbar navbar-expand-xl navbar-dark gdark px-5",staticStyle:{"height":"56px"}},[_c('div',{staticClass:"mr-4"},[_c('router-link',{attrs:{"to":"/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"navbar-brand ",attrs:{"href":href},on:{"click":navigate}},[_c('img',{staticStyle:{"max-height":"35px","max-width":"30px"},attrs:{"src":require("@/assets/key-icon-teal.svg")}}),_c('span',{staticClass:"text-white",staticStyle:{"margin-left":"5px","font-family":"Rubik","font-size":"22px","font-weight":"bold"}},[_vm._v("GUARD")]),_c('span',{staticClass:"text-teal",staticStyle:{"font-family":"Rubik","font-size":"22px","font-weight":"bold"}},[_vm._v("GG")])])]}}])})],1),_c('ul',{staticClass:"navbar-nav"},[_c('router-link',{attrs:{"to":"/tf2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link text-white",class:{ active: isActive },attrs:{"href":href},on:{"click":function($event){_vm.$route == '/tf2' ? _vm.window.reload() : navigate()}}},[_vm._v("BROWSE")])])]}}])}),_vm._m(0)],1),_c('div',{staticClass:"collapse navbar-collapse gdark",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav mr-auto"},[_c('router-link',{attrs:{"to":"/faq"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link text-white",class:{ active: isActive },attrs:{"href":href},on:{"click":navigate}},[_vm._v("SUPPORT")])])]}}])})],1),_c('div',{staticClass:"mx-auto"},[_c('Search',{staticStyle:{"width":"20vw"}})],1),_c('ul',{staticClass:"ml-auto navbar-nav nav"},[(this.$store.getters['stepByStep/isActive'])?_c('div',{staticClass:"mx-3",staticStyle:{"margin-top":"15px"}},[_c('font-awesome-layers',{staticStyle:{"font-size":"2rem"}},[_c('font-awesome-icon',{staticStyle:{"font-size":"20px","left":"3px","bottom":"-6px"},attrs:{"icon":"circle","color":"white"}}),_c('font-awesome-icon',{staticStyle:{"cursor":"pointer"},attrs:{"icon":"exclamation-triangle","color":"#ED6B33","data-toggle":"modal","data-target":"#stepByStep"}})],1)],1):_vm._e(),_c('router-link',{attrs:{"to":"/sell"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link text-white btn btn-orange",class:{ active: isActive },staticStyle:{"font-size":"16px","font-weight":"700","font-family":"Roboto"},style:({'margin-top': _vm.loggedIn ? '12px' : '0px'}),attrs:{"href":href},on:{"click":navigate}},[_vm._v("SELL")])])]}}])}),(_vm.loggedIn)?_c('Profile',{ref:"profile",on:{"profileToggle":function($event){return _vm.toggleDropdown('profile', $event)}}}):_vm._e(),(!_vm.loggedIn)?_c('li',{staticClass:"nav-item text-white"},[_c('router-link',{attrs:{"to":"/login"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"btn btn-white nav-link",staticStyle:{"text-transform":"none"},attrs:{"href":href},on:{"click":navigate}},[_vm._v("LOGIN")])]}}],null,false,3966124504)})],1):_vm._e(),(!_vm.loggedIn)?_c('li',{staticClass:"nav-item text-white"}):_vm._e()],1)]),_c('Notifications',{ref:"notifications",staticClass:"mx-2",on:{"noticationsToggle":function($event){return _vm.toggleDropdown('notifications', $event)}}}),_c('Cart',{ref:"cart",staticClass:"mx-2",on:{"cartToggle":function($event){return _vm.toggleDropdown('cart', $event)}}})],1),(_vm.showGameBar)?_c('nav',{staticClass:"navbar navbar-expand-lg glight game-selector ",staticStyle:{"z-index":"1"}},[_c('transition',{staticClass:"w-100",attrs:{"name":"fadeLeft","tag":"div","mode":"out-in"}},[(_vm.selectedSection == null)?_c('ul',{key:"1",staticClass:"navbar-nav nav-fill w-100"},_vm._l((_vm.sections.filter(function (x) { return !x.hidden; })),function(section){return _c('li',{key:section.path,staticClass:"nav-item"},[_c('router-link',{attrs:{"to":section.path},nativeOn:{"click":function($event){_vm.selectedSection = section}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{staticClass:"nav-item",class:{ active: isActive }},[_c('span',{staticClass:"nav-link navbar-rubik",class:{ 'game-selected': isActive },staticStyle:{"cursor":"pointer"},attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(section.name))])])]}}],null,true)})],1)}),0):_c('div',{key:"2",staticClass:"d-flex w-100"},[_c('div',{staticClass:"nav-link navbar-rubik game-selected my-auto",staticStyle:{"cursor":"pointer","margin-left":"70px","min-width":"100px"},on:{"click":function($event){_vm.selectedSection = null}}},[_vm._v(_vm._s(_vm.selectedSection.name))]),_c('div',{staticClass:"w-100 my-auto text-center d-flex flex-sm-column flex-md-row justify-content-center"},[(_vm.selectedSection.filterBar)?_c(_vm.selectedSection.filterBar,{tag:"component"}):_c('div',[_vm._v("Filters coming soon")]),(_vm.selectedSection.advancedBar)?_c('button',{staticClass:"btn btn-primary btn-primary-fancy ml-4 lg-button-advanced",on:{"click":function($event){return _vm.toggleAdvancedBar($event)}}},[_c('span',{staticClass:"mr-2"},[_c('img',{attrs:{"src":require("@/assets/slide.svg")}})]),_vm._v(" Advanced ")]):_vm._e()],1)])])],1):_vm._e(),(_vm.selectedSection != null && _vm.selectedSection.advancedBar && _vm.advancedOpen)?_c(_vm.selectedSection.advancedBar,{tag:"component",staticClass:"justify-content-center",on:{"":function($event){}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])}]

export { render, staticRenderFns }