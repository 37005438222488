<template>
    <div class="bg h-100" style="overflow-y: auto" infinite-wrapper>
        <div class="container mt-2 h-100">
            <div v-if="!isLoading && items.length == 0">
                <p class="text-center">No results.</p>
            </div>
            <div v-else class="row d-flex justify-content-start" v-for="(row, i) in renderableItems" v-bind:key="i">
                <div class="" v-for="item in row" v-bind:key="item.id">
                    <Item class="mt-4 mb-2 mx-1" :item="item" :purchasable="true"></Item>
                </div>
            </div>
            <infinite-loading v-if="!errorLoading && currentStringifiedFilters" @infinite="infiniteHandler" ref="InfiniteLoading" force-use-infinite-wrapper></infinite-loading>

            <div v-if="items.length <= 0 && errorLoading">Error loading items</div>
        </div>
    </div>
</template>

<script>
import * as mock from '@/mock.js';
import api from "../api";
import parseItems from "../utils/parseItems";
import { mapGetters } from "vuex";
import sleep from "await-sleep"
import axios from "axios";

export default {
    props: [ ],
    data() {
        return {
            items: [],
            windowWidth: 0,
            appId: this.$route.meta.appId,
            game: this.$route.meta.game,
            continuationToken: null,
            errorLoading: false,
            loadingMore: false,
            loaderCalled: [],
            isLoading: true,
            currentStringifiedFilters: "",
            cancelSource: null,
        }
    },
    mounted() {
        // this.$store.dispatch('filters/setFilterLoadedStatus', false);
        console.log("Loaded gridview page");
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
        this.onResize();
        console.log("Setup resize listeners");
    },

    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },
    computed: {
        ...mapGetters({
            filters: "filters/filters",
            filtersLoaded: "filters/filtersLoaded",
        }),
        filteredItems() {
            return this.items.filter(item => {
                let seenCommodities = {};

                if (seenCommodities[item.market_hash_name]) {
                    seenCommodities[item.market_hash_name].quantity++;
                    return false;
                }
                
                // is null apaz.
                if (item.type == "COMMODITY") {
                    seenCommodities[item.market_hash_name] = item;
                    item.quantity = 1;
                }

                return true;
            });
        },
        renderableItems() {
            let toRender = this.filteredItems;

            const itemsPerRow = this.windowWidth >= 1400 ? 5 : 4;
            // Do the 'binning' into groups of 4
            return toRender.reduce((col, item, i) => {
                if (i % itemsPerRow === 0)
                    col.push([]); // Add a new row
                
                col[col.length - 1].push(item); // push item to the current row
                return col;
            }, []);
                
        }
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth
        },
        async infiniteHandler($state) {
            console.log("Infinite handler hit! Loading more");
            // TODO: Wait for filter bar to load.

            //if (this.isLoading) return;
            
            // check last 5 results to be in the same second
            // This seems to be constantly triggering for me, disabling for now
            if (false && this.loaderCalled.length >= 4) {
                if ((this.loaderCalled[3] - 1000) >= this.loaderCalled[0]){
                    console.error("Loading too quickly.");
                    await sleep(2000);
                    this.isLoading = false;
                    return $state.complete();
                }
            }

            this.isLoading = true;
            this.loaderCalled.push(Date.now());
            console.log("Asking to load more");

            let isMoreItems = await this.loadMore();
            if (isMoreItems)
                $state.loaded();
            else
                $state.complete();

            this.isLoading = false;

            if (this.loaderCalled.length > 5) {this.loaderCalled.shift()}; // Cap array at 5 entries
        },
        async loadMore() {
            // TODO: for some reason the same call is getting executed twice
            console.log("Loading more")
            let appId = parseInt(this.appId);
            let continuationToken = this.continuationToken;
            let maxItems = 20;

            console.log(`Attempting to load more for ${appId}`);

            if (!this.$store.getters['filters/filtersLoaded']) {
                console.log("Filters not loaded, waiting");
                await sleep(500)
                return true;
            }


            let filters = {
                maxItems,
                state: "LISTED",
                excludeOwned: true,
                sortBy: 'bestdeal'
            };

            if (appId && !isNaN(appId)) {
                filters.appId = appId;
            }

            if (appId == 730) {
                filters = {
                    ...filters,
                    tradelocked: {
                        min: 0,
                        max: 7
                    },
                    float: {
                        min: 0,
                        max: 1,
                    }
                }
            }

            if (!appId) {
                filters = { ...filters, tradelocked: null }; // hack fix search double search
            }

            for (let filterKey of Object.keys(this.filters)) {
                if (this.filters[filterKey]) filters[filterKey] = this.filters[filterKey];
            }

            // Try to use the newer continuation token, but fall back to the skip legacy if needed.
            if (continuationToken) {
                console.log("Getting more listings the new way")
                filters.continuationToken = continuationToken
            } else {
                console.log("Getting more listings the old way")
                filters.skip = this.items.length;
            }

            try {
                if (this.cancelSource) {
                    console.log(`Cancelling previous request`);
                    this.cancelSource.cancel();
                }

                let cancelSource = axios.CancelToken.source();
                this.cancelSource = cancelSource;
                let response = await api.getItems(filters, { cancelToken: cancelSource.token });
                this.cancelSource = null; // Request has finished.
                console.log(response);
                if (!response || !response.data) {
                    console.error("Unable to get item data");
                    // this.errorLoading = true;
                    // return false;
                    return true;
                }

                console.log("Adding items");
                this.items = this.items.concat(await parseItems(response.data));
                let seenCache = {};
                this.items = this.items.filter(item => {
                    const seen = seenCache[item.id];
                    seenCache[item.id] = true;
                    return !seen;
                })

                this.continuationToken = response.headers['continuation-token'];

                if (this.continuationToken) return true;
                if (response.data.length == 0) return false;
                return true;
            } catch (e) {
                console.log("Unable to load items for grid view");
                console.log(e);
                this.errorLoading = true;
                return false;
            }
        },
    },

    watch: {
        $route(to, from) {
            this.appId = to.meta.appId;
            this.game = to.meta.game;
            console.log("Resetting")
            if (this.$refs.InfiniteLoading) this.$refs.InfiniteLoading.stateChanger.reset();
        },
        filtersLoaded: {
            handler(newVal) {
                if (!newVal) return; // dont reset if not loaded 
                console.log("Filters are now loaded, resetting")

                this.currentStringifiedFilters = JSON.stringify(this.filters);

                this.items = [];
                this.continuationToken = null;
                if (this.$refs.InfiniteLoading) {
                    this.$refs.InfiniteLoading.stateChanger.reset(); 
                }
            }
        },
        filters: {
            deep: true,
            handler(newValue) {
                // Note: when mutating (rather than replacing) an Object or an Array, the old value will be the same as new value because they reference the same Object/Array. Vue doesn’t keep a copy of the pre-mutate value.
                console.log("Change of filters");
                console.log(`Old value: ${this.currentStringifiedFilters}`);
                console.log(`new value: ${JSON.stringify(newValue)}`);
                
                if (JSON.stringify(newValue) == this.currentStringifiedFilters) return console.log("No need to reset filter boi");
                
                this.currentStringifiedFilters = JSON.stringify(newValue);
                this.items = [];
                this.continuationToken = null;
                if (this.$refs.InfiniteLoading) this.$refs.InfiniteLoading.stateChanger.reset(); 
                // this.loadMore();
            }
        }
    }
}
</script>