console.log(`Items.JS loaded`);
const api = require('../api');
import parseItems from "../utils/parseItems";
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    items: [],
    my_listings: [],
    myListingsContinuation: "",
  },
  getters: {
    byAppId: state => appId => state.items.filter(item => item.appid == appId),
    item: state => itemId => state.items.filter(item => item.id == itemId),
    byName: state => name => state.items.filter(item => item.name+''.toLowerCase() == name+''.toLowerCase()),
    byAppIdName: state => (appId, name) => state.items.filter(item => item.appid == appId && item.name.toLowerCase().indexOf(name.toLowerCase()) > -1),
    all: state => state.items,
    my_listings: state => state.my_listings,
    selectedMyListings: state => state.my_listings.filter(x => x.selected),
  },
  mutations: {
    clearItems(state) {
      Vue.set(state, 'items', []);
    },
    setItems(state, items) {
      //console.log("Updating items");
      state.items = [];
      Vue.set(state, 'items', items);
    },
    setMyListings(state, my_listings) {
      Vue.set(state, 'my_listings', my_listings);
    },
    addMyListings(state, my_listings) {
      Vue.set(state, 'my_listings', state.my_listings.concat(my_listings));
    },
    clearMyListings(state) {
        Vue.set(state, 'my_listings', [])
    },
    setListing(state, listing) {
        const itemIndex = state.my_listings.findIndex(item => item.id === listing.id);
        state.my_listings[itemIndex] = listing;
    },
    setMyListingsContinuationToken(state, token) {
      state.myListingsContinuation = token;
    },
    removeListing(state, listingId) {
        const itemIndex = state.my_listings.findIndex(item => item.id === listingId);
        
        console.log(`removing index ${itemIndex}`)
        state.my_listings.splice(itemIndex, 1);
        Vue.set(state, 'my_listings', state.my_listings );
    }
  },
  actions: {
    async getItems(context, params) {
      //console.log("Getting fresh set of items params: ", params);
      let items = await api.getItems(params);
      // Mapping back-end Item model to front-end model
      items = await parseItems(items.data)
      context.commit('setItems', items)
    },
    reset: (context) => {
      context.commit('clearItems');
      context.commit('clearMyListings');
    },
    async updateMyListings(context) {
        let user = context.rootState.auth.user;
        let response = await api.getItems({ state: "LISTED", userId: user.id})
        let my_listings = response.data;
        let continuation = response.headers['continuation-token'];
        my_listings = await parseItems(my_listings)

        context.commit('setMyListings', my_listings);
        context.commit('setMyListingsContinuationToken', continuation);
        return my_listings
    },
    async loadMoreMyListings(context) {
      // Returns true or false, if there is more or not
      let user = context.rootState.auth.user;
      console.log("Loading more mylistings");
      let filters = { state: "LISTED", userId: user.id };
      if (context.state.myListingsContinuation) {
        filters.continuationToken = context.state.myListingsContinuation 
      } else {
        // there is no continuation token.
        return false;
      }

      let response = await api.getItems(filters);
      let my_listings = response.data;
      let continuation = response.headers['continuation-token'];
      my_listings = await parseItems(my_listings)

      context.commit('addMyListings', my_listings);
      context.commit('setMyListingsContinuationToken', continuation);
      return continuation != null;

    },
    async updateListing(context, params) {
        let user = context.rootState.auth.user;

        let partitionKey = user.id; 
        if (params.partitionKey) 
          partitionKey = params.partitionKey;

        let response = (await api.updateResource(params.resourceId, "item", partitionKey, params.changes))
        let item = response.data.resource;
        context.commit('setListing', item)

        if (response.data.errors.length > 0){
          if (response.data.errors[0].message) {
            if (response.data.errors[0].message == "Old value does not match the current value, concurrency issue? Refresh your resource"){
              throw new Error("Concurrency issues. Refresh page.")
            } else {
              throw new Error(response.data.errors[0].message);
            }
          } else
            throw new Error("Unable to update resource properties");
        }

        return item
    }
  }
}