import store from '../store';

// TODO: Move to API?
export const availableCollections = {
    Any: { name: "Any" },
    "The 2018 Inferno Collection": {
        image: require("@/assets/collections/the2018infernocollection.png"),
        name: "The 2018 Inferno Collection"
    },
    "The 2018 Nuke Collection": {
        image: require("@/assets/collections/the2018nukecollection.png"),
        name: "The 2018 Nuke Collection"
    },
    "The Alpha Collection": {
        image: require("@/assets/collections/thealphacollection.png"),
        name: "The Alpha Collection"
    },
    "The Ancient Collection": {
        image: require("@/assets/collections/theancientcollection.png"),
        name: "The Ancient Collection"
    },
    "The Arms Deal 2 Collection": {
        image: require("@/assets/collections/thearmsdeal2collection.png"),
        name: "The Arms Deal 2 Collection"
    },
    "The Arms Deal 3 Collection": {
        image: require("@/assets/collections/thearmsdeal3collection.png"),
        name: "The Arms Deal 3 Collection"
    },
    "The Arms Deal Collection": {
        image: require("@/assets/collections/thearmsdealcollection.png"),
        name: "The Arms Deal Collection"
    },
    "The Assault Collection": {
        image: require("@/assets/collections/theassaultcollection.png"),
        name: "The Assault Collection"
    },
    "The Aztec Collection": {
        image: require("@/assets/collections/theazteccollection.png"),
        name: "The Aztec Collection"
    },
    "The Baggage Collection": {
        image: require("@/assets/collections/thebaggagecollection.png"),
        name: "The Baggage Collection"
    },
    "The Bank Collection": {
        image: require("@/assets/collections/thebankcollection.png"),
        name: "The Bank Collection"
    },
    "The Blacksite Collection": {
        image: require("@/assets/collections/theblacksitecollection.png"),
        name: "The Blacksite Collection"
    },
    "The Bravo Collection": {
        image: require("@/assets/collections/thebravocollection.png"),
        name: "The Bravo Collection"
    },
    "The Breakout Collection": {
        image: require("@/assets/collections/thebreakoutcollection.png"),
        name: "The Breakout Collection"
    },
    "The CS20 Collection": {
        image: require("@/assets/collections/thecs20collection.png"),
        name: "The CS20 Collection"
    },
    "The Cache Collection": {
        image: require("@/assets/collections/thecachecollection.png"),
        name: "The Cache Collection"
    },
    "The Canals Collection": {
        image: require("@/assets/collections/thecanalscollection.png"),
        name: "The Canals Collection"
    },
    "The Chop Shop Collection": {
        image: require("@/assets/collections/thechopshopcollection.png"),
        name: "The Chop Shop Collection"
    },
    "The Chroma 2 Collection": {
        image: require("@/assets/collections/thechroma2collection.png"),
        name: "The Chroma 2 Collection"
    },
    "The Chroma 3 Collection": {
        image: require("@/assets/collections/thechroma3collection.png"),
        name: "The Chroma 3 Collection"
    },
    "The Chroma Collection": {
        image: require("@/assets/collections/thechromacollection.png"),
        name: "The Chroma Collection"
    },
    "The Clutch Collection": {
        image: require("@/assets/collections/theclutchcollection.png"),
        name: "The Clutch Collection"
    },
    "The Cobblestone Collection": {
        image: require("@/assets/collections/thecobblestonecollection.png"),
        name: "The Cobblestone Collection"
    },
    "The Control Collection": {
        image: require("@/assets/collections/thecontrolcollection.png"),
        name: "The Control Collection"
    },
    "The Danger Zone Collection": {
        image: require("@/assets/collections/thedangerzonecollection.png"),
        name: "The Danger Zone Collection"
    },
    "The Dust 2 Collection": {
        image: require("@/assets/collections/thedust2collection.png"),
        name: "The Dust 2 Collection"
    },
    "The Dust Collection": {
        image: require("@/assets/collections/thedustcollection.png"),
        name: "The Dust Collection"
    },
    "The Falchion Collection": {
        image: require("@/assets/collections/thefalchioncollection.png"),
        name: "The Falchion Collection"
    },
    "The Fracture Collection": {
        image: require("@/assets/collections/thefracturecollection.png"),
        name: "The Fracture Collection"
    },
    "The Gamma 2 Collection": {
        image: require("@/assets/collections/thegamma2collection.png"),
        name: "The Gamma 2 Collection"
    },
    "The Gamma Collection": {
        image: require("@/assets/collections/thegammacollection.png"),
        name: "The Gamma Collection"
    },
    "The Glove Collection": {
        image: require("@/assets/collections/theglovecollection.png"),
        name: "The Glove Collection"
    },
    "The Gods and Monsters Collection": {
        image: require("@/assets/collections/thegodsandmonsterscollection.png"),
        name: "The Gods and Monsters Collection"
    },
    "The Havoc Collection": {
        image: require("@/assets/collections/thehavoccollection.png"),
        name: "The Havoc Collection"
    },
    "The Horizon Collection": {
        image: require("@/assets/collections/thehorizoncollection.png"),
        name: "The Horizon Collection"
    },
    "The Huntsman Collection": {
        image: require("@/assets/collections/thehuntsmancollection.png"),
        name: "The Huntsman Collection"
    },
    "The Inferno Collection": {
        image: require("@/assets/collections/theinfernocollection.png"),
        name: "The Inferno Collection"
    },
    "The Italy Collection": {
        image: require("@/assets/collections/theitalycollection.png"),
        name: "The Italy Collection"
    },
    "The Lake Collection": {
        image: require("@/assets/collections/thelakecollection.png"),
        name: "The Lake Collection"
    },
    "The Militia Collection": {
        image: require("@/assets/collections/themilitiacollection.png"),
        name: "The Militia Collection"
    },
    "The Mirage Collection": {
        image: require("@/assets/collections/themiragecollection.png"),
        name: "The Mirage Collection"
    },
    "The Norse Collection": {
        image: require("@/assets/collections/thenorsecollection.png"),
        name: "The Norse Collection"
    },
    "The Nuke Collection": {
        image: require("@/assets/collections/thenukecollection.png"),
        name: "The Nuke Collection"
    },
    "The Office Collection": {
        image: require("@/assets/collections/theofficecollection.png"),
        name: "The Office Collection"
    },
    "The Operation Broken Fang Collection": {
        image: require("@/assets/collections/theoperationbrokenfangcollection.png"),
        name: "The Operation Broken Fang Collection"
    },
    "The Operation Hydra Collection": {
        image: require("@/assets/collections/theoperationhydracollection.png"),
        name: "The Operation Hydra Collection"
    },
    "The Overpass Collection": {
        image: require("@/assets/collections/theoverpasscollection.png"),
        name: "The Overpass Collection"
    },
    "The Phoenix Collection": {
        image: require("@/assets/collections/thephoenixcollection.png"),
        name: "The Phoenix Collection"
    },
    "The Prisma 2 Collection": {
        image: require("@/assets/collections/theprisma2collection.png"),
        name: "The Prisma 2 Collection"
    },
    "The Prisma Collection": {
        image: require("@/assets/collections/theprismacollection.png"),
        name: "The Prisma Collection"
    },
    "The Revolver Collection": {
        image: require("@/assets/collections/therevolvercollection.png"),
        name: "The Revolver Collection"
    },
    "The Rising Sun Collection": {
        image: require("@/assets/collections/therisingsuncollection.png"),
        name: "The Rising Sun Collection"
    },
    "The Safehouse Collection": {
        image: require("@/assets/collections/thesafehousecollection.png"),
        name: "The Safehouse Collection"
    },
    "The Shadow Collection": {
        image: require("@/assets/collections/theshadowcollection.png"),
        name: "The Shadow Collection"
    },
    "The Shattered Web Collection": {
        image: require("@/assets/collections/theshatteredwebcollection.png"),
        name: "The Shattered Web Collection"
    },
    "The Spectrum 2 Collection": {
        image: require("@/assets/collections/thespectrum2collection.png"),
        name: "The Spectrum 2 Collection"
    },
    "The Spectrum Collection": {
        image: require("@/assets/collections/thespectrumcollection.png"),
        name: "The Spectrum Collection"
    },
    "The St. Marc Collection": {
        image: require("@/assets/collections/thest.marccollection.png"),
        name: "The St. Marc Collection"
    },
    "The Train Collection": {
        image: require("@/assets/collections/thetraincollection.png"),
        name: "The Train Collection"
    },
    "The Vanguard Collection": {
        image: require("@/assets/collections/thevanguardcollection.png"),
        name: "The Vanguard Collection"
    },
    "The Vertigo Collection": {
        image: require("@/assets/collections/thevertigocollection.png"),
        name: "The Vertigo Collection"
    },
    "The Wildfire Collection": {
        image: require("@/assets/collections/thewildfirecollection.png"),
        name: "The Wildfire Collection"
    },
    "The Winter Offensive Collection": {
        image: require("@/assets/collections/thewinteroffensivecollection.png"),
        name: "The Winter Offensive Collection"
    },
    "The X-Ray Collection": {
        image: require("@/assets/collections/thex-raycollection.png"),
        name: "The X-Ray Collection"
    },
    "The eSports 2013 Collection": {
        image: require("@/assets/collections/theesports2013collection.png"),
        name: "The eSports 2013 Collection"
    },
    "The eSports 2013 Winter Collection": {
        image: require("@/assets/collections/theesports2013wintercollection.png"),
        name: "The eSports 2013 Winter Collection"
    },
    "The eSports 2014 Summer Collection": {
        image: require("@/assets/collections/theesports2014summercollection.png"),
        name: "The eSports 2014 Summer Collection"
    },
    "The 2021 Train Collection": {
        image: require("@/assets/collections/the_2021_train_collection.png"),
        name: "The 2021 Train Collection"
    },
    "The 2021 Mirage Collection": {
        image: require("@/assets/collections/the_2021_mirage_collection.png"),
        name: "The 2021 Mirage Collection"
    },
    "The 2021 Dust 2 Collection": {
        image: require("@/assets/collections/the_2021_dust_2_collection.png"),
        name: "The 2021 Train Collection"
    },
    "The 2021 Vertigo Collection": {
        image: require("@/assets/collections/the_2021_vertigo_collection.png"),
        name: "The 2021 Vertigo Collection"
    }
};

const tf2StrangePartNames = [
    "Robots Destroyed",
    "Strange Cosmetic Part: Kills",
    "Airborne Enemies Killed",
    "Damage Dealt",
    "Domination Kills",
    "Snipers Killed",
    "Buildings Destroyed",
    "Projectiles Reflected",
    "Headshot Kills",
    "Medics Killed",
    "Strange Cosmetic Part: Fires Survived",
    "Teammates Extinguished",
    "Strange Cosmetic Part: Freezecam Taunt Appearances",
    "Spies Killed",
    "Allied Healing Done",
    "Sappers Destroyed",
    "Player Hits",
    "Gib Kills",
    "Scouts Killed",
    "Kills with a Taunt Attack",
    "Long-Distance Kills",
    "Giant Robots Destroyed",
    "Critical Kills",
    "Demomen Killed",
    "Point-Blank Kills",
    "Strange Cosmetic Part: Assists",
    "Medics Killed That Have Full ÜberCharge",
    "Cloaked Spies Killed",
    "Soldiers Killed",
    "Kills While Explosive Jumping",
    "Low-Health Kills",
    "Burning Enemy Kills",
    "Unusual-Wearing Player Kills",
    "Kills While Übercharged",
    "[NC-SF] Mann Manor (Community)",
    "Engineers Killed",
    "Not Crit nor MiniCrit Kills",
    "Full Health Kills",
    "Killstreaks Ended",
    "Defender Kills",
    "Posthumous Kills",
    "Revenge Kills",
    "Robot Scouts Destroyed",
    "[NC-SF] Yukon (Community)",
    "Heavies Killed",
    "Tanks Destroyed",
    "Halloween Kills",
    "Pyros Killed",
    "Kills During Victory Time",
    "[NC-SF] Borneo (Community)",
    "[NC-SF] Kong King (Community)",
    "[NC-SF] Hoodoo (Community)",
    "[NC-SF] Suijin (Community)",
    "Robot Spies Destroyed",
    "[NC-SF] Fastlane (Community)",
    "Taunting Player Kills",
    "Full Moon Kills",
    "Underwater Kills",
    "[NC-SF] Turbine (Community)",
    "[NC-SF] Lakeside (Community)",
    "Robots Destroyed During Halloween",
    "[NC-SF] Frontier (Community)",
    "[NC-SF] Snakewater (Community)",
    "[NC-SF] Steel (Community)",
    "[NC-SF] Harvest (Community)",
    "[NC-SF] Coldfront (Community)",
    "[NC-SF] 2Fort Invasion (Community)",
    "[NC-SF] Egypt (Community)",
    "[NC-SF] Moonshine Event (Community)",
    "[NC-SF] Snowplow (Community)",
    "[NC-SF] Process (Community)",
    "[NC-SF] Watchtower (Community)",
    "[NC-SF] Watergate (Community)",
    "[NC-SF] Gullywash (Community)",
    "[NC-SF] Sunshine (Community)",
    "[NC-SF] Snowycoast (Community)",
    "[NC-SF] Byre (Community)",
    "[NC-SF] Offblast (Community)",
    "[NC-SF] Standin (Community)",
    "[NC-SF] Freight (Community)",
    "[NC-SF] Vanguard (Community)",
    "[NC-SF] Hellstone (Community)",
    "[NC-SF] Pit of Death (Community)",
    "[NC-SF] Competitive",
    "[NC-SF] Highpass (Community)",
    "[NC-SF] Junction (Community)",
    "[NC-SF] Landfall (Community)",
    "[NC-SF] Maple Ridge Event (Community)",
    "[NC-SF] Mountain Lab (Community)",
    "[NC-SF] Nightfall (Community)",
    "[NC-SF] Gorge Event (Community)"
];

const tf2HalloweenSpellNames = [
    // Footprint effects
    "Halloween: Team Spirit Footprints",
    "Halloween: Gangreen Footprints",
    "Halloween: Corpse Gray Footprints",
    "Halloween: Violent Violet Footprints",
    "Halloween: Rotten Orange Footprints",
    "Halloween: Bruised Purple Footprints",
    "Halloween: Headless Horseshoes",
    // Paint effects
    "Halloween: Die Job",
    "Halloween: Spectral Spectrum",
    "Halloween: Putrescent Pigmentation",
    "Halloween: Sinister Staining",
    "Halloween: Chromatic Corruption",
    // Vocal effects
    // "Halloween: Scout's Spectral Snarl",
    // "Halloween: Soldier's Booming Bark",
    // "Halloween: Pyro's Muffled Moan",
    // "Halloween: Demoman's Cadaverous Croak",
    // "Halloween: Heavy's Bottomless Bass",
    // "Halloween: Engineers's Gravelly Growl",
    // "Halloween: Medic's Blood-curdling Bellow",
    // "Halloween: Sniper's Deep Downunder Drawl",
    // "Halloween: Spy's Creepy Croon",
    // Vocal applied spells get renamed to this
    "Halloween: Voices From Below", // ugh
    // Weapon effects
    "Halloween: Exorcism",
    // Weapon spells that get renamed when attached to class weapons
    "Halloween: Halloween Fire", // Spectral Flame (pyro flamethrower)
    "Halloween: Pumpkin Bombs" // Squash Rockets (soldier rocket launcher)
    //"Halloween: Pumpkin Bombs", // Sentry Quad-Pumpkins (engineer melee)
    //"Halloween: Pumpkin Bombs", // Gourd Grenades (demo explosive weapons)
];

const tf2HalloweenSpells = [
    {
        text: "Effect: Voices From Below",
        value: "Halloween: Voices From Below",
        image: require("@/assets/tf2/halloween/effect_voices_from_below.png")
    },
    {
        text: "Footprints: Team Spirit",
        value: "Halloween: Team Spirit Footprints",
        image: require("@/assets/tf2/halloween/footprint_team_spirit.png")
    },
    {
        text: "Footprints: Gangreen",
        value: "Halloween: Gangreen Footprints",
        image: require("@/assets/tf2/halloween/footprint_gangreen.png")
    },
    {
        text: "Footprints: Corpse Gray",
        value: "Halloween: Corpse Gray Footprints",
        image: require("@/assets/tf2/halloween/footprint_corpse_gray.png")
    },
    {
        text: "Footprints: Violent Violet",
        value: "Halloween: Violent Violet Footprints",
        image: require("@/assets/tf2/halloween/footprint_violent_violet.png")
    },
    {
        text: "Footprints: Rotten Orange",
        value: "Halloween: Rotten Orange Footprints",
        image: require("@/assets/tf2/halloween/footprint_rotten_orange.png")
    },
    {
        text: "Footprints: Bruised Purple",
        value: "Halloween: Bruised Purple Footprints",
        image: require("@/assets/tf2/halloween/footprint_bruised_purple.png")
    },
    {
        text: "Footprints: Headless Horseshoes",
        value: "Halloween: Headless Horseshoes",
        image: require("@/assets/tf2/halloween/footprint_headless_horseshoes.png")
    },
    {
        text: "Paint: Die Job",
        value: "Halloween: Die Job",
        image: require("@/assets/tf2/halloween/paint_die_job.png")
    },
    {
        text: "Paint: Spectral Spectrum",
        value: "Halloween: Spectral Spectrum",
        image: require("@/assets/tf2/halloween/paint_spectral_spectrum.png")
    },
    {
        text: "Paint: Putrescent Pigmentation",
        value: "Halloween: Putrescent Pigmentation",
        image: require("@/assets/tf2/halloween/paint_putrescent_pigmentation.png")
    },
        {
        text: "Paint: Sinister Staining",
        value: "Halloween: Sinister Staining",
        image: require("@/assets/tf2/halloween/paint_sinister_staining.png")
    },
    {
        text: "Paint: Chromatic Corruption",
        value: "Halloween: Chromatic Corruption",
        image: require("@/assets/tf2/halloween/paint_chromatic_corruption.png")
    },
    {
        text: "Effect: Exorcism",
        value: "Halloween: Exorcism",
        image: require("@/assets/tf2/halloween/effect_exorcism.png")
    },
    {
        text: "Effect: Halloween Fire",
        value: "Halloween: Halloween Fire",
        image: require("@/assets/tf2/halloween/effect_halloween_fire.png")
    },
    {
        text: "Effect: Pumpkin Bombs",
        value: "Halloween: Pumpkin Bombs",
        image: require("@/assets/tf2/halloween/effect_pumpkin_bombs.png")
    }
];

const tf2StrangeParts = [
    {
        value: "Robots Destroyed",
        text: "Robots Destroyed",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_mvm_robot_kills.148e9992636fb410c0824f560f44811f7f7ee9e7.png"
    },
    {
        value: "Strange Cosmetic Part: Kills",
        text: "Strange Cosmetic Part: Kills",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_cosmetic_part_kills.16438424758c2ef452a78d9b886940884d1603c3.png"
    },
    {
        value: "Airborne Enemies Killed",
        text: "Airborne Enemies Killed",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_airborne_killed.e031d44846553dbe90b541ee15cb0c1696066c2b.png"
    },
    {
        value: "Damage Dealt",
        text: "Damage Dealt",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_damage_dealt.54d3ff8e5e70497ec6348c6fd332c1777429aabc.png"
    },
    {
        value: "Domination Kills",
        text: "Domination Kills",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_killstartdomination.3e24971eebd1bb5c4bf2e7c786ca89c1def99824.png"
    },
    {
        value: "Snipers Killed",
        text: "Snipers Killed",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_snipers_killed.06fc155fc5b6aba320c46a39896a7054b0394b91.png"
    },
    {
        value: "Buildings Destroyed",
        text: "Buildings Destroyed",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_buildings_destroyed.31388803c446949aeffd18d890036bbccd63b26e.png"
    },
    {
        value: "Projectiles Reflected",
        text: "Projectiles Reflected",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_projectiles_deflected.3a23715038e817769bbdfa5cde0f623335a3928e.png"
    },
    {
        value: "Headshot Kills",
        text: "Headshot Kills",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_headshots.09385a704fbdf75b7cdf36915d2efad9841bfb0c.png"
    },
    {
        value: "Medics Killed",
        text: "Medics Killed",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_medics_killed.f7cdfc140a52c09e4cd8879732f32dd5e12c068f.png"
    },
    {
        value: "Strange Cosmetic Part: Fires Survived",
        text: "Strange Cosmetic Part: Fires Survived",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_fires_survived.35b8784d2b1f42cd9017d826f451b8a3682c2105.png"
    },
    {
        value: "Teammates Extinguished",
        text: "Teammates Extinguished",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_burningallyextinguished.d183a1aad6ed5fd5ca3439ccccd117cd062a549b.png"
    },
    {
        value: "Strange Cosmetic Part: Freezecam Taunt Appearances",
        text: "Strange Cosmetic Part: Freezecam Taunt Appearances",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_killcam_taunts.fa3fc657bd2b52c765666b303e427a798f358a64.png"
    },
    {
        value: "Spies Killed",
        text: "Spies Killed",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_spies_killed.11b5f2b91ea0719737a62cf845d36e02e28cf92d.png"
    },
    {
        value: "Allied Healing Done",
        text: "Allied Healing Done",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_healing_done.dc698c2255506fe8a1728660fb0d948abbeb0dff.png"
    },
    {
        value: "Sappers Destroyed",
        text: "Sappers Destroyed",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_sappers_killed.3912de671dc3702f9bd038da059fbdc7238a7cc9.png"
    },
    {
        value: "Player Hits",
        text: "Player Hits",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_players_hit.d1a70fc4fd0d5408cfd356d9f5e961208431c36f.png"
    },
    {
        value: "Gib Kills",
        text: "Gib Kills",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_enemies_gibbed.b39034d74815d845e3fff8b8cbb63a59017f632b.png"
    },
    {
        value: "Scouts Killed",
        text: "Scouts Killed",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_scouts_killed.7d51fdd93587cdfd68e7bb8704219a7dd0621c89.png"
    },
    {
        value: "Kills with a Taunt Attack",
        text: "Kills with a Taunt Attack",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_taunt_kills.58a1de56b66d1b59b675d1721a545daf66e6fd53.png"
    },
    {
        value: "Long-Distance Kills",
        text: "Long-Distance Kills",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_long_distance_kills.d444fdda39d96ef74484d42442eafc8ab423ab86.png"
    },
    {
        value: "Giant Robots Destroyed",
        text: "Giant Robots Destroyed",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_giant_kills.9080741543e43b70656f12ca7a25c13fa78e5a2f.png"
    },
    {
        value: "Critical Kills",
        text: "Critical Kills",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_killcritical.23568bbd2b360be837050fa7a58c91c993668107.png"
    },
    {
        value: "Demomen Killed",
        text: "Demomen Killed",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_demos_killed.1d7f2ae1775c54fa443450be3f80c3b95116bf95.png"
    },
    {
        value: "Point-Blank Kills",
        text: "Point-Blank Kills",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_point_blank.b104c00e46bdc4d17c42c22b44e4369a1815a292.png"
    },
    {
        value: "Strange Cosmetic Part: Assists",
        text: "Strange Cosmetic Part: Assists",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_assists.1dbc6dea788436c1ead29319690ac082344156b3.png"
    },
    {
        value: "Medics Killed That Have Full ÜberCharge",
        text: "Medics Killed That Have Full ÜberCharge",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_ubers_dropped.f1ba8186261c6af5de63c4132462a71d1b171fc0.png"
    },
    {
        value: "Cloaked Spies Killed",
        text: "Cloaked Spies Killed",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_invisibles_killed.3379ec0f5ffa26b6035da835f819a225b3aef176.png"
    },
    {
        value: "Soldiers Killed",
        text: "Soldiers Killed",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_soldiers_killed.486d03d4cb67d1acd31468591d29476eff4f0814.png"
    },
    {
        value: "Kills While Explosive Jumping",
        text: "Kills While Explosive Jumping",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_killwhileexplosivejumping.22d4cbe69634454dd7c065994316f312ac8c5baa.png"
    },
    {
        value: "Low-Health Kills",
        text: "Low-Health Kills",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_10_percent_kills.93e3d9da305017099548ec33a63ee8a456f06c3b.png"
    },
    {
        value: "Burning Enemy Kills",
        text: "Burning Enemy Kills",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_burning_enemies_killed.686017de7f8563a784910f68f05fde8c07ebe91b.png"
    },
    {
        value: "Unusual-Wearing Player Kills",
        text: "Unusual-Wearing Player Kills",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_unusuals_killed.178c3fe5b2fc135dd8e03e2a0247bbb999a67d6b.png"
    },
    {
        value: "Kills While Übercharged",
        text: "Kills While Übercharged",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_kill_while_uber.3575bebe04fa7226e748397fa76faa0d2704cbc9.png"
    },
    {
        value: "[NC-SF] Mann Manor (Community)",
        text: "[NC-SF] Mann Manor (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_manor.ced31a890ed338cdd18a6e3b91dcc678c731e551.png"
    },
    {
        value: "Engineers Killed",
        text: "Engineers Killed",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_engineers_killed.0f6b1cf2e269a48df47f32349120563957046287.png"
    },
    {
        value: "Not Crit nor MiniCrit Kills",
        text: "Not Crit nor MiniCrit Kills",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_no_crit.b57e95f04d63eede2f752480cf62cc360694693a.png"
    },
    {
        value: "Full Health Kills",
        text: "Full Health Kills",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_full_health_kills.88bcf81227bdc849b7cd1935a9fadf418a97653e.png"
    },
    {
        value: "Killstreaks Ended",
        text: "Killstreaks Ended",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_killstreaks_ended.65a6255dba7c19ae6a195cef8864e15c4a7003de.png"
    },
    {
        value: "Defender Kills",
        text: "Defender Kills",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_defender_kill.b448234de1f01a85c49f8ece7b64ef48cf0486f4.png"
    },
    {
        value: "Posthumous Kills",
        text: "Posthumous Kills",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_playerkillposthumous.44985b516302f0c274f8ad2df71227a5fbae82c2.png"
    },
    {
        value: "Revenge Kills",
        text: "Revenge Kills",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_killrevenge.2eee849487227daea338f12b1ecb158a3e5ba44d.png"
    },
    {
        value: "Robot Scouts Destroyed",
        text: "Robot Scouts Destroyed",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_robot_scouts_killed.31dccbbd8ba75f13057d788e16d9dcf5a3e91200.png"
    },
    {
        value: "[NC-SF] Yukon (Community)",
        text: "[NC-SF] Yukon (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_yukon.e1fe307f1b682fdf16073f46fe49623c344465c5.png"
    },
    {
        value: "Heavies Killed",
        text: "Heavies Killed",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_heavies_killed.4df3c02b07e4159218dad8a55257bdc526956663.png"
    },
    {
        value: "Tanks Destroyed",
        text: "Tanks Destroyed",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_tank_destroyed.d0a4c0184bbf0a41ecfd1a5203f45e700a80e868.png"
    },
    {
        value: "Halloween Kills",
        text: "Halloween Kills",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_halloween_kills.ec1fdb53db8c53afa1dc499315d0007e8517c83f.png"
    },
    {
        value: "Pyros Killed",
        text: "Pyros Killed",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_pyros_killed.4e3f15f1716f53a8fa6ce4689834e8c62a87db38.png"
    },
    {
        value: "Kills During Victory Time",
        text: "Kills During Victory Time",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_humiliation_kills.347d43b6919398733256fbcbfb20faf7f9c403b9.png"
    },
    {
        value: "[NC-SF] Borneo (Community)",
        text: "[NC-SF] Borneo (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_borneo.034b4173d020c71c75588e595488e2d6d8dedd76.png"
    },
    {
        value: "[NC-SF] Kong King (Community)",
        text: "[NC-SF] Kong King (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_kongking.891f9225c1f0bee9523df87f58d288b3e18df185.png"
    },
    {
        value: "[NC-SF] Hoodoo (Community)",
        text: "[NC-SF] Hoodoo (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_hoodoo.49caaf8b072dcf20d4f2047c8364eedce35256ac.png"
    },
    {
        value: "[NC-SF] Suijin (Community)",
        text: "[NC-SF] Suijin (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_suijin.4fca5d8262626ce6cf2e64a440512ff6d8ff3307.png"
    },
    {
        value: "Robot Spies Destroyed",
        text: "Robot Spies Destroyed",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_robospys_killed.6af482ec39203f0a1c03fb495938f7273cdbaca1.png"
    },
    {
        value: "[NC-SF] Fastlane (Community)",
        text: "[NC-SF] Fastlane (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_fastlane.936665341e0ae8848a411674643bc05cc7e3dae1.png"
    },
    {
        value: "Taunting Player Kills",
        text: "Taunting Player Kills",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_conga_killer.adfaa4430c6816fae5318d946e6db1aadce0fdc0.png"
    },
    {
        value: "Full Moon Kills",
        text: "Full Moon Kills",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_killduringfullmoon.e763e7993a4461277f377fc1dbc6d3399f6538e2.png"
    },
    {
        value: "Underwater Kills",
        text: "Underwater Kills",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_underwater_kill.d8c6f9afc979bf25d79d1d5feebe024d52215e03.png"
    },
    {
        value: "[NC-SF] Turbine (Community)",
        text: "[NC-SF] Turbine (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_turbine.2baeb4ae50ec6d38c5d6e27f246dafec8278e3b4.png"
    },
    {
        value: "[NC-SF] Lakeside (Community)",
        text: "[NC-SF] Lakeside (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_lakeside.b8f466c47d912fcdd9b160bf8f439eebef2fde0f.png"
    },
    {
        value: "Robots Destroyed During Halloween",
        text: "Robots Destroyed During Halloween",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_mvm_robot_kills_halloween.8f59fe767ed98d02be088df1c5bc8cec5894f5b4.png"
    },
    {
        value: "[NC-SF] Frontier (Community)",
        text: "[NC-SF] Frontier (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_frontier.59723a4687d442845d134bd9aadc966ebd53c247.png"
    },
    {
        value: "[NC-SF] Snakewater (Community)",
        text: "[NC-SF] Snakewater (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_snakewater.c0cf4d94ae8a6dc857746494891997003637c99c.png"
    },
    {
        value: "[NC-SF] Steel (Community)",
        text: "[NC-SF] Steel (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_steel.9f6611419523bb74c4715c02f039afbfb5b89fd8.png"
    },
    {
        value: "[NC-SF] Harvest (Community)",
        text: "[NC-SF] Harvest (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_harvest.c30ee8c453cc0ea6989f14a8f2dadd97be0eccdd.png"
    },
    {
        value: "[NC-SF] Coldfront (Community)",
        text: "[NC-SF] Coldfront (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_coldfront.f57858bd3be14032d7f04224f49306d9b4de771e.png"
    },
    {
        value: "[NC-SF] 2Fort Invasion (Community)",
        text: "[NC-SF] 2Fort Invasion (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_2fort_inv.a378b597b984db4d1736f9d121cf32ca571d38aa.png"
    },
    {
        value: "[NC-SF] Egypt (Community)",
        text: "[NC-SF] Egypt (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_egypt.73f44f242ea2524db2d904e3b06d5f82ea02e165.png"
    },
    {
        value: "[NC-SF] Moonshine Event (Community)",
        text: "[NC-SF] Moonshine Event (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_moonshine_event.f305a0465847fc2bba86deaebc1f675e8323881e.png"
    },
    {
        value: "[NC-SF] Snowplow (Community)",
        text: "[NC-SF] Snowplow (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_snowplow.c036807d8e0cb024a61053cc44f74724a257dd32.png"
    },
    {
        value: "[NC-SF] Process (Community)",
        text: "[NC-SF] Process (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_process.4032b55598acc457bf1d3dafc0d1e1ae6a14a6bf.png"
    },
    {
        value: "[NC-SF] Watchtower (Community)",
        text: "[NC-SF] Watchtower (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_watchtower.ac30dc978fafb293ddf79cf734aaf949b458f381.png"
    },
    {
        value: "[NC-SF] Watergate (Community)",
        text: "[NC-SF] Watergate (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_watergate.06bde1497d3ca9d3ae437cf6e1e14a7d9ca918da.png"
    },
    {
        value: "[NC-SF] Gullywash (Community)",
        text: "[NC-SF] Gullywash (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_gullywash.469f7c492f1e618dd651e89267a062655c58addf.png"
    },
    {
        value: "[NC-SF] Sunshine (Community)",
        text: "[NC-SF] Sunshine (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_sunshine.c50ddb400226bd24afeb558f1f2533873d24559d.png"
    },
    {
        value: "[NC-SF] Snowycoast (Community)",
        text: "[NC-SF] Snowycoast (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_snowycoast.18f1e9ecc727e599a41f079a22cdb90775c3dcff.png"
    },
    {
        value: "[NC-SF] Byre (Community)",
        text: "[NC-SF] Byre (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_byre.241f0cc7a201fa64c603b39b1f874121fc37c96c.png"
    },
    {
        value: "[NC-SF] Offblast (Community)",
        text: "[NC-SF] Offblast (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_offblast.3e5fb0ed728f6df3ac0f1b9d0276b919a1f29e68.png"
    },
    {
        value: "[NC-SF] Standin (Community)",
        text: "[NC-SF] Standin (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_standin.90c0c8b8438dc087b773c3e223ff59a75c4819c2.png"
    },
    {
        value: "[NC-SF] Freight (Community)",
        text: "[NC-SF] Freight (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_freight.fd601ea6e7ccec83d9a584f2c309fe0ebc84608f.png"
    },
    {
        value: "[NC-SF] Vanguard (Community)",
        text: "[NC-SF] Vanguard (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_vanguard.6e43ca60778ef348e091728d9029b664cbeaf4fc.png"
    },
    {
        value: "[NC-SF] Hellstone (Community)",
        text: "[NC-SF] Hellstone (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_millstone_event.c2e07c3390a7b61fd9e8f32c8e5e0a201a46e93a.png"
    },
    {
        value: "[NC-SF] Pit of Death (Community)",
        text: "[NC-SF] Pit of Death (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_pit_of_death.989b35017aeac439a9db4d6bb2aa31f0aa727b93.png"
    },
    {
        value: "[NC-SF] Competitive",
        text: "[NC-SF] Competitive",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_competitive.cafe3b7f942219d4192e56568fba43cc6d3b9210.png"
    },
    {
        value: "[NC-SF] Highpass (Community)",
        text: "[NC-SF] Highpass (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_highpass.7913cf694573988c277ae7ab144840944a0ccaa3.png"
    },
    {
        value: "[NC-SF] Junction (Community)",
        text: "[NC-SF] Junction (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_junction.b45b55482dca242461b24d436d25c70bd0f034c9.png"
    },
    {
        value: "[NC-SF] Landfall (Community)",
        text: "[NC-SF] Landfall (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_landfall.c92f4a69aa95dd51648076f20d07a7bcdfa73716.png"
    },
    {
        value: "[NC-SF] Maple Ridge Event (Community)",
        text: "[NC-SF] Maple Ridge Event (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_maple_ridge_event.350c3b6bcb6a675f677aad2795e57986ffeb9f3b.png"
    },
    {
        value: "[NC-SF] Mountain Lab (Community)",
        text: "[NC-SF] Mountain Lab (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_mountainlab.9d0b0ce8a3596b1ad5d658d0b8c1a13e36397171.png"
    },
    {
        value: "[NC-SF] Nightfall (Community)",
        text: "[NC-SF] Nightfall (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_nightfall.965fa39efd160f727edc2de6618c4334704d02a6.png"
    },
    {
        value: "[NC-SF] Gorge Event (Community)",
        text: "[NC-SF] Gorge Event (Community)",
        image:
            "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_gorge_event.f5df4111dc675a62d81f72badd4079bb003a6728.png"
    }
];
// TODO: Move to API?
// Extractor code:
// let results = [];$('.result-box').each(function() {
//     let div = $(this);
//     let stickerName = div.find('h3 a').text();
//     let stickerImg  = div.find('.img-responsive').attr('src')
//     console.log(stickerName, stickerImg)
//     results.push({ text: stickerName, value: stickerName, image: stickerImg })
// });
// JSON.stringify(results).replace('[', '').replace(']', '')
import stickers from "./stickers.json";
console.log("Got stickers");
export const availableStickers = stickers.map(sticker => {
    return {
        text: (sticker.tournament ? `${sticker.tournament}: ` : '') + sticker.name,
        value: sticker.name + (sticker.tournament ? `| ${sticker.tournament}` : ''),
        image: sticker.image,
        tournament: sticker.tournament
    }
}).sort((a, b) => {
    if (a.tournament > b.tournament) return 1;
    if (a.tournament < b.tournament) return -1;

    if (a.tournament == b.tournament) {
        if (a.text > b.text) return 1;
        if (a.text < b.text) return -1;
    }

    return 0;
});

const defaultCsgoFloatRanges = {
    BS: [1.0, 0.44],
    WW: [0.44, 0.37],
    FT: [0.37, 0.15],
    MW: [0.15, 0.07],
    FN: [0.07, 0.0]
};

const asiimovFloatRanges = {
    BS: [1.0, 0.44],
    WW: [0.44, 0.37],
    FT: [0.37, 0.18],
    MW: [],
    FN: []
};

const getItemType = function(market_hash_name, appid) {
    if (appid == 252490) return "COMMODITY";
    const commodities = store.getters['commodities/commodities'];
    if (commodities.indexOf(market_hash_name) > -1) return "COMMODITY";
    return "STANDARD";
}

// This needs re-doing into a parseSiteItems and parseSteamItems
export async function parseItems(items) {
    let data = [];
    for (let sourceItem of items) {
        let steam = sourceItem.steamItem || sourceItem;
        let item = {
            id: sourceItem.id,
            userId: sourceItem.userId,
            depositerId: sourceItem.depositerId,
            type: getItemType(sourceItem.market_hash_name, steam.appid || sourceItem.appid), // sourceItem.type, // Stupid we mean commodity or not but some items have this set!?!

            appid: steam.appid || sourceItem.appid,
            appId: steam.appid || sourceItem.appid,
            market_hash_name: steam.market_hash_name,
            assetId: steam.assetid || sourceItem.assetid,
            contextId: getContextId(steam.appid || sourceItem.appid),
            name: parseName(steam.name),
            listPrice: sourceItem.listPrice,
            marketPrice: sourceItem.marketPrice,
            fraudWarnings: steam.fraudwarnings,
            renamed: steam.fraudwarnings ? steam.fraudwarnings.findIndex(x => x.indexOf('This item has been renamed.') > -1 || x.indexOf('Name Tag: ') > -1) > -1 : false,
            currency: parseCurrency(sourceItem.currency),
            image: steam.icon_url,
            tradable: steam.tradable, // TODO: Implement proper tradable check (including market_tradable_restriction)
            location: sourceItem.location,
            quantity: sourceItem.quantity || 1,
            disabled: sourceItem.disabled || false,
            developer: sourceItem.developer || false,
            lockedUntil: sourceItem.lockedUntil || false,
            state: sourceItem.state,
            previousListPrice: sourceItem.listPrice,
            selected: false
        };

        if (!item.lockedUntil) {
            // console.log("Found false locked ");
        }

        // Inspect Link
        if (steam.actions) {
            item.inspectLink = parseInspectLink(steam.actions);
        }

        // Game specific properties
        if (item.appid === 730) {
            // CSGO & TF2
            // TODO: Update to cover TF2 items as well - pending completion of the TF2 item inspect endpoint
            if (sourceItem.inspected) {
                if (!sourceItem.inspected.float) {
                    // If it is not parsed correctly yet
                    sourceItem.inspected = parseInspected(sourceItem.inspected);
                }
                item.float = sourceItem.inspected.float;
                item.patternIndex = sourceItem.inspected.patternIndex || sourceItem.inspected.paintindex;
                item.floatRanges = getFloatRangesFromMinMaxWear(sourceItem.minWear || 0, sourceItem.maxWear || 1);
            }
        }

        if (item.appid === 730) {
            // CSGO
            try {
                item.rarity = parseRarity(steam.tags);
            } catch (e) {}
            try {
                item.stattrak = parseStattrak(steam.name);
            } catch (e) {}
            try {
                item.stickers = parseStickers(
                    steam.descriptions[steam.descriptions.length - 1].value,
                    sourceItem.inspected
                );
            } catch (e) {}
            try {
                item.collection = parseCollection(steam.tags);
            } catch (e) {}
            if (sourceItem.inspected) {
                item.phase = sourceItem.inspected.phase;
                item.paintIndex = sourceItem.inspected.paintIndex;
            }
        }

        if (item.appid === 440) {
            // TF2
            item.rarity = {};
            for (let tag of steam.tags) {
                if (tag.category === "Quality") {
                    item.rarity.name = tag.name || tag.localized_tag_name;
                }
            }
            item.rarity.color = steam.name_color;
            item.class = parseTf2Class(steam.tags);
            // Parsing Strange/Halloween/Killstreaker parts
            item.stickers = parseTf2Parts(steam.descriptions, steam.tags, item);

            // Removing certain keywords from name to make it easier to read
            item.name = filterName(steam.market_name);
            item.level = parseTf2Level(steam.type);
            item.craftable = parseTf2Craftable(steam.descriptions);
        }

        if (item.appid === 252490) {
            // Rust
            for (let tag of steam.tags) {
                if (tag.category === "itemclass") {
                    item.type = tag.localized_tag_name || tag.name;
                } else if (tag.category === "steamcat") {
                    item.rarity = {
                        name: tag.localized_tag_name || tag.name,
                        color: steam.name_color
                    };
                }
            }
        }

        if (item.appid === 433850) {
            // Z1BR
            item.type = steam.tags[0].localized_tag_name || steam.tags[0].internal_name;
            item.rarity = {
                name: steam.type.split(" ")[0],
                color: steam.name_color
            };
        }

        if (item.appid == 440 || item.appid == 730 || item.appid == 570) {
            // CSGO / TF2 / Dota 2
            item.wear = steam.wear || parseWear(steam.tags);
        }

        if (item.appid == 570) {
            // Dota 2
            item.type = parseDotaType(steam.tags);
            item.rarity = parseRarity(steam.tags);
            let gem = null;
            const gems = [
                {
                    value: "Ascendant Gem",
                    text: "Ascendant Gem",
                    image:
                        "https://static.wikia.nocookie.net/dota2_gamepedia/images/3/3a/Cosmetic_icon_Ascendant_Gem.png"
                },
                {
                    value: "Autograph Rune",
                    text: "Autograph Rune",
                    image:
                        "https://static.wikia.nocookie.net/dota2_gamepedia/images/7/7f/Cosmetic_icon_Autograph_Rune.png"
                },
                {
                    value: "Ethereal Gem",
                    text: "Ethereal Gem",
                    image:
                        "https://static.wikia.nocookie.net/dota2_gamepedia/images/5/5f/Cosmetic_icon_Ethereal_Gem.png"
                },
                {
                    value: "Inscribed Gem",
                    text: "Inscribed Gem",
                    image:
                        "https://static.wikia.nocookie.net/dota2_gamepedia/images/a/a7/Cosmetic_icon_Inscribed_Gem.png"
                },
                {
                    value: "Kinetic Gem",
                    text: "Kinetic Gem",
                    image: "https://static.wikia.nocookie.net/dota2_gamepedia/images/9/9a/Cosmetic_icon_Kinetic_Gem.png"
                },
                {
                    value: "Prismatic Gem",
                    text: "Prismatic Gem",
                    image:
                        "https://static.wikia.nocookie.net/dota2_gamepedia/images/c/c7/Cosmetic_icon_Prismatic_Gem.png"
                },
                {
                    value: "Spectator Gem",
                    text: "Spectator Gem",
                    image:
                        "https://static.wikia.nocookie.net/dota2_gamepedia/images/8/86/Cosmetic_icon_Spectator_Gem.png"
                }
            ];

            for (let { type, value } of steam.descriptions) {
                if (type != "html") continue;
                if (gem) continue;
                gem = gems.find(x => value.indexOf(x.value) > -1);
            }

            if (gem) {
                item.stickers = item.stickers || [];
                console.log("Found gem");
                item.stickers.unshift({ image: gem.image, name: gem.value });
            }
        }

        item.simplified = parseSimplified(item.appid);
        if (sourceItem.discount) item.discount = sourceItem.discount.toFixed(2);

        if (!item.disabled) {
            data.push(item);
        }

        // You might be wondering why I put this here, the truth is, type was already defined on the schema as being either STANDARD or COMMODITY however in the above settings we are setting it
        // to a "localized_tag_name", this isnt what we want, I'm assuming this will be used in the filters, so they will break, we need to figure out where it breaks and rename those to something different
        item.type = getItemType(item.market_hash_name, item.appid); // sourceItem.type, // Stupid we mean commodity or not but some items have this set!?!

    }
    return data;
}

function parseDotaType(tags) {
    let type = "";
    let quality = "";
    let slot = "";
    for (let tag of tags) {
        if (tag.category == "Quality") {
            quality = tag.name;
        }
        if (tag.category == "Slot") {
            slot = tag.name;
        }
    }
    type = [quality, slot].join(" | ");
    return type;
}

function parseStattrak(name) {
    if (name.indexOf("StatTrak™") > -1) {
        return true;
    } else {
        return false;
    }
}

function parseName(name) {
    name = name || "";
    let newName = name.replace("|", "").replace("StatTrak™ ", "");
    return newName.replace(/\s\s+/g, " ");
}

function parseWear(tags) {
    for (let tag of tags) {
        if (tag.category == "Exterior") {
            return tag.localized_tag_name || tag.name;
        }
    }
    return null;
}

export function parseRarity(tags) {
    tags = tags || [];
    for (let tag of tags) {
        if (tag.category == "Rarity") {
            return {
                name: tag.localized_tag_name || tag.name,
                color: tag.color
            };
        }
    }
    return null;
}

export function parseStickers(htmlString, inspected) {
    if (!htmlString.trim()) return [];
    inspected = inspected || { stickers: [] };

    let el = $("<div></div>");
    el.html(htmlString);
    let images = $("img", el)
        .get()
        .map(function(elm) {
            return elm.src;
        });

    let stickers = [];
    let names = $("center", el)
        .text()
        .split(",")
        .map(x => x.replace("Sticker: ", "").trim());

    for (let i in names) {
        if (!names[i]) continue;

        let stickerInspect = inspected.stickers[i];

        stickers.push({
            image: images[i],
            name: names[i],
            wear: stickerInspect ? stickerInspect.wear : null
        });
    }

    return stickers;
}

function parseCurrency(currency) {
    // TODO: Parse Currency depending on user locale
    if (currency === "USD") {
        return "$";
    }
}

function parseSimplified(appid) {
    if (appid === 730) {
        return false;
    }
    return true;
}

function parseInspectLink(actions) {
    for (let action of actions) {
        if (action.link.substring(0, 15) === "steam://rungame") {
            return action.link;
        }
    }
    return null;
}

function parseTf2Parts(descriptions, tags, item) {
    // Checking if item is a crate
    for (let tag of tags) {
        if (tag.localized_tag_name == "Crate" || tag.name == "Crate") {
            return "";
        }
    }

    let stickers = [];
    let combinedKitName = "";
    let breakCheck = false;

    for (let desc of descriptions) {
        let word = desc.value.split(" ")[0];

        // Killstreaks Active = Killstreak Kit
        // Sheen = Specialized Killstreak Kit
        // Killstreaker = Professional Killstreak Kit
        if (word == "Killstreaks" || word == "Sheen:" || word == "Killstreaker:") {
            combinedKitName += desc.value + "\n\r"; // Combining kit descs
            continue;
        }

        // Parsing Unusual "part"
        else if (desc.value.split(" ")[1] == "Unusual") {
            stickers.push({
                image: "https://i.imgur.com/SlUNPT9.png",
                name: desc.value
            });
            continue;
        }

        // Halloween parts - matching known list of parts
        for (let spell of tf2HalloweenSpellNames) {
            let a = desc.value.replace(" (spell only active during event)", "");
            if (a.includes(spell)) {
                let spellData = tf2HalloweenSpells.find(x => x.value === spell);
                stickers.push({
                    image: spellData.image,
                    name: spellData.text
                });
                breakCheck = true;
                break;
            }
        }
        //if (breakCheck) continue;

        //Normal parts - matching known list of parts
        for (let strangePart of tf2StrangePartNames) {
            if (desc.value.includes(strangePart)) {
                let strangePartData = tf2StrangeParts.find(x => x.text === strangePart);
                stickers.push({
                    image: strangePartData.image,
                    name: strangePartData.text
                });
                break;
            }
        }
    }

    // Adding combined kit to stickers
    if (combinedKitName !== "") {
        let image = "https://i.imgur.com/5SUMkHu.png"; // Default error image - should really never display
        if (combinedKitName.includes("Killstreaker:")) image = require("@/assets/tf2_killstreakkit_professional.png");
        else if (combinedKitName.includes("Sheen:")) image = require("@/assets/tf2_killstreakkit_specialized.png");
        else if (combinedKitName.includes("Killstreaks:")) image = require("@/assets/tf2_killstreakkit_standard.png");
        stickers.push({
            image: image,
            name: combinedKitName
        });
    }

    return stickers;
}

function parseTf2Class(tags) {
    let classes = [];

    for (let tag of tags) {
        if (tag.category === "Class") {
            classes.push(tag.name);
        }
    }

    if (classes.length === 9) {
        return "All Class";
    } else if (classes.length < 4) {
        return classes.join(", ");
    } else {
        return "Multi Class";
    }
}

function filterName(name) {
    let exceptions = ["Vintage Merryweather"]; // TODO: Add rest of exhaustive list of exception names
    let names = name.split(" ");
    let cleaned = "";
    // Exceptions
    if (exceptions.includes(name)) return name;

    for (let n of names) {
        if (
            n !== "Unusual" &&
            n !== "(Factory New)" &&
            n !== "(Minimal Wear)" &&
            n !== "(Field-Tested)" &&
            n !== "(Well-Worn)" &&
            n !== "(Battle Scarred)" &&
            n !== "Taunt:" &&
            n !== "Vintage"
        ) {
            cleaned += n + " ";
        }
    }
    return cleaned;
}

function getContextId(appId) {
    let games = [
        { appId: 730, contextId: 2 }, // csgo
        { appId: 440, contextId: 2 }, // tf2
        { appId: 570, contextId: 2 }, // dota2
        { appId: 433850, contextId: 1 }, //z1br
        { appId: 252490, contextId: 2 } // rust
    ];

    let game = games.find(e => e.appId == appId)
    return game ? game.contextId : 2;
}

function parseCollection(tags) {
    let tag = tags.find(x => x.category == "ItemSet");

    let collection = availableCollections[tag.name];

    return collection;
}

function parseInspected(data) {
    // {
    //   "stickers": [
    //     {
    //       "slot": 2,
    //       "sticker_id": 4508,
    //       "wear": 0.48989033699035645,
    //       "scale": null,
    //       "rotation": null,
    //       "tint_id": null
    //     }
    //   ],
    //   "accountid": null,
    //   "itemid": "18715566767",
    //   "defindex": 11,
    //   "paintindex": 8,
    //   "rarity": 1,
    //   "quality": 4,
    //   "paintwear": 0.3815753757953644,
    //   "paintseed": 220,
    //   "killeaterscoretype": null,
    //   "killeatervalue": null,
    //   "customname": null,
    //   "inventory": 237,
    //   "origin": 24,
    //   "questid": null,
    //   "dropreason": null,
    //   "musicindex": null,
    //   "entindex": null
    // }
    let stickers = [];
    if (data.stickers) {
        for (let sticker of data.stickers) {
            // There's probably a cleaner way of doing this, such as unpacking them? {...}
            stickers.push({
                slot: sticker.slot,
                wear: sticker.wear
            });
        }
    }

    let inspected = {
        patternIndex: data.paintseed,
        paintIndex: data.paintindex,
        float: data.paintwear,
        stickers
    };
    return inspected;
}

function parseTf2Level(type) {
    let words = type.split(" ");
    let level = words.findIndex(e => e == "Level");
    if (level > -1) return words[level + 1];
    return null;
}

export function getFloatRangesFromMinMaxWear(minWear, maxWear) {
    let defaultRanges = [
        { name: "BS", range: [1.0, 0.44] },
        { name: "WW", range: [0.44, 0.37] },
        { name: "FT", range: [0.37, 0.15] },
        { name: "MW", range: [0.15, 0.07] },
        { name: "FN", range: [0.07, 0.0] }
    ];

    // TODO: Re-work this entire function. This is pretty badly written, and I"m not sure of its performance.
    let maxRanges = [];
    let maxFlag = true;
    // Max ranges
    for (let i = 0; i < defaultRanges.length; i++) {
        if (maxWear == defaultRanges[i].range[0]) {
            // If wear range stays the same as default
            break;
        }
        if (defaultRanges[i].range[1] > maxWear) {
            // If the current wear does not on the skin
            maxRanges.push({
                name: defaultRanges[i].name + " - Unavailable",
                range: defaultRanges[i].range,
                striped: true,
                position: i
            });
        } else {
            if (maxFlag) {
                // Add a striped and a non-striped progress bar
                maxRanges.push({
                    name: defaultRanges[i].name + " - Unavailable",
                    range: [defaultRanges[i].range[0], maxWear],
                    striped: true,
                    position: i - 0.1
                });
                maxRanges.push({
                    name: defaultRanges[i].name,
                    range: [maxWear, defaultRanges[i].range[1]],
                    striped: false,
                    position: i
                });
                maxFlag = false;
            }
        }
    }
    // Min ranges
    let minFlag = true;
    let minRanges = [];
    for (let i = defaultRanges.length - 1; i >= 0; i--) {
        if (minWear == defaultRanges[i].range[1]) {
            // If wear range stays the same as default
            break;
        }
        if (defaultRanges[i].range[0] < minWear) {
            // If the current wear does not on the skin
            minRanges.push({
                name: defaultRanges[i].name + " - Unavailable",
                range: defaultRanges[i].range,
                striped: true,
                position: i
            });
        } else {
            if (minFlag) {
                // Add a striped and a non-striped progress bar
                minRanges.push({
                    name: defaultRanges[i].name + " - Unavailable",
                    range: [minWear, defaultRanges[i].range[1]],
                    striped: true,
                    position: i + 0.1
                });
                minRanges.push({
                    name: defaultRanges[i].name,
                    range: [defaultRanges[i].range[0], minWear],
                    striped: false,
                    position: i
                });
                minFlag = false;
            }
        }
    }
    minRanges.reverse();
    let finalRanges = maxRanges.concat(minRanges);
    // Adding missing default ranges
    let wearNames = [];
    for (let range of finalRanges) {
        wearNames.push(range.name.substring(0, 2));
    }
    wearNames = [...new Set(wearNames)]; // Removing duplicates
    for (let i = 0; i < defaultRanges.length; i++) {
        if (!wearNames.includes(defaultRanges[i].name)) {
            defaultRanges[i].striped = false;
            defaultRanges[i].position = i;
            finalRanges.push(defaultRanges[i]);
        }
    }

    // Sorting
    // Assigning a position (striped bars having +- .1) is a really ghetto solution
    finalRanges.sort((a, b) => (a.position > b.position ? 1 : -1));
    // Removing "position param"
    for (let range of finalRanges) {
        delete range.position;
    }
    return finalRanges;
}

function parseTf2Craftable(descriptions) {
    for (let desc of descriptions) {
        if (desc.value == "( Not Usable in Crafting )") {
            return "Un-craftable";
        }
    }
    return "Craftable";
}

export default parseItems;
