var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex v-select-custom my-auto"},[_c('div',{staticClass:"d-flex px-2 v-bs-ranger"},[_c('div',{staticClass:"d-flex"},[_vm._m(0),_c('currency-input',{staticClass:"v-ranger v-title",attrs:{"value":_vm.min,"placeholder":"MIN","options":{
          currency: 'USD',
          currencyDisplay: 'hidden',
          hideCurrencySymbolOnFocus: false,
          hideGroupingSeparatorOnFocus: false,
          hideNegligibleDecimalDigitsOnFocus: false,
          useGrouping: true,
          valueRange: { min: 0, max: 100000 },
        }},on:{"change":function($event){_vm.min = $event}}})],1),_c('div',{staticClass:"v-hr-spacer mx-1"}),_c('div',{staticClass:"d-flex"},[_vm._m(1),_c('currency-input',{staticClass:"v-ranger v-title",attrs:{"placeholder":"MAX","value":_vm.max,"options":{
          currency: 'USD',
          currencyDisplay: 'hidden',
          hideCurrencySymbolOnFocus: false,
          hideGroupingSeparatorOnFocus: false,
          hideNegligibleDecimalDigitsOnFocus: false,
          useGrouping: true,
          valueRange: { min: 0, max: 100000 },
        }},on:{"change":function($event){_vm.max = $event}}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-group-prepend brl"},[_c('span',{staticClass:"input-group-text brl",attrs:{"id":"basic-addon1"}},[_vm._v("$")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text brl",attrs:{"id":"basic-addon1"}},[_vm._v("$")])])}]

export { render, staticRenderFns }