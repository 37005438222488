<template>
  <input 
    ref="inputRef" 
    :value="formattedValue"
    :placeholder="placeholder"
    v-on:keyup.enter="$emit('enter')"
    v-on:keyup="keyup"
  >
</template>

<script>
import useCurrencyInput from 'vue-currency-input'
import { watch } from '@vue/composition-api'
import numeral from "numeral"

export default {
  name: 'CurrencyInput',
  props: {
    value: {
      type: Number,
      coerce: str => parseFloat(str)
    }, // Vue 2: value
    options: Object,
    placeholder: String,
  },
  setup (props) {
    const { formattedValue, inputRef, setValue} = useCurrencyInput(props.options)

    watch(() => props.value, (value) => { // Vue 2: props.value
      setValue(value)
    })

    return { inputRef, formattedValue }
  },
  methods: {
    keyup() {
      console.log("ey up");
      this.$emit('priceChanged', numeral(event.target.value).value());
    }
  }
  
}
</script>