function guard(to, from, next){
    // check auth
    let authed = true; // TODO: Fix

    if(authed) {
        // or however you store your logged in state
        next(); // allow to enter route
    } else{
        next('/login'); // go to '/login';
    }
}

export default guard;