console.log("Store.JS Loaded");
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import VuexReset from '@ianwalter/vuex-reset'

const vuexLocal = new VuexPersistence({
  // supportCircular: true,
  modules: [ 'auth' ],
  
  // Reducer takes in what should be remembered, here I'm only remembering auth.
  reducer: (state) => ({
    auth: state.auth
  })
})

import cart from './cart';
import auth from './auth';
import items from './items';
import inventory from './inventory';
import watchlist from './watchlist';
import activities from './activities';
import filters from './filters';
import stepByStep from './stepByStep';
import commodities from './commodities';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    cart,
    auth,
    items,
    inventory,
    watchlist,
    activities,
    filters,
    stepByStep,
    commodities,
  },
  state: {
  },
  mutations: {
    reset: () => {}
  },
  actions: {
  },
  plugins: [ 
    vuexLocal.plugin,
    VuexReset()
  ]
})
