<template>
    <li
        class="dropdown mx-1"
        id="notificationsDropdown"
        :class="{ 'show': isDropdownOpen }">
        <a href="#0"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            class="text-white"
            @mouseenter="toggle(true)">

            <img src="@/assets/notifications.svg" />

            <div v-if="notifications.length > 0" class="notification-count"><span>{{notifications.filter(x=>!x.read).length}}</span></div>
        </a>
        
        <transition enter-active-class="animated fadeIn faster" leave-active-class="animated fadeOut faster" mode="out-in">
            <div class="dropdown-menu dropdown-menu-right nav-dropdown notification-dropdown"
                @mouseleave="toggle(false)"
                aria-labelledby="notificationDropdown"
                style="overflow-y: auto; background-color: white; border-top-left-radius: 0; border-top-right-radius:0; margin-left: -65px; border-top: 0;z-index:2020; margin-top: 15px; padding-top: 0px;"
                :class="{ 'show': isDropdownOpen }" v-if="isDropdownOpen">
                
                
                <vue-custom-scrollbar tagname="div" class="notifications-scroll">
                    <template  v-for="(notification, i) in notifications">
                        <div class="notification"  v-bind:key="notification.id">
                            <div class="d-flex p-3" style="width: 300px;">
                                <div v-if="notification.image" class="my-auto">
                                    <img class="rounded-circle" :src="notification.image" style="height:40px; width:40px;object-fit: contain;" />
                                </div>

                                <div class="notification-content"
                                    style="width: 250px">
                                    <div v-html="notification.html"></div>
                                    <div v-if="notification.time" class="notification-time mt-1">{{moment(notification.time).fromNow()}}</div>
                                </div>

                                <div class="ml-auto mr-1 my-auto">
                                    <a href="#0" @click="readNotification(notification)">
                                        <font-awesome-icon class icon="times" size="sm" style="color: #1fa9be" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        
                        <div v-if="i != notifications.length-1" class="px-2 my-2" v-bind:key="notification.id+'-hr'"><div class="dropdown-divider m-0 user-divider"></div></div>
                        <!-- <hr style="margin-top: 0; margin-bottom: 0;"  v-bind:key="notification.id"/> -->
                    </template>
                </vue-custom-scrollbar>
                <div class="mx-auto">
                    <button class="btn btn-block btn-teal" type="button" style="border-radius:0" @click="clearAllNotifications">
                        <font-awesome-icon class="fa-md" icon="trash" />
                        Clear All
                    </button>
                </div>

            </div>
        </transition>
    </li>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import moment from "moment";

export default {
    data() {
        return {
            // notifications: this.$store.getters['auth/notifications'],
            isDropdownOpen: false,

        }
    },
    components: {
        vueCustomScrollbar
    },
    methods: {
        async clearAllNotifications() {
            let oldNotifs = JSON.parse(JSON.stringify(this.notifications));

            this.isDropdownOpen = false;
            
            // this.notifications = []; // can't do this.
            await this.$store.dispatch("auth/updateUser", [{
                key: "notifications",
                value: []
            }]);
            this.$store.commit("auth/CLEAR_NOTIFS");
        },
        moment,
        async readNotification(notification) {
            let oldNotifs = JSON.parse(JSON.stringify(this.notifications));
            this.notifications.splice(this.notifications.indexOf(notification), 1);

            await this.$store.dispatch('auth/updateUser', [{          
                key: "notifications",
                value: this.notifications,
                oldValue: oldNotifs
            }]);
            if (this.notifications.length == 0)
                this.isDropdownOpen = false;
        },

        toggle(wantedState) {
            if (this.notifications.length == 0) return
            if (wantedState == 'toggle')
                this.isDropdownOpen = !this.isDropdownOpen;
            else
                this.isDropdownOpen = wantedState;
            // this.isDropdownOpen = true;

            this.$emit('noticationsToggle', this.isDropdownOpen)
            // console.log("emitted toggle event")
        },
    },
    computed: {
        notifications() {
            return this.$store.getters['auth/notifications'].sort((a,b) => (a.time < b.time) ? 1 : -1);
        },
        user() {
            return this.$store.getters['auth/user'];
        }
    }
}
</script>