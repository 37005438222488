<template>
  <div class="d-flex v-select-custom my-auto">
    <div class="d-flex px-2 v-bs-ranger">
      <div class="d-flex">
        <div class="input-group-prepend brl">
          <span class="input-group-text brl" id="basic-addon1">$</span>
        </div>

        <currency-input
          :value="min"
          @change="min = $event"
          placeholder="MIN"
          class="v-ranger v-title"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            hideCurrencySymbolOnFocus: false,
            hideGroupingSeparatorOnFocus: false,
            hideNegligibleDecimalDigitsOnFocus: false,
            useGrouping: true,
            valueRange: { min: 0, max: 100000 },
          }"
        />
      </div>

      <div class="v-hr-spacer mx-1"></div>

      <div class="d-flex">
        <div class="input-group-prepend">
          <span class="input-group-text brl" id="basic-addon1">$</span>
        </div>
        <currency-input
          placeholder="MAX"
          :value="max"
          @change="max = $event"
          class="v-ranger v-title"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            hideCurrencySymbolOnFocus: false,
            hideGroupingSeparatorOnFocus: false,
            hideNegligibleDecimalDigitsOnFocus: false,
            useGrouping: true,
            valueRange: { min: 0, max: 100000 },
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CurrencyInput from "./CurrencyInput";

export default {
  name: "PriceRanger",
  props: {
    minPrice: Number,
    maxPrice: Number,
    options: Object,
  },

  data() {
    return {
      min: null,
      max: null,
    };
  },

  components: { CurrencyInput },

  methods: {
    emitNewPrice() {
      // Sends back to parent component the prices
      let min = this.min;
      let max = this.max;
      this.$emit("input", {
        value: { min, max },
        text: `$${min} - $${max}`,
      });
    },
    reset() {
      this.min = null;
      this.max = null;
    },
  },
  watch: {
    min() {
      this.emitNewPrice();
    },
    max() {
      this.emitNewPrice();
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/brand.scss";
.v-select-custom {
  position: relative;
  width: 100%;
  min-width: 200px;
  height: 30px;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;

    .v-select-toggle {
      background-color: #f8f9fa;
      border-color: #f8f9fa;
      opacity: 0.65;
      cursor: not-allowed;

      &:focus {
        outline: 0 !important;
      }
    }
  }

  min-width: 260px;
  max-width: 260px;
  width: 260px;
  text-overflow: ellipsis;
}

.v-ranger {
  min-width: 65px;
  height: 30px;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgb(146, 158, 174);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 0 10px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #1e2021;

  text-align: center;

  &:hover {
    background-color: #e2e6ea;
    border-color: #dae0e5;
  }
  &:focus {
    outline: none;
    border: 1px solid $primary;
    // outline: 1px solid $primary;
    box-shadow: 0 0 1pt 1pt $primary;
  }
}

.input-group-prepend {
  padding: 0;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.v-hr-spacer {
  width: 10px;
  min-width: 10px;
  height: 2px;
  background-color: $primary;
  margin: auto auto;
}

input {
  width: 100%;
}

.v-title {
  text-overflow: ellipsis;
  overflow: hidden;
  color: $color-primary-darker;
}

.brl {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
</style>
