<template>
  <div id="app">
    <div class="d-flex flex-column h-100">
      <div class="alert alert-danger bannod" v-if="loggedIn && user && user.state == 'LIMITED'">
        Your account is limited, please confirm your email
      </div>      
      <div class="alert alert-danger bannod" v-if="loggedIn && user && user.state == 'HARD_BANNED'">
        Account disabled, please contact customer support.
      </div>
      <Header></Header>
      <router-view />
    </div>
    <StepByStep/>
  </div>
</template>

<style lang="scss">
</style>

<script>
import Header from "@/components/Header.vue";
import StepByStep from "@/components/StepByStep.vue";
import api from "@/api.js";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  data() {
    return {
      seenNotifications: [],
    }
  },
  components: {
    Header,
    StepByStep
  },
  created: function() {
    //check expired jwt 
    let token = localStorage.getItem('auth');
    if (token) {
      let info = atob(token.split('.')[1]);
      let parsedInfo = JSON.parse(info);

      if (moment.unix(parsedInfo.exp).isBefore()) {
        console.log("Expired token!");
        // Log out the user.
        this.$store.dispatch('auth/logout');
        this.$router.push('/');
      } else {
        console.log("Token is OK!");
      }
    }
  
    // Remove stepbystep if not logged in
    if (!this.$store.getters["auth/loggedIn"]) {
      this.$store.dispatch("stepByStep/clear");
    }

    // Updating user balance every 2s
    if (this.$store.getters["auth/loggedIn"] == true) {
      this.setupUserpolling();
    }
    // Updating user details on page refresh
    if (this.$store.getters["auth/loggedIn"] == true) {
      let user = this.$store.getters["auth/user"];
      this.$store.dispatch("auth/refreshUser", user);
    }

    let self = this;
    api.axios.interceptors.response.use(undefined, function(err) {
      return new Promise(function(resolve, reject) {
        if (!err.response) return resolve();
        console.log("API Error");
        if (err.response.status === 401) {
          self.$store.dispatch("auth/logout");
          self.$router.push("/login");
        }
        if (err.response.status === 403) {
          self.$router.push("/maintenance");
        }
        throw err;
      });
    });
  },
  async mounted() {
    let token = this.$route.query.token;
    let rememberMe = this.$route.query.rememberMe || true;
    let error = this.$route.query.error;

    // Probably should handle the error stuff first
    if (error){
      switch(error){
        case "isIpBanned":
          Swal.fire("You have been IP banned.", "Site functions have been restricted as a result.", 'error')
          break
        case "isSteamrepBanned":
          Swal.fire("You are Steamrep banned.", "Site functions have been restricted as a result.", 'error')
          break
        case "isCautioned":
          Swal.fire("You are Steamrep cautioned.", "We will keep an eye on your actions on this site.", 'error')
          break
        case "isLimited":
          Swal.fire({
            title: 'Your steam account is limited.',
            icon: 'error',
            html: 'Please upgrade to a full steam account. More information <a href="https://support.steampowered.com/kb_article.php?ref=3330-iagk-7663" style="text-decoration: underline;">here</a>.'
          })
          break
      }
    }

    if (token) {
      console.log("Got token logging in");
      let query = Object.assign({}, this.$route.query);
      delete query.token;
      delete query.rememberMe;
      this.$router.replace({ query });
      
      await this.$store.dispatch("auth/loginToken", { token, rememberMe });
    }

    // Some weird issue with token not being set correctly.
    setTimeout(() => {
      this.$store.dispatch("cart/updateCart")
      this.$store.dispatch("watchlist/updateWatchlist")
      this.$store.dispatch("commodities/updateCommodities");
    }, 100)
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
    loggedIn() {
      return this.$store.getters["auth/loggedIn"];
    }
  },
  
  watch:{
      user(value) {
        if (!Array.isArray(this.user.notifications)){
          this.user.notifications = []
        }
        //this.user.notifications = this.user.notifications || [];
        for (let notification of this.user.notifications) {
          if (!notification.id) notification.id = Math.floor(Math.random() * 10000)
          if (this.seenNotifications.indexOf(notification.id) > -1) {
            continue;
          }

          this.seenNotifications.push(notification.id);
          // let toast = this.$toasted.show(notification.html, { 
          //   theme: "toasted-primary", 
          //   position: "bottom-center", 
          //   duration : 10000
          // });
        }
      },
      loggedIn(newVal, oldVal) {
        if (oldVal == false && newVal == true) {
          this.setupUserpolling();
        }
      }
  },
  methods: {
    setupUserpolling() {
      // TODO: Refactor for SignalR changefeed
      setInterval(() => {
        let user = this.$store.getters["auth/user"];
        this.$store.dispatch("auth/refreshUser", user);
      }, 15 * 1000);
    }
  }
}
</script>

<style lang="scss">
.bannod {
  margin-bottom: 0 !important;
  border-radius: 0 !important;
}
</style>