import Vue from 'vue';
const api = require('../api');
console.log(`Activities.JS loaded`);

export default {
    namespaced: true,
    state: {
        transactionActivities: [],
        activities: []
    },
    getters: {
        transactionActivities: (state) => state.transactionActivities,
        activities: (state) => state.activities
    },
    mutations: {
        clearTransactionActivities(state) {
            Vue.set(state, 'transactionActivities', [])
        },
        setTransactionActivities(state, transactionActivities) {
            Vue.set(state, 'transactionActivities', transactionActivities)
        },
        clearActivities(state) {
            Vue.set(state, 'activities', [])
        },
        setActivities(state, activities) {
            Vue.set(state, 'activities', activities)
        }
    },
    actions: {
        async updateTransactionActivities(context, params) {
            let transactionActivities = (await api.getTransactionActivity(params)).data
            context.commit('setTransactionActivities', transactionActivities)
        },
        async updateActivities(context, params) {
            let activities = (await api.getActivities(params)).data
            context.commit('setActivities', activities)
        },
    }
}