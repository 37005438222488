import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        filters: {},
        filtersLoaded: false, 
    },
    getters: {
        filters: (state) => state.filters,
        filtersLoaded: (state) => state.filtersLoaded,
    },
    mutations: {
        SET_FILTER(state, { filter, value }) {
            Vue.set(state.filters, filter, value)
        },
        CLEAR_FILTER(state, { filter }) {
            Vue.set(state.filters, filter, null);
        },
        SET_LOADED_FILTERS(state, status) {
            state.filtersLoaded = status;
        }
    },
    actions: {
        updateFilter({ commit }, { filter, value }) {
            //console.log(`SET_FILTER: ${filter} ${JSON.stringify(value)}`);
            commit("SET_FILTER", { filter, value });
        },
        clearFilters({ state, commit }) {
            commit('SET_LOADED_FILTERS', false);
            for (let filter of Object.keys(state.filters)) {
                commit("CLEAR_FILTER", { filter });
            }
            commit('SET_LOADED_FILTERS', true);
        },
        setFilterLoadedStatus({ state, commit }, status) {
            commit('SET_LOADED_FILTERS', status);
        } 
    }
}