<template>
    <div>
        <div v-if="type == 'specific' && items.length > 0">
            <span class="h4 g-dark">Available on the Marketplace</span>
        </div>
            
        <div v-if="type == 'similar' && items.length > 0">
            <span class="h4 g-dark">Similar items</span>
        </div>
    
        <!--             
        <div style="position: relative; width: 0; height: 0">
            <div style="position: absolute; left: -100px; top:190px;">
                <div class="" v-if="scrollPosition > 0" style="">
                    <img src="@/assets/left.svg" alt="" class="clickable" @click="slide(game.id, 'left')">
                </div>
            </div>
        </div> -->

        <!-- <div class="d-inline-block" style="width:100%;"> -->
        <div class="" >
            <!-- {{notitle}} -->
            <span v-if="notitle"></span>
            <span v-else style="font-family: Rubik;font-style: normal;font-weight: bold;font-size: 24px;line-height: 28px;letter-spacing: -0.5px;color: #545454;">Recommended {{game.name}} items </span>
            
            <!-- <router-link v-if="items.length > 0" :to="'/' + game.page + (item ? `?market_hash_name=${item.market_hash_name}` : '')" v-slot="{ href, route, navigate, isActive, isExactActive }">
                <span style="float:right; font-family: Roboto;font-style: normal;font-weight: 500;font-size: 16px;line-height: 19px;color: #545454;"><a :href="href" @click="navigate">View more</a></span>
            </router-link> -->

            <!-- This middle one is the correct one  -->
            <!-- <div tagname="div" class="items" ref="game_slider" style="overflow: hidden; margin-top:29px; padding-bottom: 15px">
                <Item class="mr-2" v-for="(item, index) of items" v-bind:key="index" :item="item" :purchasable="true"></Item>
                <infinite-loading-extra @infinite="infiniteHandler" direction="right" ></infinite-loading-extra> 
            </div>
             -->

            <!-- This one is to have multi-row -->
            <div class="row d-flex justify-content-start my-2">
                <Item class="mr-2 my-2" v-for="(item, index) of items" v-bind:key="index" :item="item" :purchasable="true"></Item>
            </div>


            <!-- 
            <div v-if="initialLoad" style="position:relative; height: 120px; width:100%">
                <div class="loader"></div>
            </div> -->
        </div>

        <!-- <div style="margin-left:10px; position:relative;z-index:2"> -->

        <!-- <div class="d-inline-block" style="position: relative; width: 0; height: 0">
            <div style="position: absolute; right: -100px; top:100px;">
                <div class="" v-if="(scrollPosition < scrollWidth) && scrollWidth != 0">
                    <img src="@/assets/right.svg" alt="" class="clickable" @click="slide(game.id, 'right')">
                </div>
            </div>
        </div> -->
    </div>  
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";

import InfiniteLoadingExtra from '@/components/InfiniteLoadingExtra';
import api from "../api";
import parseItems from "../utils/parseItems";
import { parse } from '@fortawesome/fontawesome-svg-core';

export default {
    props: [
        'game', 'notitle', 'type', 'item'
    ],
    data() {
        return {
			scrollPosition: 0,
            scrollWidth: 0,
            items: [],
            continuationToken: "",
            discount: "",
            initialLoad: true,
            isLoading: false,
        }
    },
    components: {
        vueCustomScrollbar,
        InfiniteLoadingExtra,
    },
    async mounted() {
        console.log(`Scroll game view initialzie as: ${this.type}`)
        console.log(this.item);
        await this.loadMore();
        this.updatePositions();
        
    },
    methods: {
        async infiniteHandler($state) {
            console.log("Infinite handler hit! Loading more");
            let isMoreItems = await this.loadMore();
            if (isMoreItems)
                $state.loaded();
            else
                $state.complete();

        },
        updatePositions() {
            let infiniteLoader = this.$refs.game_slider;
            let element = infiniteLoader.$el;
            // let element = infiniteLoader.getScrollParent();
            console.log("Scroll position calculation here");
            if (!element) return;
            this.scrollPosition = element.scrollLeft;
            this.scrollWidth = element.scrollWidth - element.offsetWidth;
        },
        scrollTo(element, scrollPixels, duration) {
            if (!element) return;
            const scrollPos = element.scrollLeft;
            this.updatePositions();
            let self = this;
            
            if ( !( (scrollPos === 0 || scrollPixels > 0) && (element.clientWidth + scrollPos === element.scrollWidth || scrollPixels < 0))) {
                const startTime =  "now" in window.performance ? performance.now() : new Date().getTime();
                
                function scroll(timestamp) {
                    const timeElapsed = timestamp - startTime
                    const progress = Math.min(timeElapsed / duration, 1);
                    element.scrollLeft = scrollPos + scrollPixels * progress;

                    if (timeElapsed < duration) {
                        window.requestAnimationFrame(scroll);
                        self.updatePositions();
                    } else {
                        self.updatePositions();
                        return;

                    }
                }
                window.requestAnimationFrame(scroll); // TODO: Not using the timestamp parameter here?
            }
        },
        slide(gameId, direction) {
            const amount = 270;
            const tiles = 5;
            direction = direction == 'left' ? -1 : 1;
            let slidable = this.$refs['game_slider'].$el
            this.scrollTo(slidable, direction * amount * tiles, 0);
            let self = this;
            setTimeout(function() {
                self.updatePositions();
            }, 810);
        },

        async loadMore() {
            let appId = this.game.id;
            let maxItems = 20;

            if (this.isLoading) return;
            this.isLoading = true;

            console.log(`Attempting to load more for ${appId}`)
            let response;

            // TODO: Refactor out so the parent handles this.
            if (this.type == "recommended") {
                let discount = this.discount;

                response = await api.getRecommendedItems({
                    appId,
                    discount,
                    maxItems,
                });

                this.items = this.items.concat(await parseItems(response.data));
                if (response.headers['discount']){
                    this.discount = parseFloat(response.headers['discount']).toFixed(3);
                    this.initialLoad = false;
                    this.isLoading = false;
                    return true;
                }
            } else if (this.type == "similar") {
                let continuationToken = this.continuationToken;
                let market_hash_name =this.item.market_hash_name;
                let params = { market_hash_name, maxItems, appId };
                
                if (continuationToken)
                    params.continuationToken = continuationToken;
                
                response = await api.getSimilarItems(params);

                this.items = this.items.concat(await parseItems(response.data));
                this.continuationToken = response.headers['continuation-token'];
                if (this.continuationToken){
                    this.initialLoad = false;
                    this.isLoading = false;
                    return true;
                } else {
                    this.items.splice(0, 1); // take off the first one, as its usually the same item
                }
            } else if (this.type == "specific") {
                console.log(`Loading item: ${this.item.market_hash_name}`)
                let continuationToken = this.continuationToken;
                let weapon = this.item.market_hash_name;
                let params = { market_hash_name: weapon, appId, maxItems, state: "LISTED", excludeOwned: true };
                
                if (continuationToken)
                    params.continuationToken = continuationToken;
                
                response = await api.getItems(params);
                let parsedItems = await parseItems(response.data);
                
                parsedItems = parsedItems.filter(x => x.id != this.item.id);

                this.items = this.items.concat(parsedItems);

                this.continuationToken = response.headers['continuation-token'];
                if (this.continuationToken){
                    this.initialLoad = false;
                    this.isLoading = false;
                    return true;
                }
            }

            this.isLoading = false;
            this.initialLoad = false;
            return false;
        }
    },
    watch: {
        async $route() {
            this.items = []
            //this.items.length = 0;
            await this.loadMore();
        }
    }
}

</script>