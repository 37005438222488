<template>
    <div class="d-flex my-auto">
        <div class="form-inline mx-2">
            <div class="navy-text mr-2">SORT BY</div>
            <sort-by
                class="f-box mt-1"
                :options="sortBy.options"
                @input="filterChanged('sortBy', $event)"
            />
        </div>

        <div class="divider my-auto mx-2">&nbsp;</div>


        <div class="form-inline mx-2">
            <!-- <div class="navy-text mr-2">Type</div> -->
            <filter-box class="f-box mt-1" 
                :options="tradelocked.options"
                v-model="tradelocked.selected"
                rangeable
                :defaultRangeMin="1"
                :defaultRangeMax="7"
                labelRangePlaceholder="TRADE LOCK"
                @input="filterChanged('tradelocked', $event)"
                />
        </div>

        <div class="divider my-auto mx-2">&nbsp;</div>

        <div class="form-inline mx-2">
            <price-ranger 
                @input="filterChanged('price', $event)"
                ref="priceRanger"
            />
        </div>

        <div class="form-inline mx-2" style="padding-left: 80px;">
            <button class="btn btn-primary btn-primary-fancy" style="padding: 5px 5px 5px 5px; margin-left: 20px;" @click="clearFilters(); $event.target.blur();">Clear All</button>
        </div>
    </div>
</template>

<script>
import FilterBox from '../../../components/FilterBox'
import { EventBus } from "../../../utils/eventBus.js";
import PriceRanger from "@/components/PriceRanger.vue";
import SortBy from '@/components/SortBy'

export default {
    data() {
        return {
            sortBy: {
                selected: "Best Deal",
                options: [
                    { value: "bestdeal", text: 'Best Deal' },
                    { value: "price", text: 'Price', order: true, },
                    { value: "float", text: 'Float', order: true, },
                ]
            },
            tradelocked: {
                selected: { value: null, text: "Status" },
                options: [
                    { value: { min: 0, max: 0 }, text: "Instant Only" },
                    { value: { min: 1, max: 7 }, text: "Trade Locked Only" },
                ]
            },
            price: {
                // String is a hack, backend defaults to 0 999 if fields are nan.
                selected: { value: { min: " min", max: " max" }, text: "Price" },
                options: [
                    { value: { min: 0, max: 999 }, text: "Any" },
                    { value: { min: 0, max: 5 }, text: "$0 - $5" },
                    { value: { min: 5, max: 10 }, text: "$5 - $10" },
                    { value: { min: 10, max: 20 }, text: "$10 - $20" },
                    { value: { min: 20, max: 30 }, text: "$20 - $30" },
                    { value: { min: 30, max: 40 }, text: "$30 - $40" },
                    { value: { min: 40, max: 50 }, text: "$40 - $50" },
                    { value: { min: 50, max: 100 }, text: "$50 - $100" },
                    { value: { min: 100, max: 999 }, text: "$100+" },
                ]
            }
        }
    },
    components: {
        FilterBox,
        PriceRanger,
        SortBy,
    },
    async mounted() {
        await this.clearFilters();
        if (this.$route.query.market_hash_name) {
            console.log("adding")
            await this.$store.dispatch("filters/updateFilter", { filter: "market_hash_name", value: this.$route.query.market_hash_name })
        }
        if (this.$route.query.query) {
            console.log("adding")
            await this.$store.dispatch("filters/updateFilter", { filter: "query", value: this.$route.query.query })
        }
    },
    methods: {
        filterChanged(model, value) {
            console.log(`Filter changed? ${model}`)
            // Reset to null if not selected.
            if (!value || !value.value) value = { value: null };
            if (model == "price") {
                console.log("Debug point here.")
                if (isNaN(value.value.max)) {
                    value.value.max = 999;
                }
                if (isNaN(value.value.min)) {
                    value.value.min = 0;
                }
            }
            value = value.value;

            this.$store.dispatch("filters/updateFilter", { filter: model, value });
        },
        async clearFilters(){
            console.log("Filters reset")
            await this.$store.dispatch('filters/setFilterLoadedStatus', false);
            await this.$store.dispatch('filters/clearFilters');

            // Resetting visually
            this.price.selected = { value: { min: 0, max: 999 }, text: "Price" };
            this.tradelocked.selected = { value: null, text: "Status" };
            this.sortBy.selected = "Best Deal";

            if (this.$route.query.query) {
                this.$store.dispatch('filters/updateFilter', { filter: "query", value: this.$route.query.query });
            }

            this.$store.dispatch('filters/setFilterLoadedStatus', true);

        }
    }
}
</script>
