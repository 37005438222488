<template>
    <div style="width: 100%;">
        <div class="gdark" >
            <div class="d-flex justify-content-between" style="padding-top: 6px; padding-left: 40px; padding-right:40px;">
                
                <div style="" class="d-flex" > 
                    <div style="margin-top: -5px">
                        <div class="mr-3">
                            <router-link to="/" v-slot="{ href, navigate }">
                                <a class="navbar-brand " :href="href" @click="navigate">
                                    <img src="@/assets/key-icon-teal.svg" style="max-height: 35px; max-width: 30px;" />
                                    <span class="text-white" style="margin-left:5px;font-family: Rubik; font-size: 22px;  font-weight: bold">GUARD</span>
                                    <span class="text-teal" style="font-family: Rubik; font-size: 22px; font-weight: bold">GG</span>
                                </a>
                            </router-link>
                        </div>
                    </div>
                    <div style="padding-top: 0px;">
                        <!-- <a href="#0" @mouseenter="facebook=true" @mouseleave="facebook=false">
                            <font-awesome-layers style="font-size: 2rem;">
                                <font-awesome-icon icon="circle" :style="{ color: facebook ? '#ED6B33' : '#51545E' }" />
                                <font-awesome-icon :icon="[ 'fab', 'facebook-f' ]" transform="shrink-6" :style="{ color: 'white' }" />
                            </font-awesome-layers>
                        </a> -->
                        
                        <a class="mx-3" href="https://twitter.com/guardgg_" @mouseenter="twitter=true" @mouseleave="twitter=false">
                            <font-awesome-layers style="font-size: 2rem;">
                                <font-awesome-icon icon="circle" :style="{ color: twitter ? '#ED6B33' : '#51545E' }" />
                                <font-awesome-icon :icon="[ 'fab', 'twitter' ]" transform="shrink-6" :style="{ color: 'white' }" />
                            </font-awesome-layers>
                        </a>

                        <a href="https://steamcommunity.com/groups/GUARD_GG" @mouseenter="steam=true" @mouseleave="steam=false">
                            <font-awesome-layers style="font-size: 2rem;">
                                <font-awesome-icon icon="circle" :style="{ color: steam ? '#ED6B33' : '#51545E' }" />
                                <font-awesome-icon :icon="[ 'fab', 'steam-symbol' ]" transform="shrink-6" :style="{ color: 'white' }" />
                            </font-awesome-layers>
                        </a>
                    </div>
                </div>

                <div class="d-flex">
                    <!-- <div class="mx-2"><a href="/privacypolicy.pdf" target="guardggpriv" style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 14px;color: #FFFFFF;">Privacy</a></div> -->
                    <div class="mx-2"><a href="/terms.pdf" target="guardggtos" style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 14px;color: #FFFFFF;">Terms</a></div>
                </div>

            </div>
        </div>
        <div style="background-color: #19191C;text-align:center">
            <span style="font-family: Poppins;font-style: normal;font-size: 14px;color: #FFFFFF;">
                <span style="font-weight: bold; font-family: Poppins;font-style: normal;font-size: 14px;color: #FFFFFF;">Guardgg LLC.</span>  Not affiliated with Valve Corporation
            </span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            steam: false,
            twitter: false,
            facebook: false,
        }
    }
}
</script>

<style lang="scss" scoped>
</style>