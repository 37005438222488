<template>
    <li class="dropdown mx-1" id="cartDropdown" :class="{ 'show': isDropdownOpen }">
        <a
            href="#0"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            @mouseover="openCart"
            @click="openCart"
        >
            <img src="@/assets/cart.svg" />
            <!-- <span class="cart-count">{{cartCount}}</span> -->
            <div v-if="(hasCartItems)" class="notification-count text-white"><span>{{cartCount}}</span></div>

            <span class="ml-2 cart-total text-white">${{ hasCartItems ? cartTotal.toFixed(2) : '0.00'}}</span>
            <span>
                <font-awesome-icon
                v-if="!isDropdownOpen && hasCartItems"
                class="text-white ml-2"
                icon="angle-down"
                size="lg"
                color="white"
                />
                <font-awesome-icon
                v-else-if="isDropdownOpen"
                class="text-white ml-2"
                icon="angle-up"
                color="white"
                size="lg"
                />
            </span>
        </a>

        <transition enter-active-class="animated fadeIn faster" leave-active-class="animated fadeOut faster" mode="out-in">
            <div
                v-if="isDropdownOpen"
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="cartDropdown"
                :class="{ 'show': isDropdownOpen }"
                style="z-index:2020; margin-top: 16px; border-top-right-radius: 0px; border-top-left-radius: 0px; border-top: 0;"
                @mouseleave="closeCart"
            >
                <vue-custom-scrollbar tagname="div" class="cart-scroll">
                    <template class v-for="(cartItem, i) in cartItems">
                        <div class="px-3 pt-2 d-flex cart-item" v-bind:key="cartItem.id">
                            <div>
                                <img
                                    class="rounded cart-thumb-img"
                                    :src="'https://steamcommunity-a.akamaihd.net/economy/image/'+(cartItem.image || cartItem.icon_url || cartItem.iconUrl)+''"
                                    @error="$event.target.src=require('@/assets/Placeholder.png')" 
                                    style="height:50px;"
                                />
                            </div>
                            <div
                                class="ml-4 g-light-gray d-flex justify-content-between"
                                style="width: 350px; max-wdith: 350px;"
                            >
                                <div style="width: 150px;">
                                    <div class="cart-item-name"><span v-if="cartItem.type == 'COMMODITY'">{{$store.getters["cart/qtyOf"](cartItem)}} x</span> {{cartItem.market_hash_name}}</div>
                                    <div>
                                        <span
                                            v-if="cartItem.stattrak"
                                            class="cart-item-wear"
                                            style="color: #C46C4F;"
                                        >StatTrak™</span>
                                        <span
                                            v-if="cartItem.wear"
                                            class="cart-item-wear"
                                        >{{cartItem.wear}}</span>
                                    </div>
                                </div>
                                <div>
                                    <div class="my-auto cart-price" v-if="cartItem.type == 'COMMODITY'">
                                        <!-- <div class="input-group-prepend" @click="cartItem.qty--;">
                                            <span
                                            class="input-group-text"
                                            style="border-top-left-radius: 25px;border-bottom-left-radius: 25px; background-color:white; font-weight: bold; padding-right:0px; cursor: pointer;border-right:0px;width: 20px;"
                                            >-</span>
                                        </div>

                                        <input
                                            type="text"
                                            class="form-control text-center"
                                            value="1"
                                            style="width: 50px; border-radius: 0; border-right:0;border-left:0; color:black; padding-left: 4px; padding-right:4px"
                                            v-model="cartItem.qty"
                                        />

                                        <div class="input-group-append">
                                            <span
                                            class="input-group-text"
                                            style="border-top-right-radius: 25px;border-bottom-right-radius: 25px; background-color:white; font-weight:bold; padding-left: 0px; cursor: pointer; border-left:0"
                                            @click="cartItem.qty++"
                                            >+</span>
                                        </div> -->
                                        <!-- {{cartItem.qty}} -->
                                    </div>
                                </div>
                                <div class="mr-4 ml-2 mt-2 my-auto cart-price" style="width: 50px;">
                                    ${{((cartItem.type == 'COMMODITY' ? numeral($store.getters["cart/costOf"](cartItem)).value() : numeral(cartItem.listPrice).value()).toFixed(2))}}
                                </div>
                            </div>
                            <div class="my-auto mr-4">
                                <span href="#" @click="removeFromCart(cartItem)">
                                    <font-awesome-icon class="fa-md" icon="trash" />
                                </span>
                            </div>
                        </div>

                        <div v-bind:key="cartItem.id+'-hr'" v-if="i < cartItems.length - 1" class="px-2 my-2"><div class="dropdown-divider m-0 user-divider"></div></div>
                    </template>
                </vue-custom-scrollbar>
                <div class="d-flex justify-content-around p-2" style="box-shadow: 0px -5px 5px rgba(30, 32, 33, 0.07);">
                    <div class="my-auto cart-item-name" style="font-weight: normal">
                        Total
                    </div>
                    <div>
                        <div
                            class="mt-2 cart-item-name"              
                        >{{cartItems.length}} item{{cartItems.length == 1 ? '' : 's'}}</div>
                    </div>
                    <div class="my-auto cart-price">
                        ${{cartTotal.toFixed(2)}}
                    </div>
                        <div class>
                            <router-link
                                to="/cart"
                                v-slot="{ href, navigate }"
                            >
                                <a
                                class="text-uppercase btn btn-lg btn-orange text-white"
                                style="padding-left:10px; padding-right:10px;"
                                :href="href"
                                @click="navigate"
                                >Checkout</a>
                            </router-link>
                        </div>
                    </div>
                </div>
            <!-- </div> -->
        </transition>
    </li>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import numeral from 'numeral';
export default {
    mounted() {
        console.log(this.$route.name);
    },
    data() {
        return {
            isDropdownOpen: false,

        };
    },
    computed: {
        cartTotal() {
            return this.$store.getters["cart/total"];
        },
        hasCartItems() {
            return this.$store.getters["cart/hasItems"];
        },
        cartItems() {
            return this.$store.getters["cart/condensedItems"];
        },
        cartCount() {
            if (this.$route.name == 'OrderConfirmed') return 0;

            return this.$store.getters["cart/count"];
        },
    },
    components: {
        vueCustomScrollbar
    },
    methods: {
        numeral,
        openCart() {
            if (!this.isDropdownOpen) {
                this.toggle();
            }
        },
        closeCart() {
            if (this.isDropdownOpen) {
                this.toggle();
            }
        },
        toggle() {
            if (!this.hasCartItems) return;

            this.isDropdownOpen = !this.isDropdownOpen;
            this.$emit("cartToggle", this.isDropdownOpen);

            // if (this.isDropdownOpen) this.$refs.notifications.toggleNotifications(false);
        },
        async removeFromCart(cartItem){
            console.log("Removing item");
            try { await this.$store.dispatch('cart/removeItem', cartItem); } catch (e) {}
            console.log("Removed item " + this.cartCount);
            if (this.cartCount <= 0) {
                this.isDropdownOpen = false;
            }
        }
    }

}
</script>