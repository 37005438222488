
const api = require('../api');
console.log(`Inventory.JS loaded`);
import Vue from 'vue';
import parseItems from "../utils/parseItems";

export default {
    namespaced: true,
    state: {
        inventory: []
    },
    getters: {
        inventory: (state) => state.inventory
    },
    mutations: {
        setInventory(state, items) {
            Vue.set(state, 'inventory', items)
        },
        clearInventory(state) {
            Vue.set(state, 'inventory', [])
        }
    },
    actions: {
        async updateInventory(context) {
            console.log("Getting inventory")

            let items = (await api.getInventory({ location: "SITE", state: ["UN-LISTED", "PENDING_WITHDRAW"] })).data.site
            items = await parseItems(items)
            context.commit('setInventory', items)
            console.log("Finished getting inventory")
        }
    }
}