console.log(`Watchlist.JS loaded`);
const api = require('../api');
import parseItems from "../utils/parseItems";
import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        items: [],
        id: null
    },
    getters: {
        total: state => state.items.reduce(function(total, item) { return total += (parseFloat(item.price) * parseFloat(item.qty || 1)) }, 0),
        inWatchlist: (state) => (item) => state.items.findIndex((elm) => elm.id == item.id) > -1,
        hasItems: (state) => state.items.length > 0,
        count: (state) => state.items.length,
        items: (state) => state.items,
        watchlistByGame: (state) => {
            let games = {};
            for (let item of state.items) {
                games[item.appId] = games[item.appId] || [];
                games[item.appId].push(item);
            }
            return games;
        },
        watchlistId: (state) => state.id
    },
    mutations: {
        addItemToWatchlist(state, item) {
            state.items.push(item);
        },
        removeItemById(state, itemId) {
            let indexOfItem = state.items.findIndex((item) => item.id == itemId);
            if (indexOfItem == -1) return;

            state.items.splice(indexOfItem, 1);
        },
        updateWatchlistId(state, id){
            state.id = id;
        },
        clearWatchlist(state) {
            Vue.set(state, 'items', []);
        }
    },
    actions: {
        async updateWatchlist(context){
            // If logged in, get cart from lists container
            let loggedIn = context.rootState.auth.loggedIn;
            if (loggedIn){
                let user = context.rootState.auth.user;
                let list = (await api.getLists({userId: user.id, type: "watchlist"})).data[0]
                if (!list) {
                    console.error(`Fix me`);
                }
                context.commit('updateWatchlistId', list.id)
                if (list.items.length > 0){ // Only get items if there are any
                    let items = (await api.getItems({ids: list.items.join(","), state: "LISTED"})).data
                    context.commit('clearWatchlist')

                    items = await parseItems(items);
                    for (let item of items){
                        context.commit('addItemToWatchlist', item)
                    }
                }
            }
            // Otherwise just use Vuex store
        },
        async addItem(context, item) {
            // Syncing watchlist before adding any new items
            await context.dispatch("updateWatchlist")

            if (!item.id)
                throw new Error("Item must have a trackable ID!");
            
            item.qty = item.qty || 1;
            item.price = item.price || 1;

            context.commit( 'addItemToWatchlist', item);
            let user = context.rootState.auth.user;
            if (user && user.id){
                let cart = context.getters["items"].map(a => a.id)
                let changes = [{
                    key: "items",
                    value: cart
                }]

                await api.updateResource(context.getters["watchlistId"], "list", user.id, changes)
            }
        },
        async removeItem(context, item) {
            // Syncing watchlist before adding removing items
            await context.dispatch("updateWatchlist")

            if (!item.id)
                throw new Error("Item must have a trackable ID!");
            context.commit( 'removeItemById', item.id);
            let user = context.rootState.auth.user;
            if (user && user.id){
                let reducedCart = context.getters["items"].map(a => a.id).filter(a => a != item.id)
                let changes = [{
                    key: "items",
                    value: reducedCart
                }]
                await api.updateResource(context.getters["watchlistId"], "list", user.id, changes)
            }
        },
        reset: () => {}

    }
}