import guard from '../utils/guard.js'

const topRoutes = [
    {
      path: '/faq',
      name: 'faq',
      component: () => import('../views/faq.vue')
    },
    {
      path: '/faq/:section',
      name: 'faq-section',
      component: () => import('../views/faq.vue')
    },
    {
      path: '/support',
      name: 'support',
      component: () => import('../views/404.vue')
    },
    {
      path: '/blog',
      name: 'blog',
      component: () => import('../views/404.vue')
    },
    {
      path: '/items',
      name: 'items',
      component: () => import('../views/404.vue')
    },
    {
      path: '/cart',
      name: 'cart',
      component: () => import('../views/Cart.vue')
    },
    {
      path: '/account',
      name: 'Account',
      component: () => import('../views/MyProfile.vue')
    },
    {
      path: '/maintenance',
      name: 'Maintenance',
      component: () => import('../views/Maintenance.vue')
    },
    {
      path: '/sell',
      name: 'Sell',
      component: () => import('../views/Sell.vue'),
      beforeEnter: guard
    },
    {
      path: '/inventory',
      name: 'MyInventory',
      component: () => import('../views/MyInventory.vue')
    },
    {
      path: '/my-listings',
      name: 'MyListings',
      component: () => import('../views/MyListings.vue')
    },
    {
      path: '/deposit',
      name: 'deposit',
      component: () => import('../views/Deposit.vue')
    },
    {
      path: '/s/:steam_id?/:store_id?',
      name: 'MyStore',
      component: () => import('../views/MyStore.vue')
    },
    {
      path: '/i/:item_id',
      name: 'ItemDatabaseResult',
      component: () => import('../views/ItemDatabaseResult.vue')
    },
    // {
    //   path: "/db",
    //   name: "ItemDatabase",
    //   component: () => import('../views/ItemDatabase.vue')
    // },
    {
      path: '/withdraw',
      name: 'Withdraw',
      component: () => import('../views/Withdraw.vue')
    },
    {
      path: '/activity',
      name: 'Activity',
      component: () => import('../views/Activity.vue')
    },
    {
      path: '/watchlist',
      name: 'Watchlist',
      component: () => import('../views/Watched.vue')
    },
    {
      path: '/recommended',
      name: 'Recommended',
      component: () => import('../views/Recommended.vue')
    },
    {
      path: '/new',
      name: 'New',
      component: () => import('../views/GridView.vue')
    },
    {
      path: '/listings',
      name: 'Listings',
      component: () => import('@/views/Listings')
    },
    {
      path: '/confirmed/:orderId',
      name: 'OrderConfirmed',
      component: () => import('@/views/OrderConfirmed')
    },
    {
      path: '/success/:transactionId',
      name: 'TransactionConfirmed',
      component: () => import('@/views/TransactionConfirmed')
    },
    {
      path: '/password-change',
      name: 'PasswordChange',
      component: () => import('../views/PasswordChange.vue')
    },
    {
      path: '/password-setup',
      name: 'PasswordSetup',
      component: () => import('../views/PasswordSetup.vue')
    },
    {
      path: '/email-change',
      name: 'EmailChange',
      component: () => import('../views/EmailChange.vue')
    },
    {
      path: '/beta',
      name: 'Beta',
      component: () => import('../views/BetaApplication.vue')
    },
    {
      path: '/feedback',
      name: 'Feedback',
      component: () => import('../views/Feedback.vue')
    },
    // {
    //   path: '/accounts',
    //   name: 'Accounts',
    //   component: Accounts
    // }
]

export default topRoutes;