import Swal from 'sweetalert2';
import Vue from 'vue';
const axios = require('axios');
const api = require('../api');
console.log(`Auth.JS loaded`);

let guardggLoginPage = process.env.VUE_APP_GUARD_STEAMSIGNON_URL || `https://auth.guard.gg/api/SteamSignOn`;
let guardggManualLogin = process.env.VUE_APP_GUARD_MANUALLOGIN_URL;


export default {
    namespaced: true,
    state: {
        loggedIn: false,
        user: null,
    },
    getters: {
        user: (state) => state.user,
        loggedIn: (state) => state.loggedIn,
        notifications: (state) => state.user && state.user.notifications ? state.user.notifications : [],
        id: (state) => state.user.id
    },
    mutations: {
        login(state, user) {
            if (!user) {
                console.log("User logged in with valid credentials, but couldn't find them in the DB")
                return;
            }
            state.user = user;
            state.loggedIn = true;
        },
        logout(state) {
            // Do stuff, store it etc
            state.user = null;
            state.loggedIn = false;
            localStorage.clear();
            sessionStorage.clear();
            window.location = "/"
        },
        updateUser(state, user) {
            state.user = user;
        },
        CLEAR_NOTIFS(state) {
            state.user.notifications = []
        }
    },
    actions: {
        updatelastRequestedEmailChange(context) {
            let user = context.state.user;
            user.lastRequestedEmailChange = new Date().toISOString();
            context.commit("updateUser", user);
        },
        updatelastRequestedPwChange(context) {
            let user = context.state.user;
            user.lastRequestedPwChange = new Date().toISOString();
            context.commit("updateUser", user);
        },
        // You call this one externally
        loginWithSteam(context, { rememberMe }) {
            // TODO: Implement sending a user back to the page they came from if coming from a user's store
            let url = guardggLoginPage + `?origin=${window.location.origin}&rememberMe=${rememberMe}`;
            console.log("Sending user to Guard auth with origin: " + url)
            window.location = url; // Take them to the new page
        },
        // You call this one externally
        connectSteam(context, { rememberMe }) {
            // TODO: Implement sending a user back to the page they came from if coming from a user's store
            let url = guardggLoginPage + `?origin=${window.location.origin}&rememberMe=${rememberMe}&userLinkToken=${api.getToken()}`;
            console.log("Sending user to Guard auth with origin: " + url)
            window.location = url; // Take them to the new page
        },
        async loginToken(context, { token, rememberMe }) {
            api.setToken(token, rememberMe)
            console.log("Set token, Getting my user ")
            let user = await api.getCurrentUser();
            context.commit('login', user.data);
        },
        async loginManually(context, { username, password, twoFactorToken, rememberMe }) {
            let result = {
                success: false,
                requires_twofactor: false,
                message: ""
            }
            let sentData = { username, password }
            if (twoFactorToken) {
                sentData.twoFactorToken = twoFactorToken
            }

            // Send login details and handle accordingly
            try {
                let response = await axios.post(guardggManualLogin, sentData)

                if (response.status == 200) {
                    result.message = response.data.message;

                    if (response.data.success) {
                        // Log user
                        $('#twofactor').modal('hide');
                        let { token, id } = response.data
                        api.setToken(token, rememberMe)
                        let user = await api.getUserById(id, "guardgg"); // Deprecate once realms come into play
                        console.log("got succesful login");
                        context.commit("login", user.data)
                        result.success = true;
                    } else if (response.data.requires_twofactor) {
                        // Open 2FA Modal
                        result.requires_twofactor = true
                    }
                }
            } catch (error) {
                if (error.response) {
                    result.message = error.response.data.message;

                    if (error.response.status == 401) {
                        console.log("Invalid login details.")
                    } else if (error.response.status == 500) {
                        console.log("Server error.")
                    }
                }
            }
            return result;
        },
        async refreshUser(context, user) {
            console.log("refreshUser() called")
            let updatedUser = await api.getUserById(user.id, "guardgg"); // Deprecate once realms come into play
            context.commit('updateUser', updatedUser.data)
            return updatedUser;
        },
        updateUser(context, changes) {
            let userId = context.state.user.id;

            let contextData = {};

            if (!Array.isArray(changes)) {
                contextData = changes.context;
                changes = changes.changes;
            }

            let apiCall = api.updateResource(userId, "user", "guardgg", changes, contextData);
            apiCall.then((result) => {
                if (result.data.errors.length == 0 && result.status === 200){
                    context.commit('updateUser', result.data.resource);
                }
            });
            return apiCall;
        },
        async register(context, data) {
            return api.registerUser(data).then((result) => {
                if (result.status === 201) {
                    api.setToken(result.data.token, true);
                    context.commit('login', result.data.user);
                }
            })
        },
        logout({ commit }) {
            api.setToken(null, true);
            commit('logout')
        },
        reset: () => {},
        async forgotPassword() {
            const result = await Swal.fire({
                title: `Forgot password?`, 
                text: `Please enter your email for a password reset link`,
                input: "email",
                type: 'info',
                showCancelButton: true,
                showConfirmButton: true,
            });
            // { isConfirm, value: email }
            const email = result.email;
            const isDismissed = result.dismiss;
            if (isDismissed) return;

            try {
                await api.usersForgotPassword(email);
                Swal.fire(`Success`, 'If this email is registered you should receive an email soon.', 'success');
            } catch(e) {
                Swal.fire(`Error`, 'Please try again later.', 'error');

            } 
        }
    }
}