import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import NotFound from '../views/404.vue'

Vue.use(VueRouter)

import mainRoutes from './main';
import gameRoutes from './games';
import authRoutes from './auth';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/404',
    name: 'not-found',
    component: NotFound
  },

  ...mainRoutes,
  ...gameRoutes,
  ...authRoutes,
  { path: '*', component: NotFound }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login') 
  } else {
    next() 
  }
})

export default router
