<template>
  <div class>
    <div class="item csgo m-1" :class="[{'item-simplified': simplified, 'item-compact': compact}, this.itemBackground]" style="background-color: #FFFFFF"  :style="itemStyle">
      <div v-if="(quantity > 1 || type == 'COMMODITY') &&
          ($route.name == 'Sell' || $route.name == 'MyListings')
          ">
        <div style="width: 0; height: 0; position: relative; top: 55px; left: 10px">
          <div style="padding-right: 2px; text-align: center; border-radius: 6px; border: 1px solid #626262; background-color: #606060; width: 74px; height: 16px; position: absolute;">
            <div style="font-family: Roboto;font-style: normal;font-weight: bold;font-size: 10px;color: #FFFFFF; margin-left:3px;">{{quantity}} available</div>
          </div>
        </div>
      </div>

      <div v-if="featured" class="item-featured text-center pt-2">FEATURED LISTING</div>

      <div class="item-content h-100">
        <div
          class="item-image h-100"
          :style="{ 'background-image': `url(https://steamcommunity-a.akamaihd.net/economy/image/${image}/256fx256f)`}"
        >
          <div class="item-header" style="margin-left:-20px;">
            <div>
              <a :href="urlname" class="item-title" style="font-family: Roboto;font-style: normal;font-weight: 900;font-size: 14px; cursor: pointer;" @click="$router.push(urlname)">
                <div>{{niceMarketHashName}}</div>
              </a>
              <div class="item-status d-flex">
                <div class="mr-1" v-if="appId == 440 && rarity" style="font-size: 14px; font-weight: 500;" :style="{ 'color': '#' + rarity.color == 'FAFAFA' ? 'C3C3C3' : rarity.color }">{{rarity.name}} </div>
                <div v-if="appId == 440 && craftable && rarity" class="item-wear mr-1 " style="font-size:12px"> | </div>
                <!-- <div v-if="appId == 440 && craftable" class="item-wear mr-1 " style="font-size:14px">{{craftable}}</div> -->
                <!-- <div v-if="appId == 440 && craftable && wear" class="item-wear mr-1 " style="font-size:12px"> | </div> -->


                <!-- Souvenir items -->
                <div class="" v-if="item.market_hash_name.toLowerCase().includes('souvenir')">
                  <span class="item-souvenir mr-1 " style="font-size:0.8em; font-weight:600; margin-top:0px; margin-right:2px">Souvenir </span>
                  <span style="margin-right: 2px" class="mr-1 "> | </span>
                </div>

                <div v-if="stattrak" class="item-stattrak mr-1 ">StatTrak™</div>
                <span v-if="stattrak && wear" class="item-wear mr-1 " style="color:black;"> | </span>
                <div v-if="wear" class="item-wear mr-1 " style="margin-top: 1px;">{{wear}}</div>
                <!-- <div v-if="type" class="item-wear" style="font-size:12px" :style="{color: '#' + rarity.color}">{{type}}</div> -->
              </div>
            </div>
            <!-- <div class="item-image-filler" style="height:210px;">&nbsp;</div> -->
          </div>

          <div style="position: relative; right: -140px; top: -10px;height:0">
            <img :src="splatImage" alt="" srcset="" style="border-radius: 50%" :style="{ 'height': splatImageHeight,'max-height': splatImageHeight }" v-tooltip="splatTooltip">
          </div>
          <div class="item-image-filler" style="height:110px;">&nbsp;</div>
          <div v-if="developer" class=" text-center" style="height:50px; margin-top:-50px; color:white;text-stroke: 1px black;font-weight:bold">DEVELOPER ITEM</div>

          <div
            v-if="this.currency && this.listPrice && !listingsPage"
            class="item-price"
            style="margin-left: -0px; margin-right: -10px"
            :style="{'height': simplified ? '15px' : '55px', 'margin-top': appId == 252490 ? '12px' : ''}"
          >
            <div class="price">${{numeral(listPrice).value().toFixed(2)}}</div>
            <div v-if="discount && !isNaN(discount) && discount != -Infinity && discount != Infinity && discount > 0 && purchasable" class="item-discount">SAVE {{Math.ceil(discount)}}%</div>
          </div>

          <div v-if="!this.currency && !this.listPrice" style="height:30px;">&nbsp;</div>

          <div v-if="listingsPage" class="container" style="height: 50px;" :style="{paddingTop: !float ? '30px' : '' ? !float && listingsPage : '26px'}">
            <div class="row">
              <div class="col-2 hover-pointer" style="padding-top: 2px; padding-left: 0px; padding-right: 0px;">
                <div class="circle hover-pointer" @click="reducePriceByOnePercent">-1%</div>
              </div>
              <div class="col">
                <div class="d-flex">
                  <div class="input-group-prepend brl">
                    <span style="padding-left: 10px;
                          padding-right:10px;
                          padding-top: 4px;
                          padding-bottom: 3px;
                          border: 1px solid #ced4da;
                          background-color: #e9ecef;
                          border-top-left-radius: 10px;
                          border-bottom-left-radius: 10px" id="basic-addon1">$</span>
                  </div>
                  <currency-input
                      :value="listPrice"
                      @change="listPrice = $event"
                      @priceChanged="handlePriceChanged"
                      style="
                          font-size: 1.2rem;
                          text-align: center;
                          border-top-right-radius: 15px; 
                          border-bottom-right-radius: 15px; outline: none;
                          border: 1px solid #ced4da;
                          background-color: #e9ecef;
                          color: #7BAA64;
                      "
                      :style="{color: currencyTextColour}"
                      ref="currencyRange"
                      :options="{
                          currency: 'USD',
                          currencyDisplay: 'hidden',
                          hideCurrencySymbolOnFocus: false,
                          hideGroupingSeparatorOnFocus: false,
                          hideNegligibleDecimalDigitsOnFocus: false,
                          useGrouping: true,
                          valueRange: { min: 0.05, max: 99999 },
                      }"
                  />
                </div>
                <!-- <input type="text" class="item-price-listings listings-input" v-model="listPrice" :style="{'color': numeral(listPrice).value() != numeral(previousListPrice).value() ? '#ff0000' : '#008000'}"> -->
              </div>
            </div>
          </div>

          <div style="display: flex; justify-content: space-around;flex-direction:column; height:60px;"
            :style="{'height': simplified ? '20px' : '60px'}">
            <!-- <div v-if="(!float && !patternIndex) || simplified" style="height:100px;">&nbsp;</div> -->

            <div class="d-flex justify-content-between" style="margin-right: -20px; margin-left: -25px;" :style="{marginTop: listingsPage && float ? '48px' : ''}">
              <!-- Tradable Icon -->
              <div v-if="appId == 730 || appId == 570" class="item-tradability" :class="{ 'mx-auto': stickers.length == 0 }" >
                <span v-if="isTradable">
                  <img src="@/assets/unlocked.svg" style="margin-right:5px;" /> Tradable
                </span>
                <span v-else-if="isTradable === null">
                  <img src="@/assets/errorlocked.svg" style="margin-right:5px;" /> Unknown
                </span>
                <span v-else>
                  <img src="@/assets/locked.svg" style="margin-right:5px;"  /> {{humanTradableTime}}
                </span>
              </div>

              <!-- TF2 Class -->
              <div v-if="appId === 440">
                <span style="font-family: Roboto; font-size: 14px; color: #545454; padding-left: 10px; font-weight: 500;">{{this.class}}</span>
              </div>

              <!-- Stickers -->
              <div class="item-stickers">
                <div v-for="(sticker, i) of cappedStickers" v-bind:key="i" class="item-sticker">
                  <img :src="sticker.image"
                  style="max-width: 50px;"
                  v-tooltip="appid == 730 ? sticker.name + parseStickerScrape(sticker.wear) : sticker.name"
                  :class="appId === 440 ? getTf2BackdropClass(sticker.name) : ''"
                  />
                  <font-awesome-icon v-if="sticker.wear" icon="exclamation-circle" class="sticker-wear-alert-icon" style="color: #ED6B33; max-height: 12px;"/>
                </div>
              </div>
            </div>


            <div v-if="(float || patternIndex) && !simplified">
              <div class="mt-3"></div>
              <div class="hr"></div>
              <div class="mt-2"></div>
              <div class="float-info" style="margin-right: -40px; margin-left: -15px;">
                
                <div class="progress" style="height: 10px;position: relative;">
                  <div v-for="(value, key) in floatRanges" :key="value.name"
                    v-tooltip="value.name + ' (' + value.range[0] + ' - ' + value.range[1] + ')'"
                    :style="{'width': getProgressBarWidth(value.range), 'background-color': getFloatRangeBackgroundColor(value.name), 'border-radius': getProgressBarBorderRadius(key), 'opacity': value.striped ? 0.8 : 1}"
                    class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                    :class="{'progress-bar-striped': value.striped}"
                  ></div>
                  <img src="../assets/float_arrow.svg" class="float-image" :style="{ 'left': Math.max(0, Math.min(210, (220-(float*100*2.32)))) + 'px' }">
                </div>

                <div class="float-text">
                  <!-- We do the pad and not toFixed as toFixed makes up 0's we dont know exist -->
                  <!-- Pad end will be like 0.213 or 0.0228293 -->
                  <span v-if="float" class="f1"><b>Float Value:</b> {{(parseFloat(float).toFixed(5) + '').padEnd(5, 'z').replace(/z/gmi, '') }}</span>
                  <span v-if="patternIndex" class="f1"><b>Pattern Index:</b> #{{patternIndex}}</span>
                </div>
              </div>
            </div>
        </div>


          <div v-if="purchasable && !editable" class="w-100 buy-bar-parent" >
            <div class="hr mt-2"></div>
            <div class="buy-bar w-100" :style="{ 'margin-top': !simplified ? '10px' : '5px' }">
              <div class="my-auto ">
                <div class="d-flex" style="margin-top:2px;margin-left:-10px;margin-right:5px;">
                  <div v-if="watchlistLoading" v-tooltip="'Add to Watchlist'" class="item-icon-base" style="height: 30px; width: 30px; background-color: #091629; border-radius:50%; padding-left: 4px; padding-top:2px; margin-right: 4px;">
                    <div class="spinner-border text-white spinner-border-sm" style="font-size: 12px; margin-left: 3px;" role="status">
                    </div>
                  </div>
                  <div v-else-if="!checkItemInWatchlist()" v-tooltip="'Add to Watchlist'" class="item-icon-base" style="height: 30px; width: 30px; background-color: #091629; border-radius:50%; padding-left: 4px; padding-top:2px; margin-right: 4px;">
                    <icon-plus class="icon-custom" @click="addToWatchlist"/>
                  </div>
                  <div v-else v-tooltip="'Remove from Watchlist'" class="item-icon-base" style="height: 30px; width: 30px; background-color: #091629; border-radius:50%; padding-left: 4px; padding-top:2px; margin-right: 4px;">
                    <icon-minus class="icon-custom" @click="removeFromWatchlist"/>
                  </div>
                
                  <div v-tooltip="'View Similar'" class="item-icon-base" style="height: 30px; width: 30px; background-color: #091629; border-radius:50%; padding-left: 4px; padding-top:2px; margin-right: 4px;" @click="viewSimilar">
                    <icon-magnify class="icon-custom"/>
                  </div>
                  <div v-if="inspectLink" v-tooltip="'Inspect In-Game'" style="cursor: pointer !important;" @mouseenter="steam = true" @mouseleave="steam=false" @click="inspectItem()">
                    <font-awesome-layers style="font-size: 2rem; margin-top: -1px;">
                      <font-awesome-icon icon="circle" :style="{ color: steam ? '#1095aa' : '#545454' }" />
                      <font-awesome-icon :icon="[ 'fab', 'steam-symbol' ]" transform="shrink-6" :style="{ color: 'white' }" />
                    </font-awesome-layers>
                  </div>
                </div>
              </div>

              <div style="margin-right:-10px; width: 121px; padding-left: 0; padding-right: 0; text-align:center; background-color: ED6B33; height:35px;"
                v-if="!isInCart"
                class="btn btn-orange btn-xs item-button checkout-button"
                @click="addToCart"
                :disabled="addToCartIsDisabled"
              >Add to cart</div>
              <div
                style="margin-right:-10px; width: 121px; padding-left: 0; padding-right: 0; text-align:center; height:35px;"
                v-else
                class="btn btn-outline-dark btn-xs item-button checkout-button"
                disabled
              >Added!</div>
            </div>
          </div>

          <!-- MyInventory page (withdrawing items) -->
          <div v-if="withdrawable" class="w-100 buy-bar-parent" :style="{ 'margin-top': appId == 252490 ? '-12px' : '0px' }">
            <div class="hr mt-2"></div>
            <div class="buy-bar w-100">
              <div>
                <div style="text-align:center" :style="{ 'margin-left': simplified ? '15px' : '', 'margin-top': simplified ? '8px' : '' }">
                  <div
                    class="f1 watchlist"
                    style="margin-left:-10px; padding-top: 5px; padding-left: 8px;"
                    v-if="!isInSellCart"
                  >

                    <div v-if="unlistinglistingloader" class="spinner-border spinner-border-sm mt-1 ml-2" role="status">
                    </div>
                    <a v-else href="#0" style="font-family: Roboto;font-style: normal;font-weight: normal;font-size: 14px;color: #545454;" @click="relistItem">Relist</a></div>
                  <div
                    class="f1 watchlist"
                    style="margin-right:-10px;"
                    v-else
                  ><a href="#0" style="font-family: Roboto;font-style: normal;font-weight: normal;font-size: 10px;color: #545454;">Cancel Listing</a></div>
                </div>
              </div>

              <!-- TODO: Use slots -->
              <div style="margin-right:-10px; width: 121px; padding-left: 0; padding-right: 0; text-align:center; background-color: ED6B33; height:35px;"
                v-if="!selected"
                class="btn btn-primary btn-xs item-button checkout-button"
                @click="select"
              >SELECT</div>
              <div
                style="margin-right:-10px; width: 121px; padding-left: 0; padding-right: 0; text-align:center; height:35px; color: white;"
                v-else
                class="btn btn-cancel btn-xs item-button checkout-button"
                @click="deselect"
              >CANCEL</div>
            </div>
          </div>

          <!-- TODO: use slots -->
          <!-- MyListings page -->
          <div v-if="listingsPage" class="w-100 buy-bar-parent" style="padding-top: 6px;">
            <div class="hr mt-2"></div>
            <div class="buy-bar w-100">
              <div>
                <div style="text-align:center" :style="{ 'margin-left': simplified ? '15px' : '', 'margin-top': simplified ? '8px' : '' }">
                  <div
                    class="f1 watchlist"
                    style="margin-left:-10px; padding-top: 6px; padding-left: 5px;"
                    @click="removeFromListings"
                  >
                    <div v-if="unlistinglistingloader" class="spinner-border spinner-border-sm mt-1 ml-2" role="status">
                    </div>
                    <a v-else href="#0" style="font-family: Roboto;font-style: normal;font-weight: normal;font-size: 14px;color: #545454; padding-right: 20px;">Un-list</a>
                  </div>
                </div>
              </div>

              
              <div v-if="isSellLoading" class=" btn btn-primary "  style="margin-right:-10px; width: 150px; padding-left: 25px; padding-right: 0; text-align:center; background-color: ED6B33; height:37px; border-radius: 10px; line-height: 16px;">
                <div class="d-flex mx-auto ml-3">
                  <div class="spinner-border text-white spinner-border-sm" role="status">
                  </div>
                  <div class="my-auto ml-2 text-white">Loading...</div>
                </div>
              </div>
              <div v-else style="margin-right:-10px; width: 150px; padding-left: 0; padding-right: 0; text-align:center; background-color: ED6B33; height:37px; border-radius: 10px; line-height: 16px;"
                class="btn btn-primary btn-xs item-button checkout-button"
                @click="saveNewListingsPrice()"
              >Save</div>
            </div>
          </div>

          <div
            v-if="!editable && sellable"
            style="margin-right: -20px; margin-left: -20px;"
          >
            <div class="hr mt-2"></div>
            <div class="buy-bar" :style="{ 'margin-top': simplified ? '10px' : '5px' }">
              <div class="my-auto ">
                <div class="d-flex " style="margin-top:2px" v-if="!simplified">
                  <div v-tooltip="'View Similar'" class="item-icon-base" style="height: 30px; width: 30px; background-color: #4A4A4A; border-radius:50%; padding-left: 4px; padding-top:2px;" @click="viewSimilar">
                    <icon-magnify class="icon-custom"/>
                  </div>
                  <!-- <div v-tooltip="'View in Item Database'" class="item-icon-base" style="height: 30px; width: 30px; background-color: #4A4A4A; border-radius:50%; padding-left:4px; padding-top: 2px;transform: rotate(90deg); margin-left:10px;margin-right:10px;">
                    <icon-server class="icon-custom" />
                  </div> -->
                  <!-- <div class="item-icon-base" style="height: 30px; width: 30px; background-color: #4A4A4A; border-radius:50%; padding-top:2px;padding-left:4px;">
                    <icon-steam class="icon-custom" />
                  </div> -->
                  <div v-if="inspectLink" v-tooltip="'Inspect In-Game'" style="cursor: pointer !important;" @mouseenter="steam = true" @mouseleave="steam=false" @click="inspectItem()">
                    <font-awesome-layers style="font-size: 2rem; margin-top: -1px;">
                      <font-awesome-icon icon="circle" :style="{ color: steam ? '#ED6B33' : '#4A4A4A' }" />
                      <font-awesome-icon :icon="[ 'fab', 'steam-symbol' ]" transform="shrink-6" :style="{ color: 'white' }" />
                    </font-awesome-layers>
                  </div>
                </div>
              </div>
              <div v-if="sellable" class>
                
                <div
                  v-if="selected"
                  class="btn btn-primary btn-xs item-button"
                  style="width: 100px; font-size: 12px; letter-spacing:1.5px; font-family: 'Roboto';"
                  @click="deselect"
                >Deselect</div>
                <div
                  v-if="!selected"
                  class="btn btn-teal btn-xs item-button"
                  style="width: 100px; font-size: 12px; letter-spacing:1.5px; font-family: 'Roboto';"
                  @click="select"
                >SELL</div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <ItemPriceSetter ref="PriceSetter" @price="listPrice = $event; relistItem()" /> -->
  </div>
</template>


<script>
// TODO: Refactor list price to be float.
// TODO: Figure out a better way to map item onto the object and vice versa 
// TODO: Refactor most "helper" methods into a utils file.

import moment from "moment";
import { EventBus } from "../utils/eventBus.js";

import { getFloatRangesFromMinMaxWear } from "@/utils/parseItems.js"
// import ItemPriceSetter from "@/components/ItemPriceSetter";

import api from "@/api.js";

const validInputs = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."]
const numeral = require('numeral');
const paints = [
    {'image': require('@/assets/doppler_ruby.svg'), 'text': 'Ruby', 'value': 415, tooltip: "Ruby"},
    {'image': require('@/assets/doppler_sapphire.svg'), 'text': 'Sapphire', 'value': 416, tooltip: "Sapphire"},
    {'image': require('@/assets/doppler_blackpearl.svg'), 'text': 'Black Pearl', 'value': 417, tooltip: "Black Pearl" },
    {'image': require('@/assets/doppler_phase1.png'), 'text': 'Phase 1', 'value': 418, 'height': '60px', tooltip: "Phase 1"},
    {'image': require('@/assets/doppler_phase2.png'), 'text': 'Phase 2', 'value': 419, tooltip: "Phase 2"},
    {'image': require('@/assets/doppler_phase3.png'), 'text': 'Phase 3', 'value': 420, tooltip: "Phase 3" },
    {'image': require('@/assets/doppler_phase4.png'), 'text': 'Phase 4', 'value': 421, tooltip: "Phase 4"},
    {'image': require('@/assets/doppler_emerald.svg'), 'text': 'Emerald', 'value': 568, tooltip: "Emerald"},
    // {'image': require('@/assets/doppler_gamma_phase1.png'), 'text': 'Gamma Phase 1', 'value': 569},
    // {'image': require('@/assets/doppler_gamma_phase2.png'), 'text': 'Gamma Phase 2', 'value': 570},
    // {'image': require('@/assets/doppler_gamma_phase3.png'), 'text': 'Gamma Phase 3', 'value': 571},
    // {'image': require('@/assets/doppler_gamma_phase4.png'), 'text': 'Gamma Phase 4', 'value': 572},
]

let games = [ 
    { id: 730, name: 'csgo' }, 
    { id: 440, name: 'tf2' },
    { id: 570, name: "dota2" },
    { id: 252490, name: "rust" },
    { id: 433850, name: "z1br" }
];

export default {
  props: [
    "item",
    "purchasable",
    "sellable",
    "featured",
    "editable",
    "instantSellable",
    "compact",
    "withdrawable",
    "listingsPage",
    "overrideRelisting",
  ],
  components: {
    // ItemPriceSetter
  },
  data() {
    // this.item is a prop, we turn into data attributes.
    let item = this.item || {};

    // These need to go into parsing functions really.
    item.discount     = item.discount || null;
    item.wear         = item.wear || null;
    item.patternIndex = item.patternIndex || null;
    item.inspectLink  = item.inspectLink || null;
    item.stattrak     = item.stattrak || null;
    item.float        = item.float || null;
    item.stickers     = item.stickers || [];
    item.developer    = item.developer || false;
    item.lockedUntil  = item.lockedUntil || false;
    item.paintIndex   = item.paintIndex || false;
    item.floatRanges  = item.floatRanges || [];
    item.splatImageHeight = item.splatImageHeight || "";
    item.splatTooltip = item.splatTooltip || "";
    item.splatImage = item.splatImage || "";

    if (!item.listPrice) {
      item.listPrice = "0.00"
    }

    // TODO: Parse Souvenirs (maybe make text color yellow)
    if (this.simplified) { item.simplified = true; }
    let hasPaint = paints.find(x => item.paintIndex == x.value)
    if (hasPaint) {
      item.splatImage = hasPaint.image;
      item.splatImageHeight = hasPaint.height || "40px"
      item.splatTooltip = hasPaint.tooltip;
    } else {
      item.splatImage = null
    }


    return {
      ...item,
      humanTradableTime: null,
      addToCartIsDisabled: false,
      triedToInspect: false,
      
      steam: false,
      selected: false,
      isSellLoading: false,
      watchlistLoading: false,
      unlistinglistingloader: false,
      // isInCart: false,
      minPrice: 0,
      maxPrice: 0,
      avgPrice: 0,
      currencyTextColour: "#7BAA64"
    };
  },

  methods: {
    handlePriceChanged(newVal){
      if (numeral(newVal).value() == numeral(this.previousListPrice).value()){
        this.currencyTextColour = "#7BAA64";
        this.$emit("priceUnchanged", this.id);
      }
      else if (numeral(newVal).value() != numeral(this.previousListPrice).value()){
        this.currencyTextColour = "red";
        this.$emit("priceChanged", this.id);
      }
    },
    numeral,
    getProgressBarWidth(values){
      // What?
      if(values.length == 0){
        return "20%"
      }

      // Cap between 10-80
      let percentage = Math.max(10, Math.min((values[0]-values[1])*100), 90)
      return percentage+'%'
    },
    getProgressBarBorderRadius(float){
      let border = "0 0 0 0" // Default border
      switch(float){
        case "BS":
          border = "10px 0 0 10px";
          break;
        case "FN":
          border = "0 10px 10px 0"
          break;
      }
      
      return border
    },
    // getFloatRangeBackgroundColor(float, values){
    //   let color = "#000000"; // Error color
    //   if(values.length == 0){
    //     return "#939C9E" // Color to display that skin does not have that float available (e.g. asiimov only has FT, WW, BS)
    //   }
    //   switch(float){
    //     case "BS":
    //       color = "#cc5b5b";
    //       break;
    //     case "WW":
    //       color = "#e5724c"
    //       break;
    //     case "FT":
    //       color = "#e6ce62"
    //       break;
    //     case "MW":
    //       color = "#9ab88c"
    //       break;
    //     case "FN":
    //       color = "#85a377"
    //       break;
    //   }

    //   return color;
    // },
    getTf2BackdropClass(name){
      if (name.split(' ')[1] == "Unusual"){
        return "tf2-unusual-backdrop"
      }
      
      return "tf2-part-backdrop"
    },
    checkItemInWatchlist(){
      return this.$store.getters["watchlist/inWatchlist"](this.item);
    },
    openSellModal() {
      this.$refs.PriceSetter.openSellModal(this.$data);
    },
    async relistItem(){
      if (this.overrideRelisting) {
        return this.$emit('relist', this.id);
      }
      // 
      console.log("Re-listing item")
      this.unlistinglistingloader = true;
      this.closeSellModal()
      let params = {
        resourceId: this.id,
        changes: [{          
          key: "state",
          value: "LISTED",
          oldValue: "UN-LISTED"
        },{          
          key: "listPrice",
          value: this.listPrice
        }]
      }
      await this.$store.dispatch('items/updateListing', params)
      this.unlistinglistingloader = false;
      this.$emit('listingAdded', this.id);
    },
    setPrevious(){
      this.listPrice = this.previousListPrice;
    },
    reducePriceByOnePercent(){
      // We need to reduce price by a cent starting at 0.50$, otherwise the rounding won't work
      if (this.listPrice > 0.5){
        this.listPrice = numeral(0.99 * parseFloat(this.listPrice)).format('0.00');
      }
      else if (this.listPrice > 0.05 && this.listPrice <= 0.5){ // Still disallowing reducing past 5 cents
        this.listPrice = numeral(parseFloat(this.listPrice) - 0.01).format('0.00');
      }

      this.handlePriceChanged(this.listPrice);
    },
    async removeFromListings(){
      console.log("Removed item from listings")
      let params = {
        resourceId: this.id,
        changes: [{          
          key: "state",
          value: "UN-LISTED",
          oldValue: "LISTED"
        }]
      }
      this.unlistinglistingloader = true;
      await this.$store.dispatch('items/updateListing', params)
      this.$emit('listingRemoved', this.id);
      this.unlistinglistingloader = false;
    },
    async saveNewListingsPrice(){
      if (this.listPrice !== this.previousListPrice){ // If the price has changed
        let params = {
          resourceId: this.id,
          changes: [{          
            key: "listPrice",
            value: this.listPrice,
            oldValue: this.previousListPrice
          }]
        }
        try{
          this.isSellLoading = true;  
          let item = await this.$store.dispatch('items/updateListing', params)
          await this.$emit('listingChanged', item);
          this.previousListPrice = this.listPrice; // TODO: Listen to an event and update this instead
          console.log("Updated item price");
          this.handlePriceChanged(this.listPrice);
        } catch (e) {
          if (e.message == "Concurrency issues. Refresh page."){
            alert("There was an error saving your item. Please refresh the page.")
          }
        } finally {
          this.isSellLoading = false;  
        }
      }
    },
    getValidString(str){
      // What does this do?
      str = str + ''
      let dotCount = 0;

      let validString = ""
      for (let i in str){
        let char = str[i];
        if (char == "."){
          dotCount ++;
        }
        if(validInputs.indexOf(char) > -1 && dotCount <= 1){
          validString += char
        }
      }
      return validString
    },
    async addToCart() {
      await this.$store.dispatch("cart/addItem", this.$data);
      this.addToCartIsDisabled = true;
      // this.isInCart = true;
    },
    async addToWatchlist() {
      this.watchlistLoading = true;
      await this.$store.dispatch("watchlist/addItem", this.$data);
      this.watchlistLoading = false;
    },
    checkOut() {
      this.$router.push("/cart");
    },
    async removeFromWatchlist(){
      this.watchlistLoading = true;
      await this.$store.dispatch("watchlist/removeItem", this.$data);
      this.watchlistLoading = false;
    },
    select() {
      this.selected = true; // Some reason this isnt triggering reactivity
      this.$emit("selected", this.item.id);
      this.$forceUpdate();
    },
    deselect() {
      // this.$set(this, 'selected', true);
      this.$emit("deselected", this.item.id);
      this.selected = false;
      this.$forceUpdate();
    },
    edit() {},
    parseStickerScrape(wear){
      if(wear === null){
        return "";
      }

      wear = Math.ceil(wear*10)*10;

      if (isNaN(wear)) {
        return ""; // Avoid things like NaN % scraped
      }

      return " (" + wear + "% scraped)";
    },
    genRand(min, max, decimalPlaces) { // Sigh 
      var rand = Math.random() < 0.5 ? ((1-Math.random()) * (max-min) + min) : (Math.random() * (max-min) + min);  // could be min or max or anything in between
      var power = Math.pow(10, decimalPlaces);
      return Math.floor(rand*power) / power;
    },
    inspectItem(){
      //window.open(this.inspectLink, "_blank")
      window.location.href = this.inspectLink;
    },
    getProgressBarWidth(values){
      // console.log(values)
      return (values[0]-values[1])*100+'%'
    },
    getProgressBarBorderRadius(float){
      let border = "0 0 0 0" // Default border
      switch(float){
        case "BS":
          border = "10px 0 0 10px";
          break;
        case "FN":
          border = "0 10px 10px 0"
          break;
      }

      return border
    },
    getFloatRangeBackgroundColor(wearName){
      console.log(wearName)
      let color = "#000000"; // Error color
      switch(wearName.substring(0,2)){
        case "BS":
          color = "#cc5b5b";
          break;
        case "WW":
          color = "#e5724c"
          break;
        case "FT":
          color = "#e6ce62"
          break;
        case "MW":
          color = "#9ab88c"
          break;
        case "FN":
          color = "#85a377"
          break;
      }

      return color;
    },
    viewSimilar() {
      let gamePath = `${games.find(x => x.id == this.appId).name}`;

                        
      this.$store.dispatch("filters/updateFilter", { filter: "market_hash_name", value: this.market_hash_name })

      if (!this.$route.matched.some( ({ name }) => name === gamePath )) {
        let routeData = this.$router.resolve({path: `/${gamePath}`, query: {
          itemId: this.id,
          market_hash_name: this.market_hash_name
        }});
        console.log("Wtf")
        window.open(routeData.href, '_blank');
      }
    },
    async inspectMe() {
      if (this.triedToInspect) return;
      this.triedToInspect = true;
      console.log(`Inspecting ${this.id}`)
      let inspectLink = this.inspectLink
      if (!inspectLink) return;
      if (this.float) return;

      //TODO: We only need to do this if it came from the sell page!
      // debugger;
      inspectLink = inspectLink.replace("%owner_steamid%", this.user.steamData.steamid);
      inspectLink = inspectLink.replace("%assetid%", this.item.assetId);
      console.log(`Sent inspect request for ${this.id}`)
      try {
        //console.log(inspectLink, this.item)
        let inspectedItem = (await api.inspectItem({
          inspectLink,
          appid: this.item.appid,
          icon_url: this.item.image,
          market_hash_name: this.item.market_hash_name
        })).data;

        let steamInspected = inspectedItem.inspected;
        let customAttributes = inspectedItem.customAttributes;

        // Set float
        this.float = steamInspected.paintwear;

        // Set marketPrice
        this.marketPrice = customAttributes.marketPrice;
        
        console.log("Got inspected item");
        delete steamInspected.stickers; // TODO: fix inspect api stickers
        for (let key of Object.keys(steamInspected)) {
          this[key] = steamInspected[key];
        }

        let floatRanges = getFloatRangesFromMinMaxWear(this.minWear || 0, this.maxWear || 1);
        // Resetting float ranges
        if (this.floatRanges.length > 0) {this.floatRanges = [];}
        for (let range of floatRanges) {
          this.floatRanges.push(range);
        }

        // debugger;
        this.$forceUpdate();
      } catch (e) {
        return setTimeout(() => { this.inspectMe() }, 1000 + (Math.random() * 1500) );
      }
    }
  },
  async mounted(){
    console.log(this.$route.name);
    console.log(this.$route.path);
    this.listPrice = this.getValidString(this.listPrice);
    // Listening to and handling "select All" events from MyInventory page
    EventBus.$on('selectedAllItemsInventory', () => {
      this.selected = true;
    })
    EventBus.$on('deselectedAllItemsInventory', () => {
      this.selected = false;
    });

    //console.log(`Route name: ${this.$route.name}`)

    if (this.$route.name == "Sell") {
      if (this.inspectLink) {
        this.inspectLink = this.inspectLink.replace("%owner_steamid%", this.user.steamData.steamid);
        this.inspectLink = this.inspectLink.replace("%assetid%", this.assetId);
      }
      this.inspectMe(); // Move to mounted maybe?
    }

    if (this.$route.name == "Sell" || this.type == "COMMODITY") {
      let commodityResponse = await api.get(`/CommodityStats`, { params: { market_hash_name: this.market_hash_name }});

      if (this.$route.name == "Sell") {
        // this.quantity = 0; // we calculate this out of the component
      } else {
        this.quantity = commodityResponse.data.qty;
      }

      this.minPrice = commodityResponse.data.minPrice;
      this.maxPrice = commodityResponse.data.maxPrice;
      this.avgPrice = commodityResponse.data.avgPrice;

      this.item.minPrice = commodityResponse.data.minPrice;
      this.item.maxPrice = commodityResponse.data.maxPrice;
      this.item.avgPrice = commodityResponse.data.avgPrice;

      if (parseFloat(this.listPrice) == 0 && this.minPrice) {
        this.listPrice = this.minPrice + "";
        this.item.listPrice = this.listPrice;
      }
    }

    if (this.$store.getters["cart/inCart"](this.$data)) {
      // this.isInCart = true;
    }

    // Un-setting simplified on MyListings page
    if (this.$route.name == "MyListings"){
      this.simplified = false;
    }
    if (this.$route.name == "MyInventory"){
      this.simplified = false;
    }

  },
  async destroyed() {
    this.$emit("itemDestroyed", this.id);
  },
  computed: {
    urlname() {
      let formattedName = this.market_hash_name.replace(/(:|\||\(|\))+/gmi, '').replace(/\s\s+/g, ' ').trim().replace(/ /gmi, '-').replace('™', '').replace('#', '');
      let result = '/i/' + (formattedName)// + "?purchasable=1"; // this brok the item db page
      if (this.$route.name !== "Sell") {
        result += '?itemId=' + this.id;
      }
      return result;
    },
    cappedStickers() {
      if (this.stickers.length > 4) {
        let cappedStickers = this.stickers.slice(0, 4);
        let remainingStickerNames = this.stickers.slice(4, this.stickers.length).map(x => x.name);

        cappedStickers.push({
          name: remainingStickerNames.join("\n"),
          image: require("@/assets/more_icon.png")
        })
        return cappedStickers;
      }

      return this.stickers;
    },
    niceMarketHashName() {
      return this.market_hash_name 
        .replace("(Field-Tested)", '')
        .replace("(Minimal Wear)", '')
        .replace("(Battle-Scarred)", '')
        .replace("(Battle Scarred)", '')
        .replace("(Well-Worn)", '')
        .replace("(Factory New)", '')
        .replace("Souvenir", '')
        .replace("★ StatTrak™", '')
        .replace("★", '')
        .replace("StatTrak™", '')
        .replace(this.rarity.name, '')
        .replace("", '')
        .trim()
    },
    net() {
      let tax = this.tax;
      let listPrice = parseFloat(this.listPrice.replace('$',''));

      if (isNaN(listPrice)) {
        return 0;
      }

      if (!tax) {
        if (listPrice > 30) tax = 1.06
        else tax = 1.08
      }

      return listPrice * tax
    },
    isInCart() {
      return this.$store.getters['cart/inCart'](this.$data)
    },
    itemStyle() {
      return { 
        // "height":"200px",
        // "width": "auto", 
        // "position": "absolute", 
        // border: "3px solid black",
        // 'box-shadow': `0px 0px 5px 0px #${this.rarity.color} !important`,
        // "-webkit-filter": `drop-shadow(20px 20px 0 red)
        //         drop-shadow(-20px 20px 0 red)
        //         drop-shadow(20px -20px 0 red)
        //         drop-shadow(-20px -20px 0 red) !important;`,

        // "filter": `drop-shadow(2px 2px 0px black)
        //         drop-shadow(-2px 2px 0px black)
        //         drop-shadow(2px -2px 0px black)
        //         drop-shadow(-2px -2px 0px black) !important`
        // "filter": `drop-shadow(0 0 4px #${this.rarity.color})`
      }
    },
    user() {
      return this.$store.getters["auth/user"];
    },
    isTradable() {
      if (this.$route.name == "Sell") {
        return true;
      }

      let isTradable = true;

      let lockedUntil;
      if (this.lockedUntil !== false){
        lockedUntil = moment(this.lockedUntil)
      } else if (this.appId !== 730 && this.appId !== 440 && this.appId !== 570) {
        this.humanTradableTime = "Tradable Now";
        return true;
      }  else{
        return null; // Error-locked
      } 

      if (lockedUntil.isValid()) {
        if (lockedUntil.isBefore()) {
          isTradable = true;
        } else {
          isTradable = false;
        }
      } else {
        isTradable = null;
      }

      if (this.developer) isTradable = true;
      
      if (!isTradable) {
        this.humanTradableTime = lockedUntil.isValid() ? "Tradable " + lockedUntil.fromNow() : "Not Tradable";
      }

      return isTradable;

      // Non-CSGO/TF2/Dota2: Always tradable
      // CSGO/TF2/Dota2 Error: Orange icon
      // CSGO/TF2/Dota2 Locked: red icon + Tradable in + date
      // CSGO/TF2/Dota2 Tradable: Tradable Now + green icon 
    },
    isInWatchlist() {
      return this.$store.getters["watchlist/inWatchlist"](this.$data);
    },
    isInSellCart(){
      return false;
    },
    itemBackground(){
      switch(this.appId){
        case 440:
          return 'tf2';
          break;
        case 730:
          return 'csgo';
          break;
        case 570:
          return 'dota2';
          break;
        case 252490:
          return 'rust';
          break;
        case 433850:
          return 'z1br';
          break;
        default:
          return 'csgo';
          break;
      }
    }
  }
}
</script>

<style lang="scss" >
@import "@/scss/item.scss";


</style>