<template>
  <div class="d-flex my-auto" v-on-clickaway="hideDropdown">
    <div class="d-flex v-select">
      <button type="button" class="v-select-toggle" @click="toggleDropdown">
        <div class="v-title">{{ selectedSort.text }}</div>
      </button>

      <div class="ml-2 toggle-icon" @click="toggleDirection">
        <font-awesome-layers class="fa-lg" style="position: relative;top: -6px;right: 6px;">
          <font-awesome-icon icon="caret-down" style="padding-top: 7px; cursor: pointer;" :style="{color: selectedDirection=='desc' ? '#ED6B33' : '#B0B0B0'}"/>
          <font-awesome-icon icon="caret-up" style="padding-bottom: 7px; cursor: pointer;" :style="{color: selectedDirection=='asc' ? '#ED6B33' : '#B0B0B0'}"/>
        </font-awesome-layers>
      </div>
    </div>

    <div v-show="showDropdown" class="v-dropdown-container">
      <vue-custom-scrollbar tagname="div">
        <ul>
            <li
                v-for="(option, index) in options"
                :key="`v-select-${index}`"
                class="v-dropdown-item"
                :class="{'selected' : isSelectedOption(option)}"
                :style="{ 'color': option.color }"
                @click="selectSort(option)"
                >{{ option.text }}</li>
        </ul>
      </vue-custom-scrollbar>
    </div>
  </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import { mixin as clickaway } from "vue-clickaway";

export default {
  props: {
    options: Array, // value, text, order
  },
  mixins: [ clickaway ], 
  data() {
    console.log(`Options`);
    console.log(this.options);
    return {
      selectedSort: this.options[0],
      selectedDirection: "", // desc
      directionAvailable: false,

      showDropdown: false,
    };
  },
  components: {
    vueCustomScrollbar,
  },
  methods: {
    reset(){
      this.selectedSort = this.options[0];
      this.selectedDirection = "";
    },

    emit() {
      this.$emit('input', {
        value: this.selectedSort.value + this.selectedDirection,
        text: this.selectedSort.text,
      });
    },

    isSelectedOption(option) {
      return this.selectedDirection.value == option.value;
    },
    selectSort(option) {
      this.selectedSort = option;
      if (this.selectedSort.order) {
        this.directionAvailable = true; 
        this.selectedDirection = "asc";
      } else { 
        this.directionAvailable = false;
        this.selectedDirection = "";
      }
      this.showDropdown = false;
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    toggleDirection() {
      if (!this.directionAvailable) return;
      if (!this.selectedDirection) return this.selectedDirection = "desc";
      if (this.selectedDirection == "asc") return this.selectedDirection = "desc";
      if (this.selectedDirection == "desc") return this.selectedDirection = "asc";
    },
    hideDropdown() {
        this.showDropdown = false;
    },
  },
  watch: {
    selectedSort() {
      this.emit();
    },
    selectedDirection() {
      this.emit();
    },
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/brand.scss';

  .v-dropdown-container {
    ul {
      font-size: 14px;
      color: #424242;
      text-align: left;
      list-style: none;
      background-color: #fff;
      background-clip: padding-box;
      padding: 0px;
      margin: 0px 0px 0px 0px;
      max-height: 200px;

      li {
        
      }
    }

  }
  .v-select {    
    position: relative;
    height: 30px;
    cursor: pointer;
  
      &.disabled {
          cursor: not-allowed;
  
          .v-select-toggle {
              background-color: #f8f9fa;
              border-color: #f8f9fa;
              opacity: 0.65;
              cursor: not-allowed;
  
              &:focus {
                  outline: 0 !important;
              }
          }
      }
  
      text-overflow: ellipsis;
  }

  .v-select-toggle {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      user-select: none;
      padding: 0.375rem 0.75rem; 
      text-align: left;
      white-space: nowrap;
      transition: background-color, border-color, box-shadow, 0.15s ease-in-out;
      cursor: pointer;
  
      &:hover {
          background-color: #e2e6ea;
          border-color: #dae0e5;
      }
  
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: $color-teal;
  
      outline: none;
      border-radius: 15px;
      padding-top: 3px;
      padding-bottom: 3px;
      border: 1px solid #465b7a;
      background-color: #fff;
  
      &:focus {
          outline: none;
          border: 1px solid $primary;
          // outline: 1px solid $primary;
          box-shadow: 0 0 1pt 1pt $primary;
      }
  
      text-overflow: ellipsis;
  }
  
  .v-dropdown-container {
    top: 35px;
      position: absolute;
      background: #fff;
      padding: 0.5rem 0;
      margin: 10px 0 0 0px;
      color: #212529;
      text-align: left;
      list-style: none;
      background-clip: padding-box;
      z-index: 1000;
  
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #1E2021;

      border: 1px solid #E8E8E8;
      box-shadow: 0px 0px 10px rgba(30, 32, 33, 0.15);
      border-radius: 4px;
  }
  
  .v-dropdown-item {
  
      cursor: pointer;
      text-decoration: none;
      line-height: 32px;
      padding: 0px 10px 0px 10px;
      user-select: none;
  
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: $color-primary;
  
      &:hover:not(.default-option) {
          // background-color: #f8f9fa;
          color: $primary;
      }
  
      &.disabled {
          color: #9a9b9b;
      }
  
      &.selected {
          // background-color: #007bff;
          color: $primary;
  
  
          &:hover {
          // background-color: #007bff;
          color: $primary;
          }
      }
  
      &.disabled {
          cursor: not-allowed;
  
          &:hover {
          background-color: #fff;
          }
      }
  }
  
  .toggle-icon {
      background-color: rgba(237, 107, 51, 0.1); 
      min-width: 28px !important; 
      width: 28px !important; 
      min-height: 28px !important; 
      height: 28px !important; 
      border-radius:50%;
  
      padding: 8px;
  
      img {
          display: block;
          margin: auto auto;
      }
  }
  
  .v-title {
      text-overflow: ellipsis;
      overflow: hidden;
      color: $color-primary-darker;
      max-width: 70px;

  }
  </style>