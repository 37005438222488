<template>
    <div @mouseleave="goodbye">
        <div class="nav-item item-search input-group">
            
            <span class="item-search-icon" style="z-index:10200; margin-top:-2px; margin-left:5px;">
                <font-awesome-icon icon="search" />
            </span>

            <input
                class="form-control main-search-bar"
                type="text"
                placeholder="Search for an item..."
                style="width:350px; padding-left:40px;"
                v-model="searchQuery"
                :style="searchStyle"
                @focus="hello"
                @click="hello"
                @keypress.enter="searchItem"
            />

            <div
                class="dropdown-menu"
                aria-labelledby="searchDropdown"
                :class="{ 'show': isSearchOpen }"
                style="margin-top:0px;width:100%; border-top: none; padding-bottom: 0px;z-index:2000; border-top-left-radius:0; border-top-right-radius:0"
            >
                <div v-if="searchQuery">
                    <div v-for="item in searchResults" v-bind:key="item.id">
                        <div class="d-flex justify-content-between p-2 search-item" style="height: 50px;" @click="goToSearchResult(item)">
                            <div class="d-flex">
                                <div class="search-avatar">
                                    <img
                                    class="rounded"
                                    v-if="item.image"
                                    :src=" ('https://steamcommunity-a.akamaihd.net/economy/image/'+item.image+'/128fx128fdpx2x')"
                                    style="height:60px; margin-top: -10px"
                                    />
                                </div>
                                <div class="ml-3 search-title">
                                    <div
                                        class="search-item-name"
                                        style="font-family: Roboto; font-style: normal; font-weight: 900; font-size: 14px; color: #545454;"
                                    >{{item.name}}</div>

                                    <div style="margin-top:-10px;">
                                    <span v-if="item.stattrak"
                                        style="font-family: Roboto; font-style: normal; font-weight: 500; font-size: 10px; color: #C46C4F;"
                                    >StatTrak™</span>
                                    <span
                                        style="font-family: Roboto; font-style: normal; font-weight: 500; font-size: 10px; color: #7BAA64;"
                                    >{{item.wear}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="search-status">
                                <div
                                    class="item-rarity"
                                    style="margin-top:10px;font-family: Roboto; font-style: normal; font-weight: bold; font-size: 10px; color: #545454; text-align:bottom;"
                                >
                                    <svg height="10" width="10">
                                    <circle
                                        cx="5"
                                        cy="5"
                                        r="4"
                                        stroke="black"
                                        stroke-width="1"
                                        :fill="'#' + item.rarity.color"
                                    />
                                    </svg>
                                    {{item.rarity.name}}
                                </div>
                            </div>
                        </div>
                        <hr style="margin: 0; background-color: #black; height:1px" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
var stringSimilarity = require('string-similarity');
import moment from 'moment';
import * as mock from '../../mock';
import numeral from "numeral";
import api from "@/api.js";
import parseItems from "@/utils/parseItems";

let games = [ 
    { id: 730, name: 'csgo' }, 
    { id: 440, name: 'tf2' },
    { id: 570, name: "dota2" },
    { id: 252490, name: "rust" },
    { id: 433850, name: "z1br" }
];

export default {
    data() {
        return {
            searchQuery: this.$route.query.query,
            searchResults: [],
            isSearchOpen: false,
            searchStyle: {},
            ignoreNextClick: false,
            mostSearched: mock.item(),
        }
    },
    mounted() {
        if (this.$route.query.query) {
            this.searchQuery = this.$route.query.query
            this.isSearchOpen = false;
        }
        this.isSearchOpen = false;
    },
    components: {},
    computed: {},
    methods: {
        hello() {
            this.ignoreNextClick = true;
            this.isSearchOpen = this.searchQuery != "";
        },
        goodbye() {
            this.isSearchOpen = false;
        },
        searchItem() {
            console.log(`Searching for item: ${this.searchQuery}`);
            this.isSearchOpen = false;

            // this.$store.dispatch('filters/setFilterLoadedStatus', false);

            if (this.$route.name != 'search') {
                this.$router.push({ 
                    path: '/search',
                    query: {
                        query: this.searchQuery,
                    }
                })
            } else {
                console.log("Replaced query")
                this.$router.replace({
                    query: {
                        query: this.searchQuery
                    }
                })
            }

            console.log("Updated query filter");
            this.$store.dispatch('filters/updateFilter', {
                filter: "query",
                value: this.searchQuery,
            });

            console.log("Marking filters loaded")
            // this.$store.dispatch('filters/setFilterLoadedStatus', true);

        },
        goToSearchResult(item){
            item = item || {};
            console.log("Going to Advanced Search")
            // Route to /csgo and open advanced search (how?)
            let path = `/${games.find(x => x.id == item.appId).name}`;
            
            this.$router.push({ 
                path: path,
                query: {
                    query: this.searchQuery,
                    itemId: item.id,
                    market_hash_name: item.market_hash_name
                }
            }).catch((err) => {
                if (err.name != "NavigationDuplicated") { // Let's avoid catching everything (just in case)
                    throw err;
                }
                else {
                    this.$router.go(); // refresh (same thing as going to the same link)
                }
            })
        },
    },
    watch: {
        async searchQuery(newVal) {
            this.isSearchOpen = newVal != "";
            let response = await api.getItems({ query: this.searchQuery, maxItems: 5, distinct: true, excludeOwned: true, });
            let items = await parseItems(response.data);
            let uniqueItems = items.filter((v,i,a) => a.findIndex(t => t.market_hash_name === v.market_hash_name) === i)

            this.searchResults = uniqueItems;
        },
        isSearchOpen(newVal, oldVal) {
            if (newVal == true) {
                this.searchStyle = {
                    "outline": "none",
                    "border-bottom": "0",
                    "border-bottom-left-radius": "0px",
                    "border-bottom-right-radius": "0px",
                    "border-top-right-radius": "15px",
                    "border-top-left-radius": "15px",
                    "z-index": 10100,
                };
            } else {
                this.searchStyle = {
                    "z-index": 1
                };
            }
        }
    }
}
</script>
