import Vue from 'vue';
import Swal from "sweetalert2";

import api from "../api";

const stepBySteps = {
    itemDeposit: {
        title: "Item Deposit",
        type: "itemDeposit",
        bottomRight: true,
        tradeoffer: null,
        height: "300px",
        width: "300px",
        steps: [
            { "title": "Preparing trade offer", },
            { "title": "We're sending you an offer", },
            { "title": "Accepted", },
        ]
    },
    itemWithdrawal: {
        title: "Item Withdrawal",
        type: "itemWithdrawal",
        bottomRight: true,
        width: "500px",
        steps: [
            { title: "Withdraw initiated" },
            { title: "Offer sent" },
            { title: "Trade accepted" },
        ],
        tradeoffers: {},
    }
}

import $ from "jquery";

export default {
    namespaced: true,
    state: {
        error: null,
        numberOfSteps: 4,
        currentStep: -1,
        completedStep: 0,
        stepByStep: JSON.parse(JSON.stringify(stepBySteps.itemDeposit)),
        isSteamIssues: null,
    },
    getters: {
        stepByStep: (state) => state.stepByStep,
        numberOfSteps: (state) => state.numberOfSteps,
        currentStep: (state) => state.currentStep,
        completedStep: (state) => state.completedStep,
        error: (state) => state.error,
        isActive: (state) => state.currentStep > 0,
        isSteamIssues: (state) => state.isSteamIssues,
    },
    mutations: {
        setError: (state, error) => {
            console.log("Setting new error");
            Vue.set(state, 'error', error);
            // Swal.fire({
            //     title: "Error",
            //     html: error.message,
            //     icon: "error"
            // })
        },
        startStepByStep: (state, data) => {
            Vue.set(state.stepByStep, 'tradeoffers', {})
            Vue.set(state, 'stepByStep', data);
            Vue.set(state, 'currentStep', 1);
            Vue.set(state, 'completedStep', 0);
            Vue.set(state, 'numberOfSteps', data.steps.length);
            Vue.set(state, 'error', null);
        },
        stopStepByStep: (state, data) => {
            Vue.set(state, 'currentStep', -1);
            Vue.set(state, 'error', null);
            Vue.set(state.stepByStep, 'tradeoffers', {})
            
        },
        addData: (state, data) => {
            let currentStepByStep = state.stepByStep;
            Vue.set(state, 'stepByStep', { ...currentStepByStep, ...data })
        },
        completeStep: (state, data) => {
            let completedStep = state.currentStep;

            if (data) {
                if (data.step) {
                    // They want to go to a specific step.
                    completedStep = data.step;
                }
            }
            let nextStep = completedStep + 1;

            if (state.completedStep < completedStep) {
                Vue.set(state, 'completedStep', completedStep);
            }

            Vue.set(state, 'currentStep', nextStep);
            let currentStepByStep = state.stepByStep;
            Vue.set(state, 'stepByStep', { ...currentStepByStep, ...data })
        },
        updateTradeoffers(state, tradeofferData) {
            for (let tradeoffer of tradeofferData) {
                Vue.set(state.stepByStep.tradeoffers, tradeoffer.id, tradeoffer)
            }
        },

        steamStatus: (state, status) => {
            state.isSteamIssues = status;
        }
    },
    actions: {
        startDeposit({ commit, state, dispatch }, details) {
            commit('stopStepByStep')
            let data = { ...stepBySteps['itemDeposit'], ...details };
            commit("startStepByStep", data),
            // Open it
            setTimeout(() => {
                $('#stepByStep').modal('show');
            }, 1)

            // Check for steam issues
            dispatch('checkSteamIssues');
        },
        startWithdrawal({ commit, state, dispatch }, details) {
            commit('stopStepByStep')
            let data = { ...stepBySteps['itemWithdrawal'], ...details };
            commit("startStepByStep", data),
            // Open it
            setTimeout(() => {
                $('#stepByStep').modal('show');
            }, 1);

            // check for steam issues
            dispatch('checkSteamIssues');
        },

        async checkSteamIssues({ commit, state }) {
            let { data: { steamCommunity }} = await api.get('/steam-status');
            
            commit('steamStatus', steamCommunity != "normal");
        },
        clear({ commit, state }) {
            commit('stopStepByStep')
        }
    }
}