import '@babel/polyfill'
import 'mutationobserver-shim'
// Import vue
if (process.env.NODE_ENV == "production") {
    if (!console) {
        console = window.console;
    }
    if (console.clear) console.clear();
    console.log("Guard.GG is always looking for talent. Send us your resume info@guard.gg!");
    console.log(
        "%cStop!",
        "color:red;font-family:system-ui;font-size:4rem;-webkit-text-stroke: 1px black;font-weight:bold"
    );
    console.log("%cDo not paste anything here!!", "font-size:20px; color: black;");
    console.log("%cPasting anything here can result in skins and money being stolen!", "font-size:20px; color: black;");

    // TODO: When going into production remove this if.
    if (!localStorage.getItem('debuggerman')) {
        var console = {};
        console.log = function() {};
        console.info = function() {};
        console.warn = function() {};
        console.error = function() {};
        window.console = console;
    }
}
import Vue from 'vue'
import './plugins/bootstrap-vue'
import router from './router'
import VTooltip from 'v-tooltip'

// Import error handlings

// import * as Sentry from '@sentry/browser';
// import * as Integrations from '@sentry/integrations';
import VueAppInsights from "vue-app-insights";

let alreadyShownFeedback = false;
if (process.env.NODE_ENV == "production") {
    // Sentry.init({
    //     dsn: 'https://d6723bb17c254fb9806a5bfe904d2b0d@sentry.io/2304115',
    //     integrations: [new Integrations.Vue({Vue, attachProps: true})],
    //     logErrors: true,
    //     beforeSend(event, hint) {
    //         // Check if it is an exception, and if so, show the report dialog
    //         if (event.exception && !alreadyShownFeedback) {
    //             console.log("Showing feedback form");
    //             Sentry.showReportDialog({ eventId: event.event_id });
    //             alreadyShownFeedback = true;
    //         }
    //         return event;
    //     }
    // });

    Vue.use(VueAppInsights, {
        id: "815e8c1b-2a8a-492f-ba8d-62f0771e9c85",
        router
    });

    // dwolla.configure('prod');
} else {
}

// Testing
dwolla.configure('sandbox');

import App from './App.vue'
import store from './store'

// Import major styles / scripts
// Font awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faSteamSymbol } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
library.add(fas);
library.add(faFacebookF);
library.add(faTwitter);
library.add(faSteamSymbol);
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-icon', FontAwesomeIcon);

// Material icons
//https://materialdesignicons.com/
import 'vue-material-design-icons/styles.css';
import CameraWireless from 'vue-material-design-icons/CameraWireless.vue';
Vue.component('camera-wireless', CameraWireless);
// import Search from 'vue-material-design-icons/Search.vue';
// Vue.component('search', Search);
import Video from 'vue-material-design-icons/Video.vue';
Vue.component('video-cam', Video);
import Magnify from 'vue-material-design-icons/Magnify.vue';
Vue.component('icon-magnify', Magnify);
import Email from 'vue-material-design-icons/EmailOutline.vue';
Vue.component('icon-email', Email);
import Mobile from 'vue-material-design-icons/Cellphone.vue';
Vue.component('icon-mobile', Mobile);
import Server from 'vue-material-design-icons/Server.vue';
Vue.component('icon-server', Server);
import Steam from 'vue-material-design-icons/Steam.vue';
Vue.component('icon-steam', Steam);
import Plus from 'vue-material-design-icons/Plus.vue';
Vue.component('icon-plus', Plus);
import Minus from 'vue-material-design-icons/Minus.vue';
Vue.component('icon-minus', Minus);
import ContentCopy from 'vue-material-design-icons/ContentCopy.vue';
Vue.component('icon-contentcopy', ContentCopy);
import QRCode from 'vue-material-design-icons/QrcodeScan';
Vue.component('icon-qrcodescan', QRCode);
import OpenInNew from 'vue-material-design-icons/OpenInNew.vue';
Vue.component('icon-openinnew', OpenInNew);

import VueObserveVisibility from 'vue-observe-visibility';
Vue.use(VueObserveVisibility);

import AsyncComputed from 'vue-async-computed';
Vue.use(AsyncComputed);

import Item from '@/components/Item';
Vue.component('Item', Item)
import Footer from '@/components/Footer';
Vue.component('Footer', Footer)
import 'jquery';
import 'jquery-ui';
import '@/scss/guard.scss';
import '../node_modules/animate.css/animate.min.css';

// TESTING
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import 'vue2-animate/dist/vue2-animate.min.css';

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import InfiniteLoading from 'vue-infinite-loading';
Vue.use(InfiniteLoading, {
  slots: {
    // keep default styles
    noResults: '',

    // remove default styles
    noMore: '',

    // hide slot
    error: {
      render: h => h('div'),
    },
  },
});

import CurrencyInput from "@/components/CurrencyInput.vue";
Vue.component('currency-input', CurrencyInput);


import VueResizeText from 'vue-resize-text'; 
Vue.use(VueResizeText);


// Vue.use(require('vue-moment'), {
//   moment: require('moment-timezone')
// });

Vue.use(VTooltip)
window.$ = window.jQuery = require('jquery')

import ImageFallback from 'vue-image-fallback';
Vue.use(ImageFallback);

// Vue.config.devtools = true
// Vue.config.productionTip = false
Vue.prototype.$http = require('./api');

import Swal from 'sweetalert2';
Vue.mixin({
    swal: Swal,
});

const vm = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

window.vm = global.vm = vm; //Define you app variable globally

const GUARDGG_VERSION = "1.0.3"; // TODO: Get this from package.json somehow safely?
if (localStorage.getItem('version') != GUARDGG_VERSION) {
    // localStorage.clear();
    // sessionStorage.clear();
    localStorage.setItem('version', GUARDGG_VERSION);
    window.location.reload();
}

import Toasted from 'vue-toasted';
Vue.use(Toasted, {
    text: "X",
    position: "top-right",
    keepOnHover: true,

})