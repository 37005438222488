import NotFound from '../views/404.vue';
import GridView from '../views/GridView.vue';

// TODO: Make dynamic from an API?
let gameRoutes = [
    {
      path: '/store',
      name: 'store',
      component: GridView,
      meta: { game: "general" }
    },    
    {
      path: '/search',
      name: 'search',
      component: GridView,
      meta: { }
    },
    {
      path: '/csgo',
      name: 'csgo',
      component: GridView,
      meta: { game: "csgo", appId: 730 }
    },
    {
      path: '/dota2',
      name: 'dota2',
      component: GridView,
      meta: { game: "dota2", appId: 570 }
    },
    {
      path: '/tf2',
      name: 'tf2',
      component: GridView,
      meta: { game: "tf2", appId: 440 }
    },
  {
      path: '/rust',
      name: 'rust',
      component: GridView,
      meta: { game: "rust", appId: 252490 }
    },
    {
      path: '/z1br',
      name: 'z1br',
      component: GridView,
      meta: { game: "z1br", appId: 433850 }
    },
]

export default gameRoutes;