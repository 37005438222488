<template>
    <div>
        <div class="modal fade in" id="stepByStep" tabindex="-1" role="dialog" aria-labelledby="stepByStepLabel" aria-hidden="true" data-backdrop="false" data-focus="false" data-keyboard="false" :class="{ 'modal-bottom-right': stepByStep.bottomRight }">
            <div class="modal-dialog" role="document" :class="{ 'modal-dialog-right': stepByStep.bottomRight }" >
                <div class="modal-content " >
                    <div class="modal-header">
                        <h5 class="modal-title text-navy" id="stepByStepLabel">{{ stepByStep.title }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">
                    <div class="d-flex w-100"  :style="{ 'min-height': stepByStep.height, 'height': stepByStep.height, 'max-height': stepByStep.height, 'min-width': stepByStep.width, 'width': stepByStep.width, 'max-width': stepByStep.maxWidth }">
                            <div class="steps-form mr-4">
                                <div class="connecting-line"></div> 
                                <div class="steps-col setup-panel">
                                    <div class="steps-step" v-for="i in numberOfSteps" :key="i" :class="{'steps-active': currentStep == i }">
                                        <div
                                            class="btn btn-step"
                                            :class="{
                                                'step-active': currentStep == i, 
                                                'step-complete': completedStep >= i, 
                                                'step-disabled': i > completedStep,
                                                'step-error': error && currentStep == i,
                                            }"
                                            @click="selectStep(i)"
                                        >
                                            <div class="step-number my-auto">{{i}}</div>
                                        </div>

                                        <!-- <p>{{ stepByStep.steps[i-1].title }}</p> -->
                                    </div>
                                </div>
                            </div>
                            <template v-if="error">
                                <div class="mx-auto">
                                    <svg class="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                        <circle class="crossmark__circle" cx="26" cy="26" r="25" fill="none"/>
                                        <path class="crossmark__check" fill="none" d="M16 16 36 36 M36 16 16 36"/>
                                    </svg>
                                    <div class="text-navy" v-html="error.message"></div>
                                </div>
                            </template>
                            <template v-else>

                                <!-- Deposit items method -->
                                <div class="mx-auto" v-if="stepByStep.type == 'itemDeposit'">
                                    <div v-if="currentStep == 1" style="w-100"> 
                                        <div class="d-flex justify-content-center">
                                            <div>
                                                <img
                                                    class="rounded-circle big-avatar avatar-online"
                                                    style="height:100px; width:100px"
                                                    :src="user.steamData ? user.steamData.avatarfull : require('@/assets/steam_default_profile_pic.png')"
                                                />
                                            <div
                                                class="mt-2 text-center text-navy"
                                            >{{ user.steamData ? user.steamData.personaname : user.name || "" }}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="text-center h5 mt-3 text-navy">Working on it...</div>
                                            <div class="text-center h5 mt-3" v-if="isSteamIssues">Steam Servers are currently down. Your offer will be sent when Steam service resumes</div>
                                                
                                            <div style="position:relative; height: 100px">
                                                <div id="loader"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="currentStep == 2">
                                        <!-- Bot ID profile -->
                                        <template v-if="stepByStep.tradeoffer && stepByStep.tradeoffer.botId">
                                            <div class="d-flex mx-auto">
                                                <div>
                                                    <img class="img rounded-circle" src="@/assets/steeem.png" alt="" srcset="" style="height: 80px; width: 80px; border: 4px solid #72B527;">
                                                </div>
                                                <div class="my-auto ml-3">
                                                    Bot ID: {{stepByStep.tradeoffer.botId}}
                                                </div>
                                            </div>

                                            <div class="text-center h5 mt-3">We've sent you a trade offer!</div>
                                            <div class="text-center h5 mt-3" v-if="isSteamIssues">Steam Servers are currently down. Your offer will be sent when Steam service resumes</div>
                                        
                                            <div class="d-flex justify-content-center">
                                                <a :href="'https://steamcommunity.com/tradeoffer/' + stepByStep.tradeoffer.steamData.id" target="_blank">
                                                    <button class="btn btn-primary mt-4 mx-auto" >
                                                        Open in steam!
                                                    </button>
                                                </a>
                                            </div>

                                            <div class="text-muted mt-3 text-center" style="font-size:12px">Please ensure this security code matched: #{{stepByStep.tradeoffer.securityCode}}</div>
                                        </template>
                                        <template v-else>
                                            <div>
                                                <div class="text-center h5 mt-3">Your offer is queued!</div>
                                                    
                                                <div style="position:relative; height: 100px">
                                                    <div id="loader"></div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>

                                    <div v-if="currentStep == 3">
                                        <div class="text-center h5 mt-3">Thanks, your items have been listed</div>
                                        <!-- TODO: Check the deposit is complete. -->
                                        <svg class="successmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                            <circle class="successmark__circle" cx="26" cy="26" r="25" fill="none"/>
                                            <path class="successmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                                        </svg>
                                    </div>
                                </div>
                                <!-- Withdraw items -->
                                <div class="mx-auto w-100" v-if="stepByStep.type == 'itemWithdrawal'">
                                    <div v-if="currentStep == 1" style="w-100"> 
                                        <div>We're just preparing your tradeoffer(s)</div>
                                        <div class="text-center h5 mt-3" v-if="isSteamIssues">Steam Servers are currently down. Your offer will be sent when Steam service resumes</div>
                                        <div style="position:relative; height: 100px">
                                        <div id="loader"></div>
                                        </div>
                                    </div>

                                    <div v-if="currentStep == 2" style="w-100">
                                        
                                        <template v-if="tradeoffers && tradeoffers.length > 0"> 
                                            <!-- <div class="text-center h5 mt-3" v-if="isSteamIssues">Steam Servers are currently down. Your offer will be sent when Steam service resumes</div> -->

                                            <div v-for="(tradeoffer, i) in tradeoffers" :key="tradeoffer.id" style="max-height: 200px; overflow-y: auto">
                                                <div class="d-flex" style="max-height: 50px; height: 50px">
                                                    <div>
                                                        <img class="img rounded-circle" src="@/assets/steeem.png" alt="" srcset="" style="height: 40px; width: 40px; border: 2px solid #72B527;margin-top:6px">
                                                    </div>
                                                    <div class="my-auto ml-2">
                                                        <div>Bot ID: {{tradeoffer.botId}}</div>
                                                        <div>Security Code: {{tradeoffer.securityCode}}</div>
                                                    </div>
                                                    <div>
                                                        <div v-if="tradeoffer.state == 'DECLINED'">
                                                            <svg class="ml-5 crossmarksm" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                                <circle class="crossmark__circle" cx="26" cy="26" r="25" fill="none"/>
                                                                <path class="crossmark__check" fill="none" d="M16 16 36 36 M36 16 16 36"/>
                                                            </svg>
                                                        </div>
                                                        <div v-else-if="tradeoffer.state == 'ERRORED'">
                                                            <div class="d-flex">
                                                            <svg class="ml-5 crossmarksm" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                                <circle class="crossmark__circle" cx="26" cy="26" r="25" fill="none"/>
                                                                <path class="crossmark__check" fill="none" d="M16 16 36 36 M36 16 16 36"/>
                                                            </svg>
                                                            <div class="my-auto ml-2">Error</div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div v-else-if="tradeoffer.state == 'ACCEPTED'">
                                                            <svg class="ml-5 successmarksm" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                                <circle class="successmark__circle" cx="26" cy="26" r="25" fill="none"/>
                                                                <path class="successmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                                                            </svg>
                                                        </div>

                                                        <div class="ml-4" v-else-if="!tradeoffer.steamData || !tradeoffer.steamData.id" style="position:relative; height: 90px">
                                                            <div class="ml-auto" id="loader" style="height: 30px; width: 30px;"></div>
                                                        </div>

                                                        <a v-else-if="tradeoffer.steamData" :href="'https://steamcommunity.com/tradeoffer/' + tradeoffer.steamData.id" target="_blank">
                                                            <button class="ml-2 btn btn-teal btn-xs my-auto ml-1 mt-1" style="margin-top:5px !important;" >
                                                                Open in steam!
                                                            </button>
                                                        </a>


                                                    </div>
                                                </div>

                                                <hr v-if="i < stepByStep.tradeoffers.length -1"/>
                                            </div>
                                            <!-- <div class="d-flex mx-auto">
                                                <div>
                                                    <img class="img rounded-circle" src="@/assets/steeem.png" alt="" srcset="" style="height: 80px; width: 80px; border: 4px solid #72B527;">
                                                </div>
                                                <div class="my-auto ml-3">
                                                    Bot ID: {{stepByStep.tradeoffer.botId}}
                                                </div>
                                            </div>

                                            <div class="text-center h5 mt-3">We've sent you a trade offer!</div>
                                            
                                        
                                            <div class="d-flex justify-content-center">
                                                <a :href="'https://steamcommunity.com/tradeoffer/' + stepByStep.tradeoffer.steamData.id" target="_blank">
                                                    <button class="btn btn-teal mt-4 mx-auto" >
                                                        Open in steam!
                                                    </button>
                                                </a>
                                            </div> -->
                                        </template>
                                        <template v-else>
                                            We are just sending the tradeoffers, hang on!
                                            <div style="position:relative; height: 100px">
                                                <div id="loader"></div>
                                            </div>
                                        </template>
                                    </div>
                                    <div v-if="currentStep == 3 && stepByStep.tradeoffer.state == 'ACCEPTED'" style="w-100"> 
                                        <div class="text-center h5 mt-3">Thanks, enjoy your skins!</div>
                                        <!-- TODO: Check the deposit is complete. -->
                                        <svg class="successmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                            <circle class="successmark__circle" cx="26" cy="26" r="25" fill="none"/>
                                            <path class="successmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                                        </svg>
                                    </div>
                                </div>
                                <!-- Deposit cash -->

                                <!-- Withdraw cash -->
                            </template>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="d-flex w-100">
                            <div class="mr-auto">
                                <!-- <button  type="button" class="btn btn-xs btn-secondary" data-dismiss="modal" @click="$store.dispatch('stepByStep/clear')">Clear</button> -->

                            </div>
                            <div class="my-auto ml-2">
                                <span class="text-muted mr-2 my-auto">TIP: You can still navigate around the site!</span>
                            </div>
                            <div>
                                <button v-if="stepByStep.showCloseBtn" type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                
                                <!-- <button
                                    v-if="error && error.showRetry !== false"
                                    type="button" 
                                    class="btn btn-warning"
                                    @click="events.$emit(error.retryEvent)"
                                    :disabled="!error.retryable"
                                    >
                                    Retry?
                                </button> -->
                                <template>
                                    <button 
                                        v-if="completedStep == currentStep"
                                        type="button" 
                                        class="btn btn-dark" 
                                        :class="{
                                            'btn-primary': completedStep >= currentStep
                                        }"
                                        @click="completeStep">
                                        Next!
                                    </button>
                                    <button
                                        v-else-if="false"
                                        type="button"
                                        class="btn btn-dark"
                                        :class="{
                                            'btn-primary': completedStep >= currentStep
                                        }" 
                                        @click="completeStep">
                                        Waiting!
                                    </button>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import $ from "jquery";
import "jquery-ui";
import { mapGetters } from 'vuex';
import events from "@/events.js"
import api from "@/api.js";

export default {
    data() {
        return {
            events,
            showLoader: true,
            pollId: null,
        }
    },
    mounted() {
        // $("#stepByStep").draggable({
        //     handle: ".modal-header"
        // }); 
    },
    destroyed() {
        // $('.modal-backdrop').remove();
        if (this.pollId) clearInterval(this.pollId)
    },
    computed: {
        user() {
            return this.$store.getters["auth/user"];
        },
        tradeoffers() {
            return Object.values(this.$store.getters["stepByStep/stepByStep"].tradeoffers);
        },
        ...mapGetters({
            stepByStep: "stepByStep/stepByStep",
            currentStep: "stepByStep/currentStep",
            numberOfSteps: "stepByStep/numberOfSteps",
            error: "stepByStep/error",
            completedStep: "stepByStep/completedStep",
            isSteamIssues: "stepByStep/isSteamIssues",
        })
    },
    methods: {
        async refreshTradeoffer() {
            let tradeoffer = this.stepByStep.tradeoffer;
            if (!tradeoffer || !tradeoffer.id) return;
            let newOffer = (await api.getTradeoffer({ id: tradeoffer.id })).data;
            console.log("Got new tradeoffer!")
            this.$store.commit("stepByStep/addData", { tradeoffer: newOffer });
        },
        loadStepByStep(type) {
            this.stepByStep = JSON.parse(JSON.stringify(stepBySteps))[type];

            this.numberOfSteps = this.stepByStep.steps.length;
            this.currentStep = 1;
            this.completedStep = 0;

            setTimeout(() => {
                this.stepByStep.tradeOfferAvailable = true;
                this.showLoader = false;
            }, 3000)


            setTimeout(() => {
                this.completedStep++;
            }, 10000);
        },
        completeStep() {
            if (!this.completedStep) return;

            this.completedStep = this.currentStep;
            this.selectStep(this.currentStep + 1);

        },
        selectStep(step) {
            if (step > this.completedStep + 1) return;

            this.currentStep = step;

            if (this.currentStep == 1) {
                this.stepTitle = "Sending offer"
            }
            if (this.currentStep == 2) {
                this.stepTitle = "Listing items"
            }
        },
        async processWithdrawals() {
            console.log("Process withdrawals");
            let offers = [];
            for (let tradeoffer of this.tradeoffers) {
                if (!tradeoffer || !tradeoffer.id) {
                    // tradeoffer.state = "ERRORED";    
                    continue; // watf
                }
        
                let newOffer = (await api.getTradeoffer({ id: tradeoffer.id })).data;
                offers.push(newOffer);
            }

            this.$store.commit('stepByStep/updateTradeoffers', offers);
            
            let allStatesMatch = this.tradeoffers.map(x => {
                if (x.state == "ERRORED") return "DECLINED"; // Treat errors as declined to move on.
                return x.state;
            }).every((x, i, states) => x == states[0]);
            
            let state = this.tradeoffers[0].state;
            console.log("Got state check");

            if (allStatesMatch) {
                if (state == "ACCEPTED") {
                    clearInterval(this.pollId);
                    this.$store.commit('stepByStep/completeStep');
                }
                if (state == "DECLINED") {
                    clearInterval(this.pollId);
                    this.$store.commit('stepByStep/setError', {
                        retryable: true,
                        retryEvent: 'retryListItems',
                        message: `You declined the Steam tradeoffer.`,
                    });
                    console.log("User Declined state")
                } 
                if (state == "ERRORED") {
                    clearInterval(this.pollId);
                    this.$store.commit('stepByStep/setError', {
                        retryable: false,
                        message: `Something went wrong while processing your items for withdraw.`,
                    });
                }
            }
        }
    },
    watch: {
        async stepByStep(newVal) {
            // Item deposit checking
            if (newVal.type == "itemDeposit" && this.currentStep == 2) {
                console.log("StepByStep starting to watch for deposits")
                if (this.pollId) clearInterval(this.pollId);

                if (!this.stepByStep.tradeoffer) {
                    this.$store.commit('stepByStep/completeStep', {});
                    // There was no tradeoffers items must have already been on site!
                } 

                this.pollId = setInterval(async () => {
                    let tradeoffer = this.stepByStep.tradeoffer;
                    console.log("Trade Offer", tradeoffer)
                    
                    if (!tradeoffer.botId) {
                        // we need to refrsh data 
                        return await this.refreshTradeoffer();
                    }

                    let itemId = tradeoffer.siteItemsToRequestFromSteam[0].id;
                    let userId = this.user.id;
                    
                    console.log("Tradeoffer loaded");
                    console.log(tradeoffer);
                    //if (tradeoffer.state == "CREATED" || tradeoffer.state == "SENT") { 
                    //    return; // We're waiting.
                    //}  
                    
                    if (tradeoffer.state == "ERRORED") {
                        // Yikers.
                        this.$store.commit('stepByStep/setError', {
                            retryable: true,
                            retryEvent: 'retryListItems',
                            message: tradeoffer.errors[tradeoffer.errors.length-1].userError || "Sorry, something has gone wrong while sending your offer." 
                        });
                        clearInterval(this.pollId);
                        return
                    } 

                    if (!itemId) return console.log(`No item ID found?`);


                    console.log(`Getting item with id: ${itemId}`);
                    let items = (await api.getItems({ id: itemId, userId })).data
                    console.log("Got items");
                    let item = items[0];
                    if (!item) {
                        this.$store.commit('stepByStep/setError', {
                            retryable: true,
                            retryEvent: 'retryListItems',
                            message: `Something went wrong while processing your items for list.`,
                        });
                        clearInterval(this.pollId);
                    }

                    console.log("Checking state")

                    // TODO: Check tradeoffer instead.
                    if (item.state == "USER_DECLINED") {
                        this.$store.commit('stepByStep/setError', {
                            retryable: true,
                            retryEvent: 'retryListItems',
                            message: `You declined the Steam tradeoffer.`,
                        });
                        console.log("User Declined state")
                    } else if (item.state == "LISTED") {
                        this.$store.commit('stepByStep/completeStep', { item });
                        clearInterval(this.pollId);
                        console.log("Item Listed / Processed state")
                    }
                }, 3000);
            } else if (newVal.type == "itemWithdrawal" && this.currentStep == 2) {
                // We need to keep checking our tradeoffers, and once all offers are complete, move them on
                console.log(`StepByStep Starting to watch for withdrawals`);
                try {
                    if (!this.withdrawPollId) this.withdrawPollId = setInterval(this.processWithdrawals, 4000)
                } catch (e) {
                    console.error(e);
                    this.$store.commit('stepByStep/setError', {
                        retryable: false,
                        message: `Something went wrong while processing your items for withdraw.`,
                    });

                }
            }
        }
    }
}
</script>
