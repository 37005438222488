// TODO: make an  API class for guard, potentially as a public lib
var axios = require('axios');
var qs = require('qs');

var axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_GUARD_API_URL || "https://api.guard.gg/v1",
});

const { ConcurrencyManager } = require("axios-concurrency");
const MAX_CONCURRENT_REQUESTS = 5;
const manager = ConcurrencyManager(axiosInstance, MAX_CONCURRENT_REQUESTS);
// Use manager.detech() to turn off the manager.

let auth = localStorage.getItem('auth');
// We was having token issues with session storage I believe.
// if (sessionStorage.getItem('auth') !== null)
//     auth = sessionStorage.getItem('auth')
// else
//     auth = localStorage.getItem('auth')
    
if (auth) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${auth}`;
}

module.exports = axiosInstance;
module.exports.axios = axiosInstance;

module.exports.getUserBySteamId = function(steamId, realm) {
    return axiosInstance.get(`/user?${qs.stringify({steamId, realm})}`);
}

module.exports.getUserById = function(id, realm) {
    return axiosInstance.get(`/user?${qs.stringify({id, realm})}`);
}
module.exports.getCurrentUser = function() {
    return axiosInstance.get(`/user`);
}

module.exports.getMostItemStats = function(params) {
    return axiosInstance.get(`/item-stats?${qs.stringify(params)}`);
}

module.exports.setToken = function(token, rememberMe) {
    // Removed temporarily as we think there was an issue with session storage
    // if (rememberMe) 
    //     localStorage.setItem('auth', token);
    // else
    //     sessionStorage.setItem('auth', token);
    
    localStorage.setItem('auth', token);
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

module.exports.getToken = function() {
    return axiosInstance.defaults.headers.common['Authorization'];
}

module.exports.getInventory = function(params) {
    return axiosInstance.get(`/inventory?${qs.stringify(params)}`, { timeout: 20 * 1000 });
}

module.exports.getTransactions = function(params) {
    return axiosInstance.get(`/transactions?${qs.stringify(params)}`);
}

module.exports.getTransactionActivity = function(params) {
    return axiosInstance.get(`/transaction-activity?${qs.stringify(params)}`);
}

module.exports.listItems = function(items) {
    return axiosInstance.post(`/create-listings`, {
        toList: items
    });
}

module.exports.registerUser = function(data) {
    return axios.post(process.env.VUE_APP_GUARD_REGISTER_URL, data)
}

module.exports.getItems = function(params, axiosParams) {
    return axiosInstance.get(`/items?${qs.stringify(params)}`, axiosParams);
}

module.exports.getTradeoffer = function(params) {
    return axiosInstance.get(`/tradeoffer?${qs.stringify(params)}`)
}

module.exports.getOrder = function(params) {
    return axiosInstance.get(`/order?${qs.stringify(params)}`)
}

module.exports.getRecommendedItems = function(params) {
    return axiosInstance.get(`/recommendeditems?${qs.stringify(params)}`)
}

module.exports.getSimilarItems = function(params) {
    return axiosInstance.get(`/similaritems?${qs.stringify(params)}`)
}

module.exports.getLists = function(params) {
    return axiosInstance.get(`/lists?${qs.stringify(params)}`)
}

module.exports.getActivities = function(params) {
    return axiosInstance.get(`/activities?${qs.stringify(params)}`)
}

module.exports.updateResource = function(resourceId, resourceType, resourcePartition, changes, context) {
    return axiosInstance.post('/update-resource', { resourceId, resourceType, resourcePartition, changes, context })
}

module.exports.getAvailablePaymentMethods = function(countryCode, amount) {
    return axiosInstance.get(`/get-available-payment-methods?${qs.stringify({
        countryCode, amount
    })}`);
}

module.exports.chargeUser = function(amount, processor, data) {
    return axiosInstance.post(`/deposit-funds?${qs.stringify({
        amount, method: processor
    })}`, data);
}

module.exports.withdrawFunds = function(amount, processor, paymentData, twoFactorToken) {
    console.log(twoFactorToken)
    return axiosInstance.post(`/withdraw-funds?${qs.stringify({
        amount: amount,
        method: processor,
    })}`, {
        paymentData
    }, {
        headers: {
            'guard-twofactor-token': twoFactorToken
        }
    });
}

module.exports.withdrawItems = function(items) {
    return axiosInstance.post(`/withdraw-items`, items);
}

module.exports.purchaseItems = function(items) {
    return axiosInstance.post('/purchase-items', { items })
}

// Consider moving amount elsewhere.
module.exports.handleAdditionalDetails = function(amount, processor, additionalDetails) {
    return axiosInstance.post(`/handle-additional-payment-details?${qs.stringify({
        methods: processor,
        countryCode: additionalDetails.countryCode || "TODO",
        amount: amount,
    })}`, {
        additionalDetails
    })
};

module.exports.inspectItem = function(steamItem) {
    // steamItem needs icon_url, appid, market_hash_name
    return axiosInstance.post(`/inspect-item`, steamItem);
}

module.exports.getPricingHistory = function(appId, marketHashName) {
    return axiosInstance.get(`/past-market-data?${qs.stringify({
        appId: appId,
        market_hash_name: marketHashName,
    })}`);
}

module.exports.usersForgotPassword = function(email) {
    return axiosInstance.post(`/users/forgot_password`, { email });
}
module.exports.getAxiosInstance = function() {
    return axiosInstance;
}