import Vue from 'vue';
const api = require('../api');
console.log(`Commodities.JS loaded`);

export default {
    namespaced: true,
    state: {
        commodities: [],
    },
    getters: {
        commodities: (state) => state.commodities, 
    },
    mutations: {
        setCommodities(state, commodities) {
            Vue.set(state, 'commodities', commodities)
        },
    },
    actions: {
        async updateCommodities(context) {
            let commodities = (await api.get(`/commodities`)).data
            context.commit('setCommodities', commodities)
        }
    }
}