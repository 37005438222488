<template>
  <div class="home">
    
    <div class="mt-5 d-flex justify-content-center">
      <div class="d-flex flex-column">
        <div class="h1">Sorry, you've headed in the wrong direction.</div>
        <div class="h3">This page couldn't be found.</div> 
      </div>
    </div>

  </div>
</template>

<script>

export default {
  components: {

  }
}
</script>
