<template>
    <li
        id="profileDropown"
        class="dropdown px-3 profile-dropdown"
        :class="{ 'show': isDropdownOpen }"
        @mouseleave="toggle(false)"
        @mouseover="toggle(true)"
        @click="toggle('toggle')"
    >
        <a
            class="user-badge"
            href="#0"
            id="profileDropdown"
            role="button"
        >
            <div class="user-badge-content">
                <div
                class="user-name text-white roboto-14 bold"
                >
                    {{ user.steamData ? user.steamData.personaname : user.username }}
                    <div
                    class="user-balance text-teal roboto-14 bold text-right"
                    >${{ (Math.floor(user.balance) / 100).toFixed(2) || "0.00" }}
                    </div>
                </div>
            </div>

            <div class="avatar mx-3" >
                <img :src="user.steamData ? user.steamData.avatar : require('@/assets/steam_default_profile_pic.png')" style="max-height: 32px; max-width: 32px;"/>
            </div>

            <div style="display: inline-block; position: relative; top: -11px;">
                <div>
                    <font-awesome-icon
                        v-if="isDropdownOpen"
                        class
                        icon="angle-up"
                        size="2x"
                        style=" height: 20px; width:20px; margin-top:-5px;"
                        color="white"
                    />
                    <font-awesome-icon
                        v-else
                        class
                        icon="angle-down"
                        size="2x"
                        style=" height: 20px; width:20px;"
                        color="white"
                    />
                </div>
            </div>
        </a>

        <transition enter-active-class="animated fadeIn faster" leave-active-class="animated fadeOut faster" mode="out-in">
            <div
                class="dropdown-menu user-dropdown dropdown-menu-right"
                aria-labelledby="personDropdown"
                style="margin-top:-5px;"
                :class="{ 'show': isDropdownOpen }"
                @mouseenter="isDropdownOpen = true"
                @mouseleave="isDropdownOpen = false"
              >
                <div class="w-100 mb-2">
                    <div class="d-flex justify-content-between" href="#0">
                        <div class="btn-group">
                            <router-link
                              to="/deposit"
                              v-slot="{ navigate }"
                            >
                                <button @click="navigate" type="button" class="btn btn-teal text-white deposit-btn">+ Deposit</button>
                            </router-link>
                        </div>
                        <div class="btn-group">
                            <router-link
                              to="/withdraw"
                              v-slot="{ navigate }"
                            >
                                <button @click="navigate" type="button" class="btn btn-teal text-white withdraw-btn">- Withdraw</button>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="px-2 my-2"><div class="dropdown-divider m-0 user-divider"></div></div>

                <router-link
                  to="/account"
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
                >
                  <a
                    class="dropdown-item user-link"
                    :href="href"
                    @click="navigate"
                  ><img src="@/assets/profile.svg" style="margin-bottom:2px;margin-right:4px"/> My Profile</a>
                </router-link>
                

                <router-link
                  to="/activity"
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
                >
                  <a
                    class="dropdown-item user-link"
                    :href="href"
                    @click="navigate"
                  ><img src="@/assets/activity.svg" style="margin-bottom:2px;margin-right:4px"/> Activity</a>
                </router-link>
                
                <router-link
                  to="/my-listings"
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
                >
                  <a
                    class="dropdown-item user-link"
                    :href="href"
                    @click="navigate"
                  ><img src="@/assets/listings.svg" style="margin-bottom:2px;margin-right:4px"/> My Listings</a>
                </router-link>
                
                <router-link
                  to="/inventory"
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
                >
                  <a
                    class="dropdown-item user-link"
                    :href="href"
                    @click="navigate"
                  ><img src="@/assets/myinventory.svg" style="margin-bottom:2px;margin-right:4px"/> My Inventory</a>
                </router-link>
                
                <router-link
                  to="/watchlist"
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
                >
                  <a
                    class="dropdown-item user-link"
                    :href="href"
                    @click="navigate"
                  ><img src="@/assets/watcheditems.svg" style="margin-bottom:2px;margin-right:4px"/> Watched Items</a>
                </router-link>
            
                <router-link
                  to="/sell"
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
                >
                  <a
                    class="dropdown-item user-link"
                    :href="href"
                    @click="navigate"
                  ><img src="@/assets/sell.svg" style="margin-bottom:2px;margin-right:4px"/> Sell</a>
                </router-link>
                <router-link
                  to="/feedback"
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
                >
                  <a
                    class="dropdown-item user-link"
                    :href="href"
                    @click="navigate"
                  ><img src="@/assets/feedback_teal.png" style="margin-bottom:2px;margin-right:4px; height:15px; color:blue"/> Feedback</a>
                </router-link>

                <div class="px-2 my-2"><div class="dropdown-divider m-0 user-divider"></div></div>

                <a
                  class="dropdown-item user-link"
                  @click="logout"
                  href="#0"
                  style=""
                ><img src="@/assets/logout.svg" style="margin-bottom:2px;margin-right:4px"/> Logout</a>
              </div>
      </transition>
    </li>
</template>

<script>
export default {
    data() {
        return {
            isDropdownOpen: false,
        };
    },
    computed: {
        user() {
            return this.$store.getters["auth/user"];
        },
    },
    methods: {
        async logout() {
            // localStorage.clear();
            // sessionStorage.clear();
            this.$store.commit("reset");
            this.$store.commit("auth/logout");
            this.$router.push("/login");
            // TODO: Figure a better workaround
            window.location.reload();
        },
        toggle(wantedState) {
            if (wantedState == 'toggle')
                this.isDropdownOpen = !this.isDropdownOpen;
            else
                this.isDropdownOpen = wantedState;

            this.$emit('profileToggle', this.isDropdownOpen)
            // console.log("emitted toggle event")
        },
    }

}
</script>

<style lang="scss" scoped>
    
</style>