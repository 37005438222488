<template>
  <div class="h-100" :style="{'background': `url(${require('@/assets/bg.png')}), #FFFFFF`}">
    <div class="container h-100">
      <div class="row align-items-center h-75 my-auto">
        <div class="col-6 mx-auto">
          <div class="card-head gdark">
            <div class="d-flex justify-content-center align-items-center h-100">
              <div class="mr-4">
                <router-link to="/" v-slot="{ href, navigate }">
                    <a class="navbar-brand " :href="href" @click="navigate">
                        <img src="@/assets/key-icon.svg" style="max-height: 35px; max-width: 30px;" />
                        <span class="text-white" style="margin-left:5px;font-family: Rubik; font-size: 22px;  font-weight: bold">GUARD</span>
                        <span class="text-secondary" style="font-family: Rubik; font-size: 22px; font-weight: bold">.GG</span>
                    </a>
                </router-link>
            </div>
            </div>
          </div>
          <div class="card-body w-100">
            <div class="mt-2 text-center h5 f-rubik" style="color: #545454">Login to your account</div>
            <div class="mt-4 mx-auto w-75">
              <div class="input-group">
                <input
                  class="mt-2 form-control login-textbox"
                  type="text"
                  name="Email"
                  id="email"
                  v-model="username"
                  placeholder="Email or Username"
                  v-on:keyup.enter="loginManually()"
                />
              </div>
              <div v-for="item in invalidUsernameReqs" class="mt-2">
                <span>{{ item }}</span>
              </div>
              <input
                class="mt-4 form-control login-textbox"
                type="password"
                name="password"
                id="password"
                v-model="password"
                placeholder="Password"
                v-on:keyup.enter="loginManually()"
              />
              <div v-for="item in invalidPassReqs" class="mt-2">
                <span >{{ item }}</span>
              </div>
              <div class="form-row w-100 mt-2">
                <!-- <div class="form-group">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="gridCheck" style="left: -55px; margin-left:0px" v-model="rememberMe">
                    <label class="form-check-label f-noto-sans" for="gridCheck" style="margin-left:5px;">
                      Remember me
                    </label>
                  </div>
                </div> -->
                
                <div class="ml-auto">
                  <a href="#0" class="ml-auto f-noto-sans" @click="forgotPassword">Forgot password?</a>
                </div>
              </div>

              <button
                class="btn btn-primary mt-2 w-100"
                style="height:50px;"
                v-on:click="loginManually()"
              >
                <span v-if="!loading" style="color: white; font-family: Roboto;">LOGIN</span>
                <img v-else src="@/assets/loading_spinner_animated.svg" style="position: relative; height: 60px; bottom: 15px;"/>
              </button>

              <div class>
                <span class="d-flex justify-content-center mt-4 f-18">
                  <span class="f-roboto" style="color: #545454">Need an account?</span>
                  <router-link
                    to="/register"
                    v-slot="{ href, route, navigate, isActive, isExactActive }"
                  >
                    <a class="pa ml-2 f-bold f-roboto" :href="href" @click="navigate">Sign Up</a>
                  </router-link>
                </span>
              </div>

              <div class="mt-4 mb-4">
                <div class="separator f-roboto f-14">Or Connect Using Steam</div>
              </div>

              <div class="mt-3 pb-4 d-flex justify-content-center">
                <button
                  @click="loginWithSteam()"
                  class="btn btn-secondary steam-login"
                  style="color:white"
                >
                  <img src="@/assets/steam-logo.svg" />
                  <span
                    class="f-roboto f-12 f-no-transform"
                    style="margin-left:5px; color: white; font-weight: 500;"
                  >Steam Login</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      username: "",
      password: "",
      loginResult: "",
      loading: false,
      rememberMe: true,
      invalidUsernameReqs: [],
      invalidPassReqs: []
    };
  },
  mounted() {
    if (this.loggedIn) {
        this.$router.push("/");
    }
  },
  computed: {
    loggedIn() {
      return this.$store.getters["auth/loggedIn"];
    }
  },
  methods: {
    ...mapActions('auth', [ 'forgotPassword' ]),
    loginWithSteam() {
      // this.$appInsights.trackEvent("signInWithSteam", { value: "ok" });
      this.$store.dispatch("auth/loginWithSteam", { rememberMe: this.rememberMe });
    },
    updateFailedLoginReasons(message){
      switch(message){
        case "You need to complete the two-factor authentication.":
          this.invalidPassReqs.push(message)
          break;
        case "Invalid 2FA token entered.":
          this.invalidPassReqs.push(message)
          break;
        case "Invalid password entered.":
          this.invalidPassReqs.push(message)
          $("#password").addClass("has-error")
          break;
        case "User does not exist. Are you sure you have entered the correct username or email address?":
          $("#email").addClass("has-error")
          this.invalidUsernameReqs.push(message) // Could probably just do an if check for this and else everything else, but this is slightly more future-proof imo
          break;
        case "Unknown error occured, please try again later.":
          this.invalidPassReqs.push(message)
      }
    },
    async loginManually() {
      this.invalidUsernameReqs = []
      this.invalidPassReqs = []
      $("#password").removeClass("has-error")
      $("#email").removeClass("has-error")
      try{
        this.loading = true;
        this.loginResult = await this.$store.dispatch("auth/loginManually", {username: this.username, password: this.password, rememberMe: this.rememberMe});
        this.loading = false;

        let token;
        if(this.loginResult.requires_twofactor){ // Start the 2FA process
          token = '';
          let response = await Swal.fire({
            title: "Confirm Two Factor Code",
            html: "Please enter your two factor code displayed on your authenticator",
            input: "text",
            icon: "info",
            inputValidator: (value) => {
              if (!value) return 'You need to write something!'
              if ((value + '').length != 6) return 'Your code must be 6 digits.';
              if (isNaN(parseInt(value))) return 'Your code must be 6 digits.';
            }
          });
          token = response.value;
          Swal.showLoading();
          this.loginResult = await this.$store.dispatch("auth/loginManually", {
            username: this.username,
            password: this.password,
            twoFactorToken: token
          });
          // If tokens match, the user will be logged in (inside auth)
          if (this.loginResult.success) {
            return Swal.close();
          }
        }

        if (this.loginResult.message){ // Authentication failed
          return this.updateFailedLoginReasons(this.loginResult.message)
        }

        else if(!this.loginResult.message && !this.loginResult.requires_twofactor){ // If non-2FA auth was successful
          // Swal.showLoading();
        }
      }
      catch(error){
        // TODO: Change to a better error method
        alert(error);
      }
    }
  },
  watch: {
    loggedIn(newVal, oldVal) {
      if (newVal) {
        this.$router.push("/");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card-head {
  height: 60px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.card-body {
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  // border-radius: 10px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.form-control {
  background: #e6e6e6;
}

.steam-login {
  background-color: #545454 !important;
  > span {
    letter-spacing: 0px;
  }
}

.f-roboto {
  font-family: "Roboto";
}
.f-rubik {
  font-family: "Rubik";
}
.f-noto-sans {
  font-family: "Noto Sans";
}

.f-12 {
  font-size: 12px;
}
.f-14 {
  font-size: 14px;
}
.f-18 {
  font-size: 18px;
}

.f-normal {
  font-weight: normal;
}
.f-bold {
  font-weight: bold;
}
.f-no-transform {
  text-transform: none;
}

.login-textbox {
  background-color: #e6e6e6 !important;
  border-radius: 10px !important;
}

.has-error {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-error:focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}
</style>